import {history} from 'packages/history/history';
// icons
import {ReactComponent as LogoImg} from './logo.svg';
// styles
import {css, CreateSheet} from 'packages/aphrodite/aphrodite';

export default function Logo() {
    const OpenPage = (url: string) => (e?: React.MouseEvent) => {
        e && e.preventDefault();
        e && e.stopPropagation();

        history.push(`/${url}`);
    };

    return (
        <a
            {...{
                className: css(Styles.logo),
                href: '/',
                onClick: OpenPage(''),
                'aria-label': 'DGtalguide',
            }}
        >
            <LogoImg
                className={css(Styles.icon)}
                width="140"
                height="30"
            />
        </a>
    );
}

const Styles = CreateSheet({
    logo: {
        cursor: 'pointer',
        userSelect: 'none',
    },
    icon: {
        display: 'block',
    },
});
