export default function PlusSignVerifier(str: string): string {
    if (str.length) {
        const re = /^[0-9s()\-\s]*$/;

        if (re.test(str)) {
            str = `+${str}`;
        } else if (str.startsWith('+')) {
            if (!re.test(str.substring(1)) || !str.substring(1).length) {
                str = str.substring(1);
            }
        }
    }
    return str;
}
