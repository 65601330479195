import Input, {InputTheme, InputLayout} from 'packages/forms/Input';
import {Secondary, Danger, Success, Dark, Gray} from './colors';
import {GetColor} from 'packages/aphrodite/aphrodite';

export const DefaultLayout: InputLayout = {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: '1rem',
    fontWeight: 600,
    borderRadius: 7,
    border: '1px solid transparent',
    padding: '10px 15px',
    transition: 'box-shadow 0.2s',
};

export const BookingLayout: InputLayout = {
    ...DefaultLayout,
    fontSize: '1.2857rem',
    fontWeight: 600,
    padding: 20,
    width: '100%',
    borderRadius: 17,
};

export const LargeLayout: InputLayout = {
    ...DefaultLayout,
    fontSize: 16,
    padding: '8px 12px',
    width: '100%',
    borderRadius: 12,
    lineHeight: '28px',
    fontWeight: 500,
};

export const PrimaryInput: InputTheme = {
    plain: {
        background: GetColor('blockBg2'),
        color: Dark.default,
        borderColor: 'rgba(48, 48, 48, 0.2)',
        boxShadow: undefined,
    },
    placeholder: {
        color: 'rgba(48, 48, 48, 0.3)',
    },
    label: {
        color: Gray.default,
    },
    focus: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: undefined,
    },
    valid: {
        background: undefined,
        color: Dark.default,
        borderColor: Success.default,
        boxShadow: 'none',
    },
    error: {
        background: GetColor('blockBg2'),
        color: Dark.default,
        borderColor: Danger.default,
        boxShadow: 'none',
    },
    disabled: {
        background: undefined,
        color: Dark.default,
        borderColor: Dark.disabled,
        boxShadow: 'none',
    },
};

export const PrimaryInputLight: InputTheme = {
    plain: {
        background: '#fff',
        color: Dark.default,
        borderColor: `${GetColor('gray3')}`,
        boxShadow: '0px 15px 20px -12px rgba(228, 228, 228, 0.5)',
    },
    placeholder: {
        color: 'rgba(48, 48, 48, 0.3)',
    },
    label: {
        color: Gray.default,
    },
    focus: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: undefined,
    },
    valid: {
        background: undefined,
        color: Dark.default,
        borderColor: Success.default,
        boxShadow: 'none',
    },
    error: {
        background: undefined,
        color: Dark.default,
        borderColor: Danger.default,
        boxShadow: 'none',
    },
    disabled: {
        background: undefined,
        color: Dark.default,
        borderColor: Dark.disabled,
        boxShadow: 'none',
    },
};

export const PrimaryTransparentInput: InputTheme = {
    plain: {
        background: 'transparent',
        color: Dark.default,
        borderColor: 'transparent',
        boxShadow: undefined,
    },
    placeholder: {
        color: 'rgba(48, 48, 48, 0.3)',
    },
    label: {
        color: Gray.default,
    },
    focus: {
        background: '#fff',
        color: undefined,
        borderColor: undefined,
        boxShadow: '0px 4px 10px rgba(48, 48, 48, 0.07)',
    },
    valid: {
        background: undefined,
        color: Dark.default,
        borderColor: 'transparent',
        boxShadow: 'none',
    },
    error: {
        background: undefined,
        color: Dark.default,
        borderColor: Danger.default,
        boxShadow: 'none',
    },
    disabled: {
        background: undefined,
        color: Dark.default,
        borderColor: 'transparent',
        boxShadow: 'none',
    },
};

export const TransparentGray: InputTheme = {
    plain: {
        background: 'transparent',
        color: Dark.default,
        borderColor: Gray.default,
        boxShadow: undefined,
    },
    placeholder: {
        color: Dark.disabled,
    },
    label: {
        color: Gray.default,
    },
    focus: {
        background: undefined,
        color: undefined,
        borderColor: Secondary.default,
        boxShadow: '0 3px 10px 0 rgba(34,41,47,.1)',
    },
    valid: {
        background: undefined,
        color: Dark.default,
        borderColor: Success.default,
        boxShadow: 'none',
    },
    error: {
        background: undefined,
        color: Dark.default,
        borderColor: Danger.default,
        boxShadow: 'none',
    },
    disabled: {
        background: undefined,
        color: Dark.default,
        borderColor: Dark.disabled,
        boxShadow: 'none',
    },
};

export const AvailabilityInput: InputTheme = {
    plain: {
        background: '#fff',
        color: Dark.default,
        borderColor: undefined,
        boxShadow: '0px 4px 10px rgba(48, 48, 48, 0.07)',
    },
    placeholder: {
        color: 'rgba(48, 48, 48, 0.3)',
    },
    label: {
        color: Gray.default,
    },
    focus: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: undefined,
    },
    valid: {
        background: undefined,
        color: Dark.default,
        borderColor: Success.default,
        boxShadow: 'none',
    },
    error: {
        background: undefined,
        color: Dark.default,
        borderColor: Danger.default,
        boxShadow: 'none',
    },
    disabled: {
        background: undefined,
        color: Dark.default,
        borderColor: Dark.disabled,
        boxShadow: 'none',
    },
};

export function MakeInput(theme: InputTheme, layout?: InputLayout) {
    return Input({...DefaultLayout, ...layout}, theme);
}
