import {CreateSheet, GetColor, css} from 'packages/aphrodite/aphrodite';
import IsIFrame from 'packages/helpers/IsIFrame';
import {useLocalization} from 'packages/localization/localization';
import Portal from 'packages/portal/portal.react';
import {useLayoutEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {ReactComponent as WhatsappIcon} from 'src/icons/whatsapp-filled.svg';
import {Dark, Gray, White} from 'src/themes/colors';

let getCookie = function () {
    let name = 'DGtalguide=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};

let checkCookie = function () {
    let check = getCookie();
    return check === '';
};

export default function WhatsappPopup() {
    const location = useLocation();
    const {t} = useLocalization('contact');

    const [Show, SetShow] = useState(false);

    useLayoutEffect(() => {
        let timer: any;
        if (location.pathname.indexOf('/tours') !== -1 || location.pathname.indexOf('/manager') !== -1) {
            if (Show) SetShow(false);
        } else if (!Show) {
            timer = setTimeout(() => {
                SetShow(true);
            }, 3 * 1000);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [Show, location]);

    const aboveCookies = checkCookie() && !IsIFrame();

    return (
        <Portal>
            <a
                href="https://wa.me/393518104545"
                target="_blank"
                rel="noopener noreferrer"
                className={css(Styles.main, Show && Styles.show, aboveCookies && Styles.aboveCookies)}
            >
                <WhatsappIcon
                    width="50"
                    height="50"
                />
                <div className={`${css(Styles.info)} whatsapp-info`}>
                    <div className={css(Styles.infoSmaller)}>{t('title')}</div>
                    WhatsApp
                </div>
            </a>
        </Portal>
    );
}

const Styles = CreateSheet({
    main: {
        position: 'fixed',
        bottom: 25,
        left: 25,
        display: 'flex',
        fontFamily: 'Montserrat',
        background: White.default,
        transition: 'transform 0.3s ease-in',
        transform: 'translateX(-200%)',
        padding: 10,
        alignItems: 'center',
        zIndex: 100,
        textDecoration: 'none',
        border: `1px solid ${GetColor('gray3')}`,
        boxShadow: '0px 4px 15px 0px rgba(48, 48, 48, 0.10)',
        userSelect: 'none',
        borderRadius: 50,
        cursor: 'pointer',
        '@media(max-width: 640px)': {
            display: 'none',
        },
        ':nth-child(1n) svg': {
            display: 'block',
        },
        ':hover': {
            ':nth-child(1n) .whatsapp-info': {
                width: 105,
                marginLeft: 12,
                marginRight: 10,
            },
        },
    },
    aboveCookies: {
        bottom: 105,
    },
    show: {
        transform: 'translateX(0)',
    },
    info: {
        width: 0,
        height: 50,
        margin: 0,
        fontSize: '1.2857rem',
        fontWeight: 600,
        color: Dark.default,
        overflow: 'hidden',
        transition: 'width 0.2s',
        whiteSpace: 'nowrap',
    },
    infoSmaller: {
        fontSize: '1rem',
        fontWeight: 500,
        color: Gray.default,
    },
});
