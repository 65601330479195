import {GetColor} from 'packages/aphrodite/aphrodite';

type Color = {
    default: string;
    hover: string;
    active: string;
    focus: string;
    disabled: string;
};

export const Primary: Color = {
    default: '#ED1764',
    hover: '#ED1764',
    active: '#ED1764',
    focus: '#ED1764',
    disabled: '#ED176452',
};

export const PrimaryManager: Color = {
    default: '#C11856',
    hover: '#C11856',
    active: '#C11856',
    focus: '#C11856',
    disabled: '#C1185652',
};

export const PrimaryManagerGradient: Color = {
    default: 'linear-gradient(126.13deg, #DB4179 11.39%, #C11856 100%)',
    hover: 'linear-gradient(126.13deg, #E45E8F 11.39%, #D6316D 100%)',
    active: 'linear-gradient(94.46deg, #E96F9B 2.67%, #C41D5A 95.69%)',
    focus: 'linear-gradient(94.46deg, #E96F9B 2.67%, #C41D5A 95.69%)',
    disabled: 'linear-gradient(94.46deg, #E96F9B 2.67%, #C41D5A 95.69%)',
};

export const PrimaryGradient: Color = {
    default: `linear-gradient(94.46deg, ${GetColor('accentLinearStart')} 2.67%, ${GetColor('accentLinearEnd')} 95.69%)`,
    hover: 'linear-gradient(94.46deg, #FF6DA3 2.67%, #FF478A 95.69%)',
    active: `linear-gradient(94.46deg, ${GetColor('accentLinearStart')} 2.67%, ${GetColor('accentLinearEnd')} 95.69%)`,
    focus: `linear-gradient(94.46deg, ${GetColor('accentLinearStart')} 2.67%, ${GetColor('accentLinearEnd')} 95.69%)`,
    disabled: `linear-gradient(94.46deg, ${GetColor('accentLinearStart')} 2.67%, ${GetColor(
        'accentLinearEnd'
    )} 95.69%)`,
};

export const PartnersGradient: Color = {
    default: 'linear-gradient(94.46deg, #5B6FCB 2.67%, #29387C 95.69%)',
    hover: 'linear-gradient(94.46deg, #5B6FCB 2.67%, #29387C 95.69%)',
    active: 'linear-gradient(94.46deg, #5B6FCB 2.67%, #29387C 95.69%)',
    focus: 'linear-gradient(94.46deg, #5B6FCB 2.67%, #29387C 95.69%)',
    disabled: 'linear-gradient(94.46deg, #5B6FCB 2.67%, #29387C 95.69%)',
};

export const PartnersDarkGradient: Color = {
    default: 'linear-gradient(94.46deg, #5F5F5F 2.67%, #1F1F1F 95.69%)',
    hover: 'linear-gradient(94.46deg, #5F5F5F 2.67%, #1F1F1F 95.69%)',
    active: 'linear-gradient(94.46deg, #5F5F5F 2.67%, #1F1F1F 95.69%)',
    focus: 'linear-gradient(94.46deg, #5F5F5F 2.67%, #1F1F1F 95.69%)',
    disabled: 'linear-gradient(94.46deg, #5F5F5F 2.67%, #1F1F1F 95.69%)',
};

export const Partners: Color = {
    default: '#29387C',
    hover: '#29387C',
    active: '#29387C',
    focus: '#29387C',
    disabled: '#29387C',
};

export const Secondary: Color = {
    default: '#82868B',
    hover: '#82868B',
    active: '#75797E',
    focus: '#75797E',
    disabled: 'rgba(130, 134, 139, 0.65)',
};

export const Success: Color = {
    default: '#21cc6d',
    hover: '#21cc6d',
    active: '#1b9e55',
    focus: '#1b9e55',
    disabled: 'rgba(40, 199, 111, 0.65)',
};

export const Danger: Color = {
    default: '#EA5455',
    hover: '#EA5455',
    active: '#E73D3E',
    focus: '#E73D3E',
    disabled: 'rgba(234, 84, 85, 0.65)',
};

export const Warning: Color = {
    default: '#FF9F43',
    hover: '#FF9F43',
    active: '#FF922A',
    focus: '#FF922A',
    disabled: 'rgba(255, 159, 67, 0.65)',
};

export const Yellow: Color = {
    default: '#ffca29',
    hover: '#ffca29',
    active: '#ffca29',
    focus: '#ffca29',
    disabled: '#ffca29',
};

export const Info: Color = {
    default: '#4D89EE',
    hover: '#4D89EE',
    active: '#00B8CF',
    focus: '#00B8CF',
    disabled: 'rgba(0, 207, 232, 0.65)',
};

export const Dark: Color = {
    default: '#303030',
    hover: '#303030',
    active: '#303030',
    focus: '#303030',
    disabled: '#30303052',
};

export const White: Color = {
    default: '#FFFFFF',
    hover: '#FFFFFF',
    active: '#FFFFFF',
    focus: '#FFFFFF',
    disabled: 'rgba(75, 75, 75, 0.65)',
};

export const Gray: Color = {
    default: '#979797',
    hover: '#979797',
    active: '#979797',
    focus: '#979797',
    disabled: '#979797',
};

export const GrayLight: Color = {
    default: '#C1C1C1',
    hover: '#C1C1C1',
    active: '#C1C1C1',
    focus: '#C1C1C1',
    disabled: '#C1C1C1',
};
