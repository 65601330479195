import {CreateContainer, SetItem, Template, GridStyles, ResponsiveLayouts} from './grid.model';

export type Response<R = string> = [object, (marker: R) => void];

export function useGrid<R extends Template>(layout: R, styles?: GridStyles): Response;
export function useGrid<R extends Template>(
    layout: R,
    responsiveLayouts: ResponsiveLayouts<R>,
    styles?: GridStyles
): Response;

export function useGrid<R extends Template>(
    layout: R,
    prm_one?: ResponsiveLayouts<R> | GridStyles,
    prm_two?: GridStyles
): [object, (marker: string) => void] {
    return [CreateContainer(layout, prm_one, prm_two), (marker: string) => SetItem(marker, prm_one)];
}
