import {MakeAnimation} from 'packages/aphrodite/aphrodite';

const logSpin = MakeAnimation(
    {
        '100%': {
            transform: 'rotate(360deg)',
        },
    },
    {}
);

export default logSpin;
