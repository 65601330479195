import {
    StyleSheetServer,
    CSSProperties,
    CSSPropertiesComplete,
    StyleDeclarationValue,
    StyleSheet as OriginalScheet,
    StyleDeclaration,
    flushToStyleTag,
} from 'aphrodite-source/lib/index';
import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';
import flattenDeep from 'lodash/flattenDeep';
import {ThemeColors, VAR_PREFIX} from 'generated/ThemeColors';

const {StyleSheet, css} = OriginalScheet.extend([
    {
        //@ts-ignore
        selectorHandler: (selector, baseSelector, generateSubtreeStyles) => {
            const nestedTags: string[] = [];
            const selectors = selector.split(',');
            each(selectors, (subselector, key) => {
                if (selector[0] === '&') {
                    const tag = key === 0 ? subselector.slice(1) : subselector;

                    const nestedTag = generateSubtreeStyles(`${baseSelector}${tag}`.replace(/ +(?= )/g, ''));
                    nestedTags.push(nestedTag);
                }
            });

            return isEmpty(nestedTags) ? null : flattenDeep(nestedTags);
        },
    },
]);

type CreateWithThemeFunc<T, L, V> = (theme?: T, layout?: L) => StyleDeclaration<V>;

export function useTheme<T, L, V>(theme: T | undefined, styles: CreateWithThemeFunc<T, L, V>, layout?: L | undefined) {
    //const ref = useRef(StyleSheet.create(styles(theme)));
    /* here we can check prev and current them and update styles if it will be needed later */
    return StyleSheet.create(styles(theme, layout));
}

export function CreateSheetWithTheme<T, L, V>(func: CreateWithThemeFunc<T, L, V>) {
    return func;
}

export function CreateSheet<V>(styles: StyleDeclaration<V>) {
    return StyleSheet.create(styles);
}

export type Animation = {
    enter?: Record<string, CSSProperties>;
    exit?: Record<string, CSSProperties>;
};

export function MakeAnimation(enter?: Record<string, CSSProperties>, exit?: Record<string, CSSProperties>) {
    return {
        enter,
        exit,
    };
}

export {css, flushToStyleTag, StyleSheetServer, OriginalScheet};
export type {CSSProperties, CSSPropertiesComplete, StyleDeclarationValue, StyleDeclaration};

export function GetColor(color: keyof ThemeColors) {
    return `var(--${VAR_PREFIX}${color})`;
}
