/* Use lazy loading for huge pages please, just wrap your page into React.Suspense */
/* keep in mind that pages w/ lazy loading may brake page switch animation */
/* maybe we need a flap on page level to which would be use to enable page switch animation */
import React, {ComponentType, ReactNode, lazy} from 'react';
import SafeUpdate from 'packages/helpers/SafeUpdate';
import {matchPath, RedirectProps} from 'react-router-dom';
import * as runtypes from 'runtypes';
import Authentication from 'src/jsx/authentication/Authentication.page.react';
import Page404React from 'src/jsx/errors/404page.react';
import IconsPage from 'src/jsx/icons/Icons.react';
import DeletionRequestPage from 'src/jsx/facebook/DeletionRequestPage.react';

const Manager = lazy(() => import('src/jsx/manager/Manager.react'));
const ManagerBookings = lazy(() => import('src/jsx/manager/pages/Bookings.react'));
const ManagerBooking = lazy(() => import('src/jsx/manager/pages/Booking.react'));
const Feed = lazy(() => import('src/jsx/manager/pages/Feed.react'));
const FeedDay = lazy(() => import('src/jsx/manager/pages/FeedDay.react'));
const FeedProductDay = lazy(() => import('src/jsx/manager/pages/FeedProductDay.react'));
const FeedResourceDay = lazy(() => import('src/jsx/manager/pages/FeedResourceDay.react'));
const Products = lazy(() => import('src/jsx/manager/pages/Products.react'));
const ManagerReports = lazy(() => import('src/jsx/manager/pages/Reports.react'));
const Resources = lazy(() => import('src/jsx/manager/pages/Resources.react'));
const ProductEdit = lazy(() => import('src/jsx/manager/pages/ProductEdit.react'));
const Product = lazy(() => import('src/jsx/manager/pages/Product.react'));
const ResourceEdit = lazy(() => import('src/jsx/manager/pages/ResourceEdit.react'));
const Console = lazy(() => import('src/jsx/console/Console.react'));
const ConsoleLanding = lazy(() => import('src/jsx/console/pages/Landing.react'));
const ConsoleReports = lazy(() => import('src/jsx/console/pages/Reports.react'));
const ConsolePayouts = lazy(() => import('src/jsx/console/pages/Payouts.react'));
const ConsoleSubscriptionsPromoCodes = lazy(() => import('src/jsx/console/pages/SubscriptionsPromoCodes.react'));
const ConsoleReleaseNotes = lazy(() => import('src/jsx/console/pages/ReleaseNotes.react'));
const ConsoleUsers = lazy(() => import('src/jsx/console/pages/Users.react'));
const ConsoleInvoices = lazy(() => import('src/jsx/console/pages/Invoices.react'));
const ConsoleAdmins = lazy(() => import('src/jsx/console/pages/Admins.react'));
const ConsoleFeatured = lazy(() => import('src/jsx/console/pages/Featured.react'));
const ConsoleTouroperator = lazy(() => import('src/jsx/console/pages/Touroperator.react'));
const ConsoleTouroperators = lazy(() => import('src/jsx/console/pages/Touroperators.react'));
const ConsoleProducts = lazy(() => import('src/jsx/console/pages/Products.react'));
const ConsoleSMS = lazy(() => import('src/jsx/console/pages/SMS.react'));

const Filter = lazy(() => import('src/jsx/products/FilterPage.react'));
const FilterSettings = lazy(() => import('src/jsx/console/pages/FilterSettings.react'));
const ProductsLibraries = lazy(() => import('src/jsx/manager/pages/ProductsLibraries.react'));
const ResourcesLibraries = lazy(() => import('src/jsx/manager/pages/ResourcesLibraries.react'));
const TourOperatorMembers = lazy(() => import('src/jsx/manager/pages/TourOperatorMembers.react'));
const MemberAccess = lazy(() => import('src/jsx/manager/pages/MemberAccess.react'));
const Coupons = lazy(() => import('src/jsx/manager/pages/Coupons.react'));
const Payouts = lazy(() => import('src/jsx/manager/pages/Payouts.react'));
const CouponReact = lazy(() => import('src/jsx/manager/pages/Coupon.react'));
const ManagerMessages = lazy(() => import('src/jsx/manager/pages/Messages.react'));
const Landing = lazy(() => import('src/jsx/landing/Landing.react'));
const Demo = lazy(() => import('src/jsx/demo/Demo.react'));
const FAQ = lazy(() => import('src/jsx/faq/faq.react'));
const AppTutorials = lazy(() => import('src/jsx/appTutorials/appTutorials.react'));
const Privacy = lazy(() => import('src/jsx/privacy/privacy.react'));
const Cookie = lazy(() => import('src/jsx/cookie/cookie.react'));
const Terms = lazy(() => import('src/jsx/terms/terms.react'));
const Author = lazy(() => import('src/jsx/authors/author.react'));
const TourOperator = lazy(() => import('src/jsx/operators/operator.react'));
const Places = lazy(() => import('src/jsx/places/places.react'));
const About = lazy(() => import('src/jsx/about/about.react'));
const Profile = lazy(() => import('src/jsx/profile/profile.react'));
const MyTours = lazy(() => import('src/jsx/tours/myTours.react'));
const Favorites = lazy(() => import('src/jsx/favorites/favorites.react'));
const Tour = lazy(() => import('src/jsx/tours/tours.react'));
const Booking = lazy(() => import('src/jsx/booking/booking.react'));
const ManagerAuthors = lazy(() => import('src/jsx/manager/pages/Authors.react'));
const ManagerBilling = lazy(() => import('src/jsx/manager/pages/Billing.react'));
const ManagerBillingSales = lazy(() => import('src/jsx/manager/pages/BillingSales.react'));
const ManagerBillingPayouts = lazy(() => import('src/jsx/manager/pages/BillingPayouts.react'));
const ManagerBillingBookingsPayments = lazy(() => import('src/jsx/manager/pages/BillingBookingsPayments.react'));
const ManagerBillingInvoices = lazy(() => import('src/jsx/manager/pages/BillingInvoices.react'));
const Partners = lazy(() => import('src/jsx/partners/pages/partners.react'));
const Messages = lazy(() => import('src/jsx/messages/messages.react'));
const WidgetPageReact = lazy(() => import('src/jsx/manager/pages/WidgetPage.react'));
const WidgetsListReact = lazy(() => import('src/jsx/manager/pages/WidgetsList.react'));
const ManagerApps = lazy(() => import('src/jsx/manager/pages/Apps.react'));
const Pricing = lazy(() => import('src/jsx/pricing/pricing.react'));
const ReleasesPage = lazy(() => import('src/jsx/releasenotes/ReleasesPage.react'));

const Widget = lazy(() => import('src/jsx/widget/Widget.react'));
const Debugging = lazy(() => import('src/jsx/debug/Debug.react'));
const IFrameToursReact = lazy(() => import('src/jsx/tours/iframeTours.react'));
const OperatorsAuth = lazy(() => import('src/jsx/touroperators/TourOperatorsAuth.page.react'));
const OperatorsSuccess = lazy(() => import('src/jsx/touroperators/TourOperatorsSuccess.page.react'));
const ManagerSettings = lazy(() => import('src/jsx/manager/pages/Settings.react'));

export const EmptyMeta = runtypes.Null;

export type PageProps<T = null> = {
    id: string;
    childRoutes: Page[];
    children?: ReactNode;
    meta: {
        is_loading: boolean;
        is_error: boolean;
        payload: T;
        redirect_source?: string;
    };
};

export const pages: Page<any>[] = [];
export const redirects: RedirectProps[] = [
    {
        from: '/manager',
        to: '/manager/products/libraries',
        exact: true,
    },
];

export const Page404: Page = {
    id: '404error',
    path: '',
    exact: true,
    Component: Page404React,
    meta: EmptyMeta,
};

/* KEEP IN MIND THAT PAGE ADDED JUST HERE W/O Go HANDLER WILL BE SOFT 404 */
/* meta should be used to pass only very important and small amount of data e.g. access error otherwise page loading will be slow */
export const RoutesObject = {
    landing: {path: '', exact: true, Component: Landing, meta: EmptyMeta},
    demo: {path:'demo', exact: true, Component: Demo, meta: EmptyMeta},
    faq: {path: 'help', exact: true, Component: FAQ, meta: EmptyMeta},
    appTutorials: {path: 'app-tutorial', exact: true, Component: AppTutorials, meta: EmptyMeta},
    privacy: {path: 'privacy', exact: true, Component: Privacy, meta: EmptyMeta},
    cookie: {path: 'cookie', exact: true, Component: Cookie, meta: EmptyMeta},
    terms: {path: 'terms', exact: true, Component: Terms, meta: EmptyMeta},
    facebook_deletion: {
        path: 'facebook/deletion',
        exact: true,
        Component: DeletionRequestPage,
        meta: EmptyMeta,
    },
    messages: {path: 'messages', exact: true, Component: Messages, meta: EmptyMeta},
    icons: {path: 'icons', exact: true, Component: IconsPage, meta: EmptyMeta},
    about: {path: 'about', exact: true, Component: About, meta: EmptyMeta},
    pricing: {path: 'pricing', exact: true, Component: Pricing, meta: EmptyMeta},
    profile: {
        path: ['profile', 'profile/edit', 'profile/settings'],
        exact: true,
        Component: Profile,
        meta: EmptyMeta,
    },
    mytours: {path: 'mytours', exact: true, Component: MyTours, meta: EmptyMeta},
    favorites: {path: 'favorites', exact: true, Component: Favorites, meta: EmptyMeta},
    authors: {path: 'authors/:guid', exact: true, Component: Author, meta: EmptyMeta},
    operator: {path: 'tour-operators/:uri', exact: true, Component: TourOperator, meta: EmptyMeta},
    tour: {path: 'tours/:uri', exact: true, Component: Tour, meta: EmptyMeta},
    iframe: {path: 'iframe/tours/:uri', exact: true, Component: IFrameToursReact, meta: EmptyMeta},
    booking: {path: 'booking/:id', exact: true, Component: Booking, meta: EmptyMeta},
    places: {path: 'places/:id', exact: true, Component: Places, meta: EmptyMeta},
    operators_join: {
        path: 'for-tour-operators/join',
        exact: true,
        Component: OperatorsAuth,
        meta: EmptyMeta,
    },
    operators_success: {
        path: 'for-tour-operators/success',
        exact: true,
        Component: OperatorsSuccess,
        meta: EmptyMeta,
    },
    auth: {
        path: ['login', 'join', 'verification', 'password_reset', 'magic'],
        exact: true,
        Component: Authentication,
        meta: EmptyMeta,
    },
    manager: {
        path: 'manager',
        exact: false,
        Component: Manager,
        meta: EmptyMeta,
        subRoutes: {
            manager_product_preview: {
                path: 'products/libraries/:library_id/:product_id',
                exact: true,
                Component: Product,
                meta: EmptyMeta,
                name: 'Product Preview',
            },
            manager_product: {
                path: 'products/libraries/:library_id/:product_id/edit/:step?',
                exact: false,
                Component: ProductEdit,
                meta: EmptyMeta,
                name: 'Product',
            },
            manager_products: {
                path: 'products/libraries/:library_id',
                exact: false,
                Component: Products,
                meta: EmptyMeta,
                name: 'Products',
            },
            manager_products_libs: {
                path: 'products/libraries',
                exact: false,
                Component: ProductsLibraries,
                meta: EmptyMeta,
                name: 'Product Libraries',
            },
            manager_resource: {
                path: 'resources/libraries/:library_id/:resource_id/edit/:step?',
                exact: false,
                Component: ResourceEdit,
                meta: EmptyMeta,
                name: 'Resource',
            },
            manager_resources: {
                path: 'resources/libraries/:library_id',
                exact: false,
                Component: Resources,
                meta: EmptyMeta,
                name: 'Resources',
            },
            manager_resources_libs: {
                path: 'resources/libraries',
                exact: false,
                Component: ResourcesLibraries,
                meta: EmptyMeta,
                name: 'Resource Libraries',
            },
            manager_booking: {
                path: 'bookings/:booking_id',
                exact: false,
                Component: ManagerBooking,
                meta: EmptyMeta,
                name: 'Booking',
            },
            manager_bookings: {
                path: 'bookings',
                exact: false,
                Component: ManagerBookings,
                meta: EmptyMeta,
                name: 'Bookings',
            },
            manager_billing: {
                path: 'billing',
                exact: true,
                Component: ManagerBilling,
                meta: EmptyMeta,
                name: 'Billing',
            },
            manager_billing_sales: {
                path: 'billing/sales',
                exact: true,
                Component: ManagerBillingSales,
                meta: EmptyMeta,
                name: 'Sales reports',
            },
            manager_billing_payouts: {
                path: 'billing/payouts',
                exact: true,
                Component: ManagerBillingPayouts,
                meta: EmptyMeta,
                name: 'Payouts',
            },
            manager_billing_bookings_payments: {
                path: 'billing/bookings-payments',
                exact: true,
                Component: ManagerBillingBookingsPayments,
                meta: EmptyMeta,
                name: 'Bookings Payments',
            },
            manager_billing_invoices: {
                path: 'billing/invoices',
                exact: true,
                Component: ManagerBillingInvoices,
                meta: EmptyMeta,
                name: 'Invoices',
            },
            manager_reports: {
                path: 'reports',
                exact: false,
                Component: ManagerReports,
                meta: EmptyMeta,
                name: 'Reports',
            },
            manager_authors: {
                path: 'authors',
                exact: false,
                Component: ManagerAuthors,
                meta: EmptyMeta,
                name: 'Authors',
            },
            manager_feed_product_day: {
                path: 'feed/:date/products/:product_id',
                exact: false,
                Component: FeedProductDay,
                meta: EmptyMeta,
                name: 'Feed product day',
            },
            manager_feed_resource_day: {
                path: 'feed/:date/resources/:resource_id',
                exact: false,
                Component: FeedResourceDay,
                meta: EmptyMeta,
                name: 'Feed resource day',
            },
            manager_feed_day: {
                path: 'feed/:date',
                exact: false,
                Component: FeedDay,
                meta: EmptyMeta,
                name: 'Feed day',
            },
            manager_feed: {
                path: 'feed',
                exact: false,
                Component: Feed,
                meta: EmptyMeta,
                name: 'Feed',
            },
            manager_member: {
                path: 'members/:member_id',
                exact: false,
                Component: MemberAccess,
                meta: EmptyMeta,
                name: 'Member',
            },
            manager_members: {
                path: 'members',
                exact: false,
                Component: TourOperatorMembers,
                meta: EmptyMeta,
                name: 'Members',
            },
            manager_coupon: {
                path: 'coupons/:id',
                exact: false,
                Component: CouponReact,
                meta: EmptyMeta,
                name: 'Coupon',
            },
            manager_coupons: {
                path: 'coupons',
                exact: false,
                Component: Coupons,
                meta: EmptyMeta,
                name: 'Coupons',
            },
            manager_payouts: {
                path: 'payouts',
                exact: false,
                Component: Payouts,
                meta: EmptyMeta,
                name: 'Payouts',
            },
            manager_messages: {
                path: 'messages',
                exact: false,
                Component: ManagerMessages,
                meta: EmptyMeta,
                name: 'Messages',
            },
            manager_widget: {
                path: 'widgets/:id',
                exact: false,
                Component: WidgetPageReact,
                meta: EmptyMeta,
                name: 'Widget',
            },
            manager_widgets: {
                path: 'widgets',
                exact: false,
                Component: WidgetsListReact,
                meta: EmptyMeta,
                name: 'Widgets',
            },
            manager_apps: {
                path: 'apps',
                exact: false,
                Component: ManagerApps,
                meta: EmptyMeta,
                name: 'Applications',
            },
            manager_settings: {
                path: 'settings/:marker?',
                exact: false,
                Component: ManagerSettings,
                meta: EmptyMeta,
                name: 'Settings',
            },
        },
    },
    console: {
        path: 'console',
        exact: false,
        Component: Console,
        meta: EmptyMeta,
        subRoutes: {
            console_filter: {
                path: 'filter',
                exact: false,
                Component: FilterSettings,
                meta: EmptyMeta,
                name: 'Filter Settings',
            },
            console_invoices: {
                path: 'invoices',
                exact: false,
                Component: ConsoleInvoices,
                meta: EmptyMeta,
                name: 'Invoices',
            },
            console_users: {
                path: 'users',
                exact: false,
                Component: ConsoleUsers,
                meta: EmptyMeta,
                name: 'Console users',
            },
            console_admins: {
                path: 'admins',
                exact: false,
                Component: ConsoleAdmins,
                meta: EmptyMeta,
                name: 'Console admin',
            },
            console_featured: {
                path: 'features',
                exact: false,
                Component: ConsoleFeatured,
                meta: EmptyMeta,
                name: 'Console featured',
            },
            console_touroperator: {
                path: 'touroperators/:id/:section?',
                exact: false,
                Component: ConsoleTouroperator,
                meta: EmptyMeta,
                name: 'Console tour operator',
            },
            console_touroperators: {
                path: 'touroperators',
                exact: true,
                Component: ConsoleTouroperators,
                meta: EmptyMeta,
                name: 'Console tour operators',
            },
            console_reports: {
                path: 'reports',
                exact: false,
                Component: ConsoleReports,
                meta: EmptyMeta,
                name: 'Console reports',
            },
            console_payouts: {
                path: 'payouts',
                exact: false,
                Component: ConsolePayouts,
                meta: EmptyMeta,
                name: 'Console payouts',
            },
            console_subscriptions_promo_codes: {
                path: 'promocodes',
                exact: false,
                Component: ConsoleSubscriptionsPromoCodes,
                meta: EmptyMeta,
                name: 'Console subscriptions promo codes',
            },
            console_release_notes: {
                path: 'releases',
                exact: false,
                Component: ConsoleReleaseNotes,
                meta: EmptyMeta,
                name: 'Console release notes',
            },
            console_products: {
                path: 'products',
                exact: false,
                Component: ConsoleProducts,
                meta: EmptyMeta,
                name: 'Console products',
            },
            console_sms: {
                path: 'sms',
                exact: false,
                Component: ConsoleSMS,
                meta: EmptyMeta,
                name: 'Console sms',
            },
            console_landing: {
                path: '',
                exact: false,
                Component: ConsoleLanding,
                meta: EmptyMeta,
                name: 'Console landing',
            },
        },
    },
    partners: {path: 'partners/:page?/:step?', exact: true, Component: Partners, meta: EmptyMeta},
    filter: {path: 'tours', exact: true, Component: Filter, meta: EmptyMeta},
    releases: {path: 'releases', exact: true, Component: ReleasesPage, meta: EmptyMeta},
    widget: {path: 'widgets/:guid', exact: true, Component: Widget, meta: EmptyMeta},
    debug: {path: 'debug', exact: true, Component: Debugging, meta: EmptyMeta},
};

type PageEntry = Omit<Page, 'id' | 'subRoutes'> & {
    subRoutes?: Record<string, PageEntry>;
};

function MakePage(key: PageID, obj: PageEntry): Page {
    let page: Page = {...obj, id: key, subRoutes: []};
    if (obj.subRoutes) {
        page.subRoutes = Object.entries(obj.subRoutes).map(entry => MakePage(entry[0] as PageID, entry[1]));
    }

    return page;
}

const Routes: Page[] = Object.entries(RoutesObject).map(entry => MakePage(entry[0] as PageID, entry[1]));
AddRoutes(Routes);

export type PageID =
    | '404error'
    | keyof typeof RoutesObject
    | keyof typeof RoutesObject.manager.subRoutes
    | keyof typeof RoutesObject.console.subRoutes;

export interface Page<T = null> {
    id: PageID;
    parent_id?: string;
    path: string | string[];
    exact: boolean;
    Component: ComponentType<PageProps<T>> | React.LazyExoticComponent<React.FC>;
    meta: runtypes.Runtype;
    subRoutes?: Page<T>[];
    name?: string;
}

export function AddRoutes<T = null>(routes: Page<T>[], parent?: Page<T>) {
    routes.forEach(route => {
        if (parent) {
            route = SafeUpdate(route, {parent_id: {$set: parent.id}});
            route = SafeUpdate(route, {path: {$set: `${parent.path}/${route.path}`}});
        }

        pages.push(route);
        if (route.subRoutes?.length) {
            AddRoutes<T>(route.subRoutes, route);
        }
    });
}

export function FindPage(Pages: Page[], path: string) {
    return Pages.find(page => {
        return matchPath(path, {
            path: typeof page.path === 'string' ? `/${page.path}` : page.path.map(p => `/${p}`),
            exact: page.exact,
        });
    });
}
