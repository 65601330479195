import {css, CreateSheet, GetColor} from 'packages/aphrodite/aphrodite';
import React from 'react';
import camelCase from 'camelcase';
import WebpackContextIcon from 'packages/helpers/WebpackContextIcon';
import GetKeyFromPath from 'packages/helpers/GetKeyFromPath';

const _icons = require.context('../../../src/icons?raw', true, /\.svg$/, 'lazy-once');

export default function IconsPage() {
    const copyPath = (path: string) => (e: React.MouseEvent) => {
        const IconName = camelCase((path.split('/').pop()?.replace('.svg', '') || 'icon') + 'Icon', {pascalCase: true});
        let str = `import { ReactComponent as ${IconName} } from "src/icons/${path.replace('./', '')}";`;

        const el = document.createElement('textarea');
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        alert(`${str}\ncopied to clipboard`);
    };

    const getCategoryFromPath = (path: string) => {
        let parts = path.replace('./', '').split('/');
        parts.splice(parts.length - 1, 1);
        if (parts.length === 0) {
            return 'General';
        } else {
            return parts.map(p => camelCase(p, {pascalCase: true})).join('/');
        }
    };

    const getCategories = () => {
        let categories: Record<string, string[]> = {};

        _icons.keys().forEach(path => {
            if (!path.startsWith('src/')) {
                const category = getCategoryFromPath(path);
                if (!categories[category]) {
                    categories[category] = [];
                }

                categories[category].push(path);
            }
        });

        return categories;
    };

    return (
        <div className={css(Styles.main)}>
            {Object.entries(getCategories()).map(([category, icons]) => {
                return (
                    <section
                        className={css(Styles.category)}
                        key={category}
                    >
                        <label className={css(Styles.categoryLabel)}>{category}</label>
                        <div className={css(Styles.categoryIcons)}>
                            {icons.map((el, i) => {
                                return (
                                    <div
                                        className={css(Styles.icon)}
                                        onClick={copyPath(el)}
                                        key={i}
                                    >
                                        <div className={css(Styles.svg)}>
                                            <WebpackContextIcon
                                                icons={_icons}
                                                icon={GetKeyFromPath(el)}
                                            />
                                        </div>
                                        <div className={css(Styles.label)}>{el.split('/').pop()}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </section>
                );
            })}
        </div>
    );
}

const Styles = CreateSheet({
    main: {
        margin: 50,
    },
    svg: {
        ':nth-child(1n) svg': {
            width: 40,
            height: 40,
        },
    },
    icon: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 10,
        maxWidth: 100,
        padding: 3,
        border: '1px solid transparent',
        ':hover': {
            border: '1px solid red',
            cursor: 'pointer',
        },
    },
    label: {
        marginTop: 5,
        wordBreak: 'break-all',
        fontSize: 11,
    },
    category: {
        marginBottom: 30,
    },
    categoryLabel: {
        fontSize: 20,
        fontWeight: 'bold',
        color: GetColor('supplementary'),
        borderBottom: `1px solid ${GetColor('gray')}`,
        marginBottom: 10,
        width: '100%',
        display: 'block',
    },
    categoryIcons: {
        display: 'flex',
        flexWrap: 'wrap',
    },
});
