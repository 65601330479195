import {createBrowserHistory, Location} from 'history';
//import { TrackPageView } from './tracking';
import config from 'src/config.json';
import GA4React from 'ga-4-react';
import {Track} from 'src/runtime/pubsub/tracking';
import React from 'react';
export const history = createBrowserHistory();

const ga4react = new GA4React('G-H8ZCV9923V');

const trackGA = (loc: any) => {
    if (config.env === config.envs.production) {
        if (loc.pathname.indexOf('/manager') === -1) {
            if (GA4React.isInitialized()) {
                ga4react.pageview(loc.pathname + loc.search);
            }
        }
    }
};

if (config.env === config.envs.production) {
    const initGA = async () => {
        await ga4react.initialize();
        trackGA(window.location);
    };
    initGA();
}

history.listen(location => {
    OnHistoryChange(location);
});

export function OnHistoryChange(location: Location, init?: boolean) {
    Track('page_views', {path: location.pathname});
    trackGA(location);
}

export const OpenPage = (path: string) => (e?: React.MouseEvent) => {
    e?.preventDefault();
    history.push(`/${path}`);
    window.scrollTo(0, 0);
};

export const OpenLink = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    let path = e.currentTarget.attributes.getNamedItem('href')?.value;
    if (path) {
        history.push(`${path}`);
    }
    window.scrollTo(0, 0);
};
