import {useLocalization} from 'packages/localization/localization';
import FacebookOAuth from 'packages/facebook/FacebookOAuth';
import {history} from 'packages/history/history';
import {session} from 'packages/session/session';
import {ReactComponent as FacebookLogo} from 'src/icons/logos/facebook.svg';
import {Dark} from 'src/themes/colors';
import {CreateSheet, GetColor, css} from 'packages/aphrodite/aphrodite';

export default function FacebookOAuthReact() {
    const {t, tReady} = useLocalization('login');
    function showAuth() {
        FacebookOAuth(() => {
            session.Reload();
            history.push('/');
        });
    }

    if (!tReady) {
        return null;
    }

    return (
        <button
            type="button"
            className={css(Styles.socialBtn)}
            onClick={showAuth}
        >
            <FacebookLogo
                className={css(Styles.icon)}
                width="30"
                height="30"
            />
            <span>{t('social.facebook')}</span>
        </button>
    );
}

const Styles = CreateSheet({
    socialBtn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '8px 30px',
        borderRadius: 13,
        boxShadow: '0px 5px 15px rgba(48, 48, 48, 0.1)',
        border: `2px solid ${GetColor('gray3')}`,
        cursor: 'pointer',
        background: '#fff',

        color: Dark.default,
        fontWeight: 600,
        lineHeight: '1.429rem',
        fontSize: '1.143rem',
    },
    icon: {
        display: 'block',
        marginRight: 15,
    },
});
