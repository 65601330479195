import deepEqual from 'fast-deep-equal';
import CreateGUID from 'packages/helpers/CreateGUID';
import {Executer, State} from './model';
import {delayExecution, logging} from './utils';

export type InternalState<Payload, Args extends ReadonlyArray<unknown>> = {
    uuid: string;
    executer: Executer<Payload, Args>;
    executerName: string;
    args: Args;
    initialized: boolean;
};

export type Store<Payload, Args extends ReadonlyArray<unknown>> = {
    execute: (...args: Args) => Promise<Payload | undefined>;
    onArgsChanged: (...args: Args) => Promise<Payload | undefined>;
    subscribe: (litener: () => void) => () => void;
    setPayload: (payload: Payload | undefined) => void;
    getInternalState: () => Readonly<InternalState<Payload, Args>>;
    getState: () => Readonly<State<Payload>>;
    getServerState: () => Readonly<State<Payload>>;
};

export function InitStore<Payload, Args extends ReadonlyArray<unknown>>({
    executer,
    args,
    initialPayload,
    executerName,
    executeOnInit,
}: {
    executer: Executer<Payload, Args>;
    args: Args;
    initialPayload?: Payload;
    executerName?: string;
    executeOnInit?: boolean;
}): Store<Payload, Args> {
    const liteners = new Set<() => void>();

    let internalState: InternalState<Payload, Args> = {
        uuid: CreateGUID(),
        executer,
        executerName: executerName || executer.name || 'Unnamed',
        args,
        initialized: false,
    };

    let state: State<Payload> = {
        executionCount: 0,
        isLoading: executeOnInit ? true : false,
        payload: initialPayload,
        error: undefined,
    };

    logging(internalState.uuid, internalState.executerName, 'INITED');

    function subscribe(litener: () => void) {
        logging(internalState.uuid, internalState.executerName, 'NEW SUB');
        liteners.add(litener);
        if (!internalState.initialized && executeOnInit) {
            logging(internalState.uuid, internalState.executerName, 'INITIAL EXECUTE');
            internalState = {...internalState, initialized: true};
            delayExecution(callExecuter, true);
        }
        return () => {
            logging(internalState.uuid, internalState.executerName, 'UNSUB');
            liteners.delete(litener);
        };
    }

    function getState() {
        return Object.freeze(state);
    }

    function getInternalState() {
        return Object.freeze(internalState);
    }

    function emit() {
        liteners.forEach(emit => emit());
    }

    async function callExecuter(isInitCall?: boolean): Promise<Payload | undefined> {
        logging(internalState.uuid, internalState.executerName, 'START CALL EXECUTER');
        const executionCount = state.executionCount + 1;
        state = {...state, executionCount};

        if (!isInitCall) {
            state = {...state, isLoading: true};
            emit();
        }

        let payload: Payload | undefined = undefined,
            error: unknown = undefined;

        try {
            payload = await internalState.executer(...internalState.args);
        } catch (err) {
            error = err;
        }
        // in case there is another runner - cancel current one
        if (executionCount !== state.executionCount) {
            return undefined;
        }

        state = {...state, payload, error, isLoading: false};
        emit();

        logging(internalState.uuid, internalState.executerName, 'END CALL EXECUTER');
        return state.payload;
    }

    async function onArgsChanged(...args: Args): Promise<Payload | undefined> {
        if (!deepEqual(internalState.args, args) || !internalState.initialized) {
            internalState = {...internalState, args, initialized: true};
            return await callExecuter();
        }

        return undefined;
    }

    async function execute(...args: Args): Promise<Payload | undefined> {
        internalState = {...internalState, args, initialized: true};
        return await callExecuter();
    }

    function setPayload(payload: Payload | undefined) {
        state = {...state, payload};
        emit();
    }

    return {
        onArgsChanged,
        execute,
        subscribe,
        setPayload,
        getInternalState,
        getState,
        getServerState: getState,
    };
}
