import {get} from 'packages/rest/api';
import {DeletionRequestRuntype} from '../models';

export default async function GetDeletionRequest(code: string) {
    return await get(
        {
            url: 'oauth/facebook/deletion',
            query: {
                id: code,
            },
        },
        DeletionRequestRuntype
    );
}
