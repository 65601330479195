import CreateGUID from 'packages/helpers/CreateGUID';
import SafeUpdate from 'packages/helpers/SafeUpdate';
import * as r from 'runtypes';

export const FilterMarkers = {
    country: 'country',
    area: 'area',
    starting_point: 'starting_point',
    vehicle: 'vehicle',
    difficulty: 'difficulty',
    type: 'type',
};

export type FilterItem = {
    id: number;
    order_id: number;
    guid: string;
    parent_id: number;
    title: string;
    icon: string | null;
    products_ids: number[] | null;
    sub_items: FilterItem[] | null;
};

export const FilterItemRuntype: r.Runtype<FilterItem> = r.Lazy(() =>
    r.Record({
        id: r.Number,
        order_id: r.Number,
        guid: r.String,
        parent_id: r.Number,
        title: r.String,
        icon: r.String.Or(r.Null),
        products_ids: r.Array(r.Number).Or(r.Null),
        sub_items: r.Array(FilterItemRuntype).Or(r.Null),
    })
);

export const FilterRangeRuntype = r.Record({
    min: r.Number,
    max: r.Number,
});

export type FilterRange = r.Static<typeof FilterRangeRuntype>;

export const FilterCategoryRuntype = r.Record({
    id: r.Number,
    marker: r.String,
    guid: r.String,
    title: r.String,
    is_range: r.Boolean,
    is_constant: r.Boolean,
    table_field: r.String.Or(r.Null),
    order_id: r.Number,
    range: FilterRangeRuntype.Or(r.Null),
    items: r.Array(FilterItemRuntype).Or(r.Null),
});
export type FilterCategory = r.Static<typeof FilterCategoryRuntype>;

export const EmptyCategoryItem: () => FilterItem = () => ({
    id: 0,
    order_id: 0,
    icon: null,
    guid: CreateGUID(),
    parent_id: 0,
    title: '',
    products_ids: null,
    sub_items: [],
});

export const EmptyCategory: () => FilterCategory = () => ({
    id: 0,
    marker: '',
    guid: CreateGUID(),
    title: '',
    is_range: false,
    table_field: '',
    order_id: 0,
    is_constant: false,
    range: null,
    items: null,
});

export const FilterRuntype = r.Dictionary(FilterCategoryRuntype, r.String);
export type Filter = r.Static<typeof FilterRuntype>;

export type FilterParams = {
    high_rating: boolean;
    categories: Record<string, number[]>;
    search_key: string;
    'north-east': string;
    'south-west': string;
    'zoom-level': string;
};

export const ReservedParamsKeys = ['high_rating', 'north-east', 'south-west', 'zoom-level', 'page_id', 'q'];

export const AutocompleteItemRuntype = r.Record({
    id: r.Number,
    keyword: r.String,
    language_id: r.Number,
    country_filter_id: r.Number,
    area_filter_id: r.Number.Or(r.Null),
});
export type AutocompleteItem = r.Static<typeof AutocompleteItemRuntype>;

export function NormalizeFilterParamsForAPI(filter_params: FilterParams) {
    let params: Record<string, any> = {};
    const categorires = {...filter_params.categories};
    params = SafeUpdate(filter_params, {$unset: ['categories', 'search_key']});
    Object.entries(categorires).forEach(([key, values]) => {
        params = SafeUpdate(params, {[key]: {$set: values.join(',')}});
    });

    params['q'] = filter_params.search_key || '';

    return params;
}
