import * as r from 'runtypes';

export const Language = {
    en: 1,
    de: 2,
    nl: 4,
    it: 3,
    ru: 5,
    fr: 6,
    es: 7,
};
export type LanguageType = keyof typeof Language;

export const LanguageTitle = {
    en: 'English',
    de: 'German',
    it: 'Italian',
    nl: 'Dutch',
    ru: 'Russian',
    fr: 'French',
    es: 'Spanish',
};
export type LanguageTitleType = keyof typeof LanguageTitle;

export const TranslationStatus = {
    Draft: 1,
    Active: 2,
};
export type TranslationStatusType = keyof typeof TranslationStatus;

export function IsAvailableLanguage(lang: string): lang is LanguageType {
    return Object.keys(Language).findIndex(l => l === lang) >= 0;
}

export const ItemTranslationStatusRuntype = r.Record({
    language: r.String,
    status_id: r.Number,
});
export type ItemTranslationStatus = r.Static<typeof ItemTranslationStatusRuntype>;
