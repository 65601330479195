import Button, {ButtonTheme, ButtonLayout} from 'packages/buttons/button';
import LoadButtonReact from 'packages/buttons/LoadButton.react';
import {
    Primary,
    Danger,
    Dark,
    White,
    Secondary,
    Success,
    PrimaryManager,
    PartnersGradient,
    PartnersDarkGradient,
    GrayLight,
    Partners,
} from './colors';
import {GetColor} from 'packages/aphrodite/aphrodite';

export const DefaultLayout: ButtonLayout = {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: '1rem',
    borderRadius: 10,
    padding: '12px 28px',
    cursor: 'pointer',
    textAlign: 'center',
    transition: 'box-shadow .25s ease-in, filter .25s ease-in, width .25s ease-in',
    fontWeight: 600,
    border: 0,
};

export const AvailabilityLayout: ButtonLayout = {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: '1rem',
    borderRadius: 7,
    padding: '10px 15px',
    cursor: 'pointer',
    textAlign: 'center',
    transition: 'box-shadow .25s ease-in, filter .25s ease-in, width .25s ease-in',
    fontWeight: 600,
};

export const PrimaryFilled: ButtonTheme = {
    plain: {
        background: `linear-gradient(94.46deg, ${GetColor('accentLinearStart')} 2.67%, ${GetColor(
            'accentLinearEnd'
        )} 95.69%)`,
        color: GetColor('whiteTextColor'),
        borderColor: undefined,
        boxShadow: `0px 5px 20px 0px ${GetColor('shadowAccentColor')}`,
    },
    hover: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: `0px 5px 20px 5px ${GetColor('shadowAccentColor')}`,
        filter: `brightness(1.1)`,
    },
    active: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: undefined,
    },
};

export const PrimaryManagerFilled: ButtonTheme = {
    plain: {
        background: `linear-gradient(126.13deg, #DB4179 11.39%, ${GetColor('accentDark')} 100%)`,
        color: White.default,
        borderColor: undefined,
        boxShadow: `0px 10px 20px -10px rgba(193, 24, 86, 0.4)`,
    },
    hover: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: `0px 13px 20px -10px rgba(193, 24, 86, 0.5)`,
        filter: `brightness(1.1)`,
    },
    active: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: undefined,
    },
};

export const PrimaryManagerInverted: ButtonTheme = {
    plain: {
        background: '#FFFFFF',
        color: GetColor('accentDark'),
        borderColor: undefined,
        boxShadow: undefined,
    },
    hover: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: undefined,
        filter: undefined,
    },
    active: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: undefined,
    },
};

export const PrimaryManagerBordered: ButtonTheme = {
    plain: {
        background: 'transparent',
        color: GetColor('accentDark'),
        borderColor: GetColor('accentDark'),
        boxShadow: undefined,
    },
    hover: {
        background: 'transparent',
        color: GetColor('accentDark'),
        borderColor: GetColor('accentDark'),
        boxShadow: undefined,
    },
    active: {
        background: 'transparent',
        color: Dark.active,
        borderColor: GetColor('accentDark'),
        boxShadow: undefined,
    },
};

export const PrimaryManagerTransparent: ButtonTheme = {
    plain: {
        background: 'transparent',
        color: PrimaryManager.default,
        borderColor: undefined,
        boxShadow: undefined,
    },
    hover: {
        background: undefined,
        color: PrimaryManager.hover,
        borderColor: undefined,
        boxShadow: undefined,
    },
    active: {
        background: undefined,
        color: PrimaryManager.active,
        borderColor: undefined,
        boxShadow: undefined,
    },
};

export const PrimaryTransparent: ButtonTheme = {
    plain: {
        background: 'transparent',
        color: GetColor('accent'),
        borderColor: undefined,
        boxShadow: 'none',
    },
    hover: {
        background: undefined,
        color: GetColor('accent'),
        borderColor: undefined,
        boxShadow: 'none',
    },
    active: {
        background: undefined,
        color: GetColor('accent'),
        borderColor: undefined,
        boxShadow: 'none',
    },
};

export const FrontTransparent: ButtonTheme = {
    plain: {
        background: 'transparent',
        color: Dark.default,
        borderColor: Dark.default,
        boxShadow: undefined,
    },
    hover: {
        background: 'transparent',
        color: Dark.hover,
        borderColor: Dark.hover,
        boxShadow: undefined,
    },
    active: {
        background: 'transparent',
        color: Dark.active,
        borderColor: Dark.active,
        boxShadow: undefined,
    },
};

export const PrimaryBordered: ButtonTheme = {
    plain: {
        background: 'transparent',
        color: Primary.default,
        borderColor: Primary.default,
        boxShadow: undefined,
    },
    hover: {
        background: undefined,
        color: Primary.hover,
        borderColor: Primary.active,
        boxShadow: undefined,
    },
    active: {
        background: undefined,
        color: Primary.active,
        borderColor: Primary.active,
        boxShadow: undefined,
    },
};

export const PartnersFilled: ButtonTheme = {
    plain: {
        background: PartnersGradient.default,
        color: White.default,
        borderColor: undefined,
        boxShadow: '0px 7px 20px -10px rgba(41, 56, 124, 0.7)',
    },
    hover: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: undefined,
        filter: undefined,
    },
    active: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: undefined,
    },
};

export const PartnersDarkFilled: ButtonTheme = {
    plain: {
        background: PartnersDarkGradient.default,
        color: White.default,
        borderColor: undefined,
        boxShadow: '0px 4px 15px -5px rgba(105, 105, 105, 0.5)',
    },
    hover: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: undefined,
        filter: undefined,
    },
    active: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: undefined,
    },
};

export const PartnersBordered: ButtonTheme = {
    plain: {
        background: 'transparent',
        color: Partners.default,
        borderColor: Partners.default,
        boxShadow: undefined,
    },
    hover: {
        background: undefined,
        color: Partners.hover,
        borderColor: Partners.active,
        boxShadow: undefined,
    },
    active: {
        background: undefined,
        color: Partners.active,
        borderColor: Partners.active,
        boxShadow: undefined,
    },
};

export const SecondaryFilled: ButtonTheme = {
    plain: {
        background: Secondary.default,
        color: White.default,
        borderColor: Secondary.default,
        boxShadow: undefined,
    },
    hover: {
        background: Secondary.default,
        color: White.default,
        borderColor: Secondary.default,
        boxShadow: undefined,
    },
    active: {
        background: Secondary.active,
        color: White.default,
        borderColor: Secondary.active,
        boxShadow: undefined,
    },
};

export const SecondaryBordered: ButtonTheme = {
    plain: {
        background: 'transparent',
        color: Secondary.default,
        borderColor: Secondary.default,
        boxShadow: undefined,
    },
    hover: {
        background: undefined,
        color: Secondary.hover,
        borderColor: Secondary.active,
        boxShadow: undefined,
    },
    active: {
        background: undefined,
        color: Secondary.active,
        borderColor: Secondary.active,
        boxShadow: undefined,
    },
};

export const DangerFilled: ButtonTheme = {
    plain: {
        background: Danger.default,
        color: 'white',
        borderColor: undefined,
        boxShadow: undefined,
    },
    hover: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: `0 8px 25px -8px ${Danger.hover}`,
    },
    active: {
        background: Danger.active,
        color: undefined,
        borderColor: undefined,
        boxShadow: 'none',
    },
};

export const DangerTransparent: ButtonTheme = {
    plain: {
        background: 'transparent',
        color: Danger.default,
        borderColor: 'transparent',
        boxShadow: undefined,
    },
    hover: {
        background: undefined,
        color: Danger.hover,
        borderColor: undefined,
        boxShadow: undefined,
    },
    active: {
        background: undefined,
        color: Danger.active,
        borderColor: undefined,
        boxShadow: undefined,
    },
};

export const DangerBordered: ButtonTheme = {
    plain: {
        background: 'transparent',
        color: Danger.default,
        borderColor: Danger.default,
        boxShadow: undefined,
    },
    hover: {
        background: undefined,
        color: Danger.hover,
        borderColor: Danger.active,
        boxShadow: undefined,
    },
    active: {
        background: undefined,
        color: Danger.active,
        borderColor: Danger.active,
        boxShadow: undefined,
    },
};

export const WhiteTransparent: ButtonTheme = {
    plain: {
        background: 'transparent',
        color: White.default,
        borderColor: undefined,
        boxShadow: undefined,
    },
    hover: {
        background: undefined,
        color: `${White.hover}99`,
        borderColor: undefined,
        boxShadow: undefined,
    },
    active: {
        background: undefined,
        color: `${White.active}99`,
        borderColor: undefined,
        boxShadow: undefined,
    },
};

export const WhiteFilled: ButtonTheme = {
    plain: {
        background: White.default,
        color: Dark.default,
        borderColor: undefined,
        boxShadow: undefined,
    },
    hover: {
        background: White.hover,
        color: `${Dark.default}`,
        borderColor: undefined,
        boxShadow: undefined,
    },
    active: {
        background: White.active,
        color: `${Dark.default}`,
        borderColor: undefined,
        boxShadow: undefined,
    },
};

export const DarkFilled: ButtonTheme = {
    plain: {
        background: `linear-gradient(94.46deg, ${GetColor('primaryLinearStart')} 2.67%, ${GetColor(
            'primaryLinearEnd'
        )} 95.69%)`,
        color: White.default,
        borderColor: undefined,
        boxShadow: undefined,
    },
    hover: {
        background: `linear-gradient(94.46deg, ${GetColor('primaryLinearStart')} 2.67%, ${GetColor(
            'primaryLinearEnd'
        )} 95.69%)`,
        color: White.default,
        borderColor: undefined,
        boxShadow: undefined,
    },
    active: {
        background: `linear-gradient(94.46deg, ${GetColor('primaryLinearStart')} 2.67%, ${GetColor(
            'primaryLinearEnd'
        )} 95.69%)`,
        color: White.default,
        borderColor: undefined,
        boxShadow: undefined,
    },
};

export const DarkTransparent: ButtonTheme = {
    plain: {
        background: 'transparent',
        color: Dark.default,
        borderColor: undefined,
        boxShadow: undefined,
    },
    hover: {
        background: undefined,
        color: Dark.default,
        borderColor: undefined,
        boxShadow: undefined,
    },
    active: {
        background: undefined,
        color: Dark.default,
        borderColor: undefined,
        boxShadow: undefined,
    },
};

export const GrayLightTransparent: ButtonTheme = {
    plain: {
        background: 'transparent',
        color: GrayLight.default,
        borderColor: undefined,
        boxShadow: 'none',
    },
    hover: {
        background: undefined,
        color: GrayLight.default,
        borderColor: undefined,
        boxShadow: 'none',
    },
    active: {
        background: undefined,
        color: GrayLight.default,
        borderColor: undefined,
        boxShadow: 'none',
    },
};

export const DarkBordered: ButtonTheme = {
    plain: {
        background: 'transparent',
        color: Dark.default,
        borderColor: Dark.default,
        boxShadow: undefined,
    },
    hover: {
        background: undefined,
        color: Dark.hover,
        borderColor: Dark.active,
        boxShadow: undefined,
    },
    active: {
        background: undefined,
        color: Dark.active,
        borderColor: Dark.active,
        boxShadow: undefined,
    },
};

export const GraySemiFilled: ButtonTheme = {
    plain: {
        background: `${White.default}19`,
        color: White.default,
        borderColor: undefined,
        boxShadow: undefined,
    },
    hover: {
        background: `${White.default}4C`,
        color: undefined,
        borderColor: undefined,
        boxShadow: undefined,
    },
    active: {
        background: `${White.default}4C`,
        color: undefined,
        borderColor: undefined,
        boxShadow: undefined,
    },
};

export const GrayFilled: ButtonTheme = {
    plain: {
        background: `${GrayLight.default}`,
        color: White.default,
        borderColor: undefined,
        boxShadow: undefined,
    },
    hover: {
        background: `${GrayLight.hover}`,
        color: undefined,
        borderColor: undefined,
        boxShadow: undefined,
    },
    active: {
        background: `${GrayLight.active}`,
        color: undefined,
        borderColor: undefined,
        boxShadow: undefined,
    },
};

export const ButtonBg: ButtonTheme = {
    plain: {
        background: GetColor('buttonBg'),
        color: GetColor('primary'),
        borderColor: undefined,
        boxShadow: undefined,
    },
    hover: {
        background: GetColor('buttonBg'),
        color: undefined,
        borderColor: undefined,
        boxShadow: undefined,
    },
    active: {
        background: GetColor('buttonBg'),
        color: undefined,
        borderColor: undefined,
        boxShadow: undefined,
    },
};

export const SuccessFilled: ButtonTheme = {
    plain: {
        background: GetColor('success'),
        color: 'white',
        borderColor: undefined,
        boxShadow: undefined,
    },
    hover: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: `0 8px 25px -8px ${Success.hover}`,
    },
    active: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: 'none',
    },
};

export const AvailabilityLight: ButtonTheme = {
    plain: {
        background: White.default,
        color: Dark.default,
        borderColor: undefined,
        boxShadow: '0px 4px 10px rgba(48, 48, 48, 0.07)',
    },
    hover: {
        background: White.hover,
        color: `${Dark.default}`,
        borderColor: undefined,
        boxShadow: '0px 4px 10px rgba(48, 48, 48, 0.15)',
    },
    active: {
        background: White.active,
        color: `${Dark.default}`,
        borderColor: undefined,
        boxShadow: undefined,
    },
};

export const WhiteBordered: ButtonTheme = {
    plain: {
        background: White.default,
        color: Dark.default,
        borderColor: GetColor('gray'),
        boxShadow: undefined,
    },
    hover: {
        background: White.hover,
        color: `${Dark.default}`,
        borderColor: GetColor('gray'),
        boxShadow: undefined,
    },
    active: {
        background: White.active,
        color: `${Dark.default}`,
        borderColor: GetColor('gray'),
        boxShadow: undefined,
    },
};

export function IsStyleTheme(pet: any | ButtonTheme): pet is ButtonTheme {
    return (pet as ButtonTheme).plain !== undefined;
}

export function MakeButton(theme: ButtonTheme, layout?: ButtonLayout) {
    return Button({...DefaultLayout, ...layout}, theme);
}

type props = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    loading: boolean;
    theme: ButtonTheme;
    layout?: ButtonLayout;
};

export function LoadButton(props: props) {
    return (
        <LoadButtonReact
            {...{
                ...props,
                layout: {...DefaultLayout, ...props.layout},
            }}
        />
    );
}
