import React, {Component} from 'react';
import CreateGUID from 'packages/helpers/CreateGUID';
import {CreateSheet, css} from 'packages/aphrodite/aphrodite';
import {InputTheme} from './Input';
import {MakeInput} from 'src/themes/input';
import {CodeInputLayout} from './CodeInput.theme';

const BACKSPACE_KEY = 8;
const LEFT_ARROW_KEY = 37;
const UP_ARROW_KEY = 38;
const RIGHT_ARROW_KEY = 39;
const DOWN_ARROW_KEY = 40;

export type InputModeTypes = 'numeric' | 'text';

export interface ReactCodeInputProps {
    fields: number;
    onChange?: (value: string) => void;
    rootclassName?: string;
    inputTheme: InputTheme;
    inputLayout: CodeInputLayout;
    isValid: boolean;
    disabled: boolean;
    autoFocus?: boolean;
    filterKeyCodes: Array<number>;
    filterChars: Array<string>;
    inputMode: InputModeTypes;
}

type state = {
    fields: number;
    value: string;
    input: Array<any>;
    isValid: boolean;
    disabled: boolean;
    filterKeyCodes: Array<number>;
};

export default class ReactCodeInput extends Component<ReactCodeInputProps, state> {
    public static defaultProps = {
        autoFocus: true,
        isValid: true,
        disabled: false,
        fields: 4,
        value: '',
        filterKeyCodes: [189, 190],
        filterChars: ['-', '.'],
    };

    private textInput: Array<any> = [];
    private uuid: string = '';

    constructor(props: ReactCodeInputProps) {
        super(props);

        const {fields, isValid, disabled, filterKeyCodes} = props;

        this.state = {
            input: [],
            value: '',
            fields,
            isValid,
            disabled,
            filterKeyCodes,
        };

        for (let i = 0; i < Number(this.state.fields); i += 1) {
            if (i < 32) {
                const value = this.state.value[i] || '';
                this.state.input.push(value);
            }
        }

        this.textInput = [];

        this.uuid = CreateGUID();
    }

    handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        const {filterChars} = this.props;

        let value = String(e.target.value);

        if (this.props.inputMode === 'numeric') {
            value = value.replace(/[^\d]/g, '');
        }

        /** Filter Chars */
        value = value
            .split('')
            .filter(currChar => {
                return !filterChars.includes(currChar);
            })
            .join('');

        let fullValue = value;

        if (value !== '') {
            const input = this.state.input.slice();

            if (value.length > 1) {
                value.split('').map((chart, i) => {
                    if (Number(e.target.dataset.id) + i < this.props.fields) {
                        input[Number(e.target.dataset.id) + i] = chart;
                    }
                    return false;
                });
            } else {
                input[Number(e.target.dataset.id)] = value;
            }

            input.map((s, i) => {
                if (this.textInput[i]) {
                    this.textInput[i].value = s;
                }
                return false;
            });

            const dataset = e.target.dataset;
            if (dataset && dataset.id) {
                const id = Number(dataset.id);

                const newTarget = this.textInput[id < input.length ? id + 1 : id];

                if (newTarget) {
                    newTarget.focus();
                    newTarget.select();
                }

                fullValue = input.join('');

                this.setState({value: input.join(''), input});
            }
        }

        if (this.props.onChange && fullValue) {
            this.props.onChange(fullValue);
        }
    }

    handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        //@ts-ignore
        const target = Number(e.target.dataset.id),
            nextTarget = this.textInput[target + 1],
            prevTarget = this.textInput[target - 1];

        let input, value;

        if (this.state.filterKeyCodes.length > 0) {
            this.state.filterKeyCodes.forEach(item => {
                if (item === e.keyCode) {
                    e.preventDefault();
                    return true;
                }
            });
        }

        switch (e.keyCode) {
            case BACKSPACE_KEY:
                e.preventDefault();
                this.textInput[target].value = '';
                input = this.state.input.slice();
                input[target] = '';
                value = input.join('');

                this.setState({value, input});
                if (this.textInput[target].value === '') {
                    if (prevTarget) {
                        prevTarget.focus();
                        prevTarget.select();
                    }
                }
                if (this.props.onChange) {
                    this.props.onChange(value);
                }
                break;

            case LEFT_ARROW_KEY:
                e.preventDefault();
                if (prevTarget) {
                    prevTarget.focus();
                    prevTarget.select();
                }
                break;

            case RIGHT_ARROW_KEY:
                e.preventDefault();
                if (nextTarget) {
                    nextTarget.focus();
                    nextTarget.select();
                }
                break;

            case UP_ARROW_KEY:
                e.preventDefault();
                break;

            case DOWN_ARROW_KEY:
                e.preventDefault();
                break;

            default:
                break;
        }
    }

    render() {
        const {autoFocus, inputMode, rootclassName} = this.props;
        const {disabled, input, isValid} = this.state;
        const Styles = StylesWithTheme(this.props.inputLayout);

        return (
            <div className={`${css(Styles.base)} ${rootclassName || ''}`}>
                {input.map((value, i) => {
                    return (
                        <input
                            {...{
                                className: css(
                                    Styles.input,
                                    MakeInput(this.props.inputTheme, {
                                        width: 45,
                                        fontSize: 20,
                                        error: !isValid,
                                        noIcon: true,
                                    })
                                ),
                                ref: ref => {
                                    this.textInput[i] = ref;
                                },
                                id: `${this.uuid}-${i}`,
                                'data-id': i,
                                autoFocus: autoFocus && i === 0 ? true : false,
                                value: value,
                                key: `input_${i}`,
                                type: 'text',
                                min: 0,
                                max: 9,
                                maxLength: input.length === i + 1 ? 1 : input.length,
                                onFocus: e => e.target.select(),
                                onChange: e => this.handleChange(e),
                                onKeyDown: e => this.handleKeyDown(e),
                                disabled: disabled,
                                'data-valid': isValid,
                                inputMode: inputMode,
                            }}
                        />
                    );
                })}
            </div>
        );
    }
}

const StylesWithTheme = (layout: CodeInputLayout) => {
    return CreateSheet({
        base: {
            display: 'flex',
        },
        input: {
            fontFamily: 'monospace',
            MozAppearance: 'textfield',
            boxSizing: 'border-box',
            appearance: 'none',
            '-webkit-appearance': 'none',
            '-moz-appearance': 'none',
            ...layout,
        },
    });
};
