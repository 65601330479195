import CreateGUID from 'packages/helpers/CreateGUID';
import Config from 'src/config.json';
import {Connection} from 'src/runtime/pubsub/server';

export default function FacebookOAuth(onFinishedOAuthCallback: () => void) {
    const StateGUID = CreateGUID();

    let OAuthWindow: Window | null = null;

    Connection.CreateSubscription('oauth', StateGUID, onFinishedOAuth);
    console.log(window.location.origin + Config.facebook.oauth.redirect_uri);
    const config = {
        oauth_url: Config.facebook.oauth.auth_uri,
        client_id: Config.facebook.oauth.client_id,
        redirect_uri: window.location.origin + Config.facebook.oauth.redirect_uri,
        state: StateGUID,
        scope: Config.facebook.oauth.scope,
    };

    const windowName = 'Facebook Login';
    const windowOptions = 'location=0,status=0,width=500,height=800,left=100,top=100';

    let authURL = `${config.oauth_url}?`;
    let parts: string[] = [
        `client_id=${encodeURIComponent(config.client_id)}`,
        `redirect_uri=${encodeURIComponent(config.redirect_uri)}`,
        `state=${encodeURIComponent(config.state)}`,
        `scope=${encodeURIComponent(config.scope)}`,
    ];

    authURL = authURL + parts.join('&');

    OAuthWindow = window.open(authURL, windowName, windowOptions);

    function onFinishedOAuth() {
        if (OAuthWindow) {
            OAuthWindow.close();
        }
        Connection.RemoveSubscription('oauth', StateGUID, onFinishedOAuth);
        onFinishedOAuthCallback();
    }
}
