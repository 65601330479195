import {useLocalization} from 'packages/localization/localization';
import {css, CreateSheetWithTheme, useTheme, GetColor} from 'aphrodite';
import {LoadButton} from 'src/themes/button';
import {MakeInput} from 'src/themes/input';
import React, {useState} from 'react';
import SmoothLine from 'packages/motion/SmoothLine.react';
import {FormStyles} from 'src/themes/form';
import {Dark, GrayLight, Primary} from 'src/themes/colors';
import PlusSignVerifier from 'packages/helpers/PlusSignVerifier';
import useSimpleError from 'packages/hooks/useSimpleError';
import {SignInTheme} from './SignIn.react';
import {RequstSignInMagicLinkAPI} from 'packages/session/API/RequstSignInMagicLink';
import {useQueryParser} from 'typed-query-parser';
import {ReactComponent as MagicStickIcon} from 'src/icons/magic-stick.svg';
import {ReactComponent as MagicMailboxIcon} from 'src/icons/magic-mailbox.svg';
import Trans from 'packages/localization/Trans';
import UnescapedTrans from 'packages/localization/UnescapedTrans.react';

export default function MagicReact({
    theme,
    identifier,
    origin,
}: {
    theme: SignInTheme;
    identifier?: string;
    origin?: string;
}) {
    const [{invalid: queryIdentifier, origin: queryOrigin}] = useQueryParser(window.location.search, ({string}) => ({
        invalid: string(),
        origin: string(),
    }));

    const {t, tReady} = useLocalization('login');
    const Styles = useTheme(theme, StylesWithTheme);
    const [loading, setLoading] = useState(false);
    const [isLinkSent, setIsLinkSent] = useState(false);
    const [ident, setIdent] = useState(PlusSignVerifier(identifier || queryIdentifier || ''));
    const [hasErr, setErr] = useSimpleError<'ident', string>();

    async function onSubmit(e: React.FormEvent) {
        e.preventDefault();

        if (ident.trim().length === 0) {
            setErr('ident', t('errors.identReq'));
            return;
        }

        setLoading(true);
        const err = await RequstSignInMagicLinkAPI(ident, encodeURIComponent(queryOrigin || origin || ''));
        setLoading(false);

        if (err !== null) {
            setErr('ident', err.text);
        } else {
            setIsLinkSent(true);
        }
    }

    const isLinkExpired = typeof queryIdentifier !== 'undefined';

    if (!tReady) {
        return null;
    }

    return (
        <form
            {...{
                method: 'POST',
                className: css(FormStyles.form, Styles.form),
                onSubmit: onSubmit,
            }}
        >
            <SmoothLine motion={['fade', 'swipe-right']}>
                {!isLinkSent ? (
                    <>
                        <div className={css(Styles.formHeader)}>
                            <MagicStickIcon className={css(Styles.icon)} />
                            <div className={css(Styles.formHeaderTitle)}>
                                {isLinkExpired ? t('magic.expiredTitle') : t('magic.title')}
                            </div>
                            <div className={css(Styles.formHeaderText)}>
                                <UnescapedTrans
                                    t={t}
                                    i18nKey={isLinkExpired ? 'magic.expiredText' : 'magic.text'}
                                >
                                    Enter an email address so we can send you a magic link for quickly singing in to{' '}
                                    <strong>DGtalguide</strong>.
                                </UnescapedTrans>
                            </div>
                        </div>
                        <input
                            {...{
                                id: 'identifier',
                                type: 'text',
                                placeholder: t('inputs.emailPhone'),
                                autoFocus: true,
                                required: true,
                                autoComplete: 'off',
                                value: ident,
                                onChange: e => {
                                    setErr('ident', false);
                                    setIdent(PlusSignVerifier(e.target.value.trim()));
                                },
                                disabled: Boolean(identifier && identifier.length > 0),
                                className: css(
                                    MakeInput(theme.input, {
                                        fontSize: 16,
                                        fontWeight: 400,
                                        padding: '8px 16px',
                                        width: '100%',
                                        borderRadius: 12,
                                        lineHeight: '28px',
                                    }),
                                    Styles.input
                                ),
                            }}
                        />
                        <div className={css(Styles.error)}>
                            <SmoothLine motion={['fade', 'swipe-up']}>{hasErr('ident')}</SmoothLine>
                        </div>
                        <LoadButton
                            {...{
                                type: 'submit',
                                loading: loading,
                                className: css(Styles.submit),
                                theme: theme.button,
                                layout: {
                                    width: '100%',
                                    padding: '15px 0',
                                    fontSize: '1.1429rem',
                                    lineHeight: 1,
                                    fontWeight: 600,
                                    whiteSpace: 'nowrap',
                                    borderRadius: 13,
                                },
                            }}
                        >
                            {t('signIn.btn')}
                        </LoadButton>
                    </>
                ) : undefined}
            </SmoothLine>
            <SmoothLine motion={['fade', 'swipe-right']}>
                {isLinkSent ? (
                    <div className={css(Styles.formHeader)}>
                        <MagicMailboxIcon className={css(Styles.mailboxIcon)} />
                        <div className={css(Styles.formHeaderTitle)}>{t('magic.sentTitle')}</div>
                        <div className={css(Styles.formHeaderText)}>
                            <Trans
                                t={t}
                                i18nKey="magic.sentText"
                                values={{ident}}
                            >
                                We just sent a message to you at <strong>email address</strong>. It contains a magic
                                link that signs you in.
                            </Trans>
                        </div>
                    </div>
                ) : undefined}
            </SmoothLine>
            <div className={css(Styles.divider)}>
                <div className={css(Styles.line)} />
                <div className={css(Styles.dividerText)}>or</div>
                <div className={css(Styles.line)} />
            </div>
        </form>
    );
}

const StylesWithTheme = CreateSheetWithTheme((theme?: SignInTheme) => {
    return {
        form: {
            position: 'relative',
            padding: '0 20px',
            zIndex: 2,
            marginBottom: 0,
        },
        formHeader: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        formHeaderTitle: {
            textAlign: 'center',
            marginBottom: 10,
            fontWeight: 700,
            fontSize: '1.143rem',
            fontHeight: '1.429rem',
            color: Dark.default,
        },
        formHeaderText: {
            textAlign: 'center',
            marginBottom: 25,
            fontWeight: 500,
            fontSize: '1rem',
            fontHeight: '1.214rem',
            color: Dark.default,
        },
        icon: {
            display: 'block',
            marginBottom: 50,
        },
        mailboxIcon: {
            display: 'block',
            marginBottom: 40,
        },
        error: {
            display: 'flex',
            justifyContent: 'flex-start',
            fontSize: '0.85714rem',
            color: Primary.default,
            textAlign: 'center',
            fontWeight: 600,
            height: 28,
            paddingTop: 10,
        },
        input: {
            display: 'block',
        },
        submit: {
            display: 'block',
            margin: '25px auto 0 auto',
        },
        divider: {
            margin: '20px 0',
            display: 'flex',
            alignItems: 'center',
            gap: 15,
        },
        line: {
            height: 1,
            width: '100%',
            background: GetColor('gray3'),
        },
        dividerText: {
            color: GrayLight.default,
            fontWeight: 500,
            fontSize: '1.143rem',
            lineHeight: '1.429rem',
            letterSpacing: '0.03em',
            textTransform: 'lowercase',
        },
    };
});
