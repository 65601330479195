const log = console.log;
const enabled = false;

export function logging(uuid: string, executer: string, ...rest: any) {
    if (!enabled) {
        return;
    }
    console.group('STORE', ...rest);
    log(`%cexecuter: ${executer}, uuid: ${uuid}, `, 'color:303030; font-weight: 500;');
    console.groupEnd();
}

export function delayExecution<Response, Args extends ReadonlyArray<unknown>>(
    func: (...args: Args) => Promise<Response> | Response,
    ...args: Args
) {
    return new Promise<Response>((resolve, reject) => {
        setTimeout(async () => {
            try {
                const res = await func(...args);
                resolve(res);
            } catch (error) {
                reject(error);
            }
        }, 0);
    });
}
