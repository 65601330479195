import {PropsWithChildren, ReactNode} from 'react';

export type SuspenseProps = PropsWithChildren<{
    fallback: ReactNode;
    initialOnly?: boolean;
    onTop?: boolean;
}>;

export default function suspensible({shouldSuspense, hasPayload}: {shouldSuspense: boolean; hasPayload: boolean}) {
    return function ({fallback, children, onTop, initialOnly}: SuspenseProps) {
        return (
            <>
                {onTop ? (
                    <>
                        {children}
                        {shouldSuspense && fallback}
                    </>
                ) : shouldSuspense && (!initialOnly || !hasPayload) ? (
                    fallback
                ) : (
                    children
                )}
            </>
        );
    };
}
