import {LightboxTheme} from 'packages/lightbox/lightbox';
import {Dark, PrimaryManagerGradient, White} from './colors';
import {GetColor} from 'packages/aphrodite/aphrodite';

export const LightTheme: LightboxTheme = {
    overlay: {
        backgroundColor: 'rgba(48, 48, 48, 0.5)',
        closeIconColor: White.default,
        hoverIconColor: 'rgba(196, 196, 196, 0.2)',
    },
    lightbox: {
        backgroundColor: White.default,
        color: Dark.default,
        boxShadow: '0px 10px 20px rgba(48, 48, 48, 0.15)',
        closeIconColor: White.default,
        hoverIconColor: 'rgba(196, 196, 196, 0.2)',
    },
    header: {
        background: PrimaryManagerGradient.default,
        color: White.default,
    },
    body: {
        backgroundColor: undefined,
        color: undefined,
    },
    footer: {
        backgroundColor: undefined,
        color: undefined,
        botderTop: undefined,
    },
};

export const DarkTheme: LightboxTheme = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        closeIconColor: White.default,
        hoverIconColor: 'rgba(196, 196, 196, 0.2)',
    },
    lightbox: {
        backgroundColor: GetColor('primary'),
        color: GetColor('gray2'),
        boxShadow: '0 5px 20px 0 rgba(34,41,47,.1)',
        closeIconColor: White.default,
        hoverIconColor: 'rgba(196, 196, 196, 0.2)',
    },
    header: {
        background: GetColor('primary'),
        color: undefined,
    },
    body: {
        backgroundColor: undefined,
        color: White.default,
    },
    footer: {
        backgroundColor: undefined,
        color: undefined,
        botderTop: '1px solid #3b4253',
    },
};

export const WarningLight: LightboxTheme = {
    ...LightTheme,
    header: {
        ...LightTheme.header,
    },
};

export const DemoLight: LightboxTheme = {
    ...LightTheme,
    lightbox: {
        ...LightTheme.lightbox,
        closeIconColor: Dark.default,
    },
};
