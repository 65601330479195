import {SignInTheme} from 'src/jsx/authentication/modules/SignIn.react';
import {SignUpTheme} from 'src/jsx/authentication/modules/SignUp.react';
import {VerificationTheme} from 'src/jsx/authentication/modules/Verification.react';
import {ResetPasswordTheme} from 'src/jsx/authentication/modules/ResetPassword.react';
import {PrimaryFilled, PrimaryTransparent} from './button';
import {Primary, Dark} from './colors';
import {PrimaryInputLight} from './input';

export const LightSignIn: SignInTheme = {
    input: PrimaryInputLight,
    button: PrimaryFilled,
    label_color: Dark.default,
    reset_color: Primary.default,
};

export const LightSignUp: SignUpTheme = {
    input: PrimaryInputLight,
    button: PrimaryFilled,
    label_color: Dark.default,
};

export const LightVerification: VerificationTheme = {
    input: PrimaryInputLight,
    button: PrimaryFilled,
    resend_buttom: PrimaryTransparent,
    label_color: Dark.default,
};

export const LightResetPassword: ResetPasswordTheme = {
    input: PrimaryInputLight,
    button: PrimaryFilled,
    label_color: Dark.default,
};
