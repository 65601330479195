import {FileRuntime} from 'packages/files/models';
import {ItemTranslationStatusRuntype} from 'packages/translations/models';
import {UserRuntype} from 'packages/users/model';
import * as r from 'runtypes';

export const AuthorRuntype = r.Record({
    id: r.Number,
    guid: r.String,
    full_name: r.String,
    description: r.String,
    created_at: r.Number,
    is_deleted: r.Boolean,
    user: UserRuntype.Or(r.Null),
    image: FileRuntime.Or(r.Null),
    translations: r.Array(ItemTranslationStatusRuntype),
});
export type Author = r.Static<typeof AuthorRuntype>;

export const AuthorsRuntype = r.Array(AuthorRuntype);
export type Authors = r.Static<typeof AuthorsRuntype>;

export type SaveFields = {
    guid: string;
    image_id: number | null;
    full_name: string;
    description: string;
};
