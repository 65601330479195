import {useGrid, Response} from 'packages/grid/grid';

export const useSimplePage = (): Response<'header' | 'content' | 'footer'> =>
    useGrid([
        ['', '1fr'],
        ['50px', 'header'],
        ['1fr', 'content'],
        ['60px', 'footer'],
    ]);

export const useSidebarPage = (): Response<'header' | 'content' | 'sidebar' | 'footer'> =>
    useGrid(
        [
            ['', '150px', '1fr'],
            ['50px', 'header', 'header'],
            ['1fr', 'sidebar', 'content'],
            ['60px', 'footer', 'footer'],
        ],
        {
            600: [
                ['', '1fr'],
                ['50px', 'header'],
                ['50px', 'sidebar'],
                ['1fr', 'content'],
                ['60px', 'footer'],
            ],
        }
    );

export const ManagerSizes = {
    full: 1500,
    header: 54,
    infoblock: 134,
    headerSidePadding: 50,
    rootPadding: 30,
    navigationWidth: 250,
    navigationHiddenWidth: 20,
    navigationHeigh: 26,
    navigationTouroperatorDropdownHeight: 64,
    navigationMarginBottom: 20,
    contentPaddingTopBottom: 40,
    contentPaddingLeftRight: 50,
    contentNavMarginBottom: 20,
    contentSidebarsMargin: 25,
    contentMarginTop: 20,
    contentBorderRadius: 5,
    rightBarWidth: 150,
};

export const Sizes = {
    header: 54,
};
