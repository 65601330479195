import {ThemeColorsRuntype} from 'generated/ThemeColors';
import {CurrencyDataRuntype, EmptyCurrencyData} from 'packages/currency/model';
import {FileRuntime} from 'packages/files/models';
import {RuntypeFromEnum} from 'packages/helpers/RuntypeFromEnum';
import {PayoutFeesRuntype} from 'packages/payouts/model';
import {ProductWithAccessRuntype} from 'packages/products/models';
import {ResourceWithAccessRuntype} from 'packages/resources/models';
import {SubscriptionRuntype, SubscriptionStatus, SubscriptionStatusRuntype} from 'packages/subscriptions/model';
import {UserRuntype} from 'packages/users/model';
import * as r from 'runtypes';

export const ErrEmptyCountry = 14;

export type CreateProductsLibraryData = {
    title: string;
};

export type CreateResourcesLibraryData = {
    title: string;
};

export type AddTourOperatorUserData = {
    email: string;
    full_name: string;
    access_level: number;
};

export type ChangeTourOperatorUserData = {
    access_level: number;
};

export enum TourOperatorStatusID {
    Trial = 1,
    TrialEnded,
    ReadyForApproval,
    Active,
    Deactivated,
}
export const TourOperatorStatusIDRuntype = RuntypeFromEnum(TourOperatorStatusID) as r.Runtype<TourOperatorStatusID>;

export enum StripeConnectStatus {
    NotConnected = 0,
    Enabled,
    Disabled,
    Pending,
    InfoRequired,
}

export const StripeConnectStatusRuntype = RuntypeFromEnum(StripeConnectStatus) as r.Runtype<StripeConnectStatus>;

export const TourOperatorRuntype = r.Record({
    id: r.Number,
    title: r.String,
    created_on: r.Number,
    stripe_connect_status_id: StripeConnectStatusRuntype,
    image: FileRuntime.Or(r.Null),
    status_id: TourOperatorStatusIDRuntype,
    is_suspended: r.Boolean,
    has_active_subscription: r.Boolean,
    public_contact_phone: r.String.Or(r.Null).optional(),
    public_contact_email: r.String.Or(r.Null).optional(),
    current_subscription_status: SubscriptionStatusRuntype,
    currency: CurrencyDataRuntype,
});
export type TourOperator = r.Static<typeof TourOperatorRuntype>;

export const TourOperatorPublicRuntype = r.Record({
    created_on: r.Number,
    currency: CurrencyDataRuntype,
    instagram_url: r.String.Or(r.Null),
    facebook_url: r.String.Or(r.Null),
    youtube_url: r.String.Or(r.Null),
    id: r.Number,
    title: r.String,
    image: FileRuntime.Or(r.Null),
    public_contact_phone: r.String.Or(r.Null).optional(),
    public_contact_email: r.String.Or(r.Null).optional(),
    whatsapp_phone: r.String.Or(r.Null).optional(),
    website_url: r.String.Or(r.Null).optional(),
    legal_notice: FileRuntime.Or(r.Null),
    privacy_policy: FileRuntime.Or(r.Null),
    terms_and_conditions: FileRuntime.Or(r.Null),
    license_or_insurance: FileRuntime.Or(r.Null),
    liability_insurance: FileRuntime.Or(r.Null),
});
export type TourOperatorPublic = r.Static<typeof TourOperatorPublicRuntype>;

export const WhiteLabelSettingsRuntype = r.Record({
    marker: r.String,
    colors: ThemeColorsRuntype,
});
export type WhiteLabelSettings = r.Static<typeof WhiteLabelSettingsRuntype>;

export const LibraryRuntype = r.Record({
    id: r.Number,
    title: r.String,
    created_on: r.Number,
    total_items: r.Number,
});
export type Library = r.Static<typeof LibraryRuntype>;
export const EmptyLibrary: Library = {
    id: 0,
    title: '',
    created_on: 0,
    total_items: 0,
};

export const LibraryPoliciesRuntype = r.Record({
    can_manage_users: r.Boolean,
    can_manage_relations: r.Boolean,
});
export type LibraryPolicies = r.Static<typeof LibraryPoliciesRuntype>;

export const LibraryItemRuntype = r.Record({
    library: LibraryRuntype,
    policies: LibraryPoliciesRuntype,
});

export const TouroperatorAccessesRuntype = r.Record({
    can_delete: r.Boolean,
    can_edit: r.Boolean,
    can_manage_authors: r.Boolean,
    can_manage_bookings: r.Boolean,
    can_manage_billing: r.Boolean,
    can_manage_coupons: r.Boolean,
    can_manage_products_libraries: r.Boolean,
    can_manage_resources_libraries: r.Boolean,
    can_manage_users: r.Boolean,
    can_manage_widgets: r.Boolean,
    can_manage_apps: r.Boolean,
    can_manage_payouts: r.Boolean,
    is_member: r.Boolean,
});
export type TouroperarAccess = r.Static<typeof TouroperatorAccessesRuntype>;

export const SessionTourOperatorItemRuntype = r.Record({
    tour_operator: TourOperatorRuntype,
    total_resources: r.Number,
    policies: TouroperatorAccessesRuntype,
});
export type SessionTourOperatorItem = r.Static<typeof SessionTourOperatorItemRuntype>;

export const TourOperatorDetailsRuntime = TourOperatorRuntype.extend({
    uri: r.String,
    legal_name: r.String,
    tax_id: r.String,
    public_contact_name: r.String.Or(r.Null),
    public_contact_phone: r.String.Or(r.Null),
    public_contact_email: r.String.Or(r.Null),
    dguide_contact_name: r.String.Or(r.Null),
    dguide_contact_email: r.String.Or(r.Null),
    dguide_contact_phone: r.String.Or(r.Null),
    is_representative_same_as_dguide: r.Boolean,
    representative_name: r.String.Or(r.Null),
    representative_email: r.String.Or(r.Null),
    representative_phone: r.String.Or(r.Null),
    address_zip: r.String.Or(r.Null),
    address_city: r.String.Or(r.Null),
    address_country: r.String.Or(r.Null),
    address_country_alpha2: r.String.Or(r.Null),
    address_street: r.String.Or(r.Null),
    legal_notice: FileRuntime.Or(r.Null),
    privacy_policy: FileRuntime.Or(r.Null),
    terms_and_conditions: FileRuntime.Or(r.Null),
    license_or_insurance: FileRuntime.Or(r.Null),
    company_registration: FileRuntime.Or(r.Null),
    liability_insurance: FileRuntime.Or(r.Null),
    website_url: r.String.Or(r.Null),
    whatsapp_phone: r.String.Or(r.Null),
    instagram_url: r.String.Or(r.Null),
    facebook_url: r.String.Or(r.Null),
    youtube_url: r.String.Or(r.Null),
    has_agreed_to_terms: r.Boolean,
    has_registered_with_trial_country: r.Boolean,
    fees: PayoutFeesRuntype,
});
export type TourOperatorDetails = r.Static<typeof TourOperatorDetailsRuntime>;

export const ConsoleTourOperatorRuntype = TourOperatorRuntype.extend({
    owner: UserRuntype,
    metrics: r.Record({
        total_products: r.Number,
        total_resources: r.Number,
        total_bookings: r.Number,
    }),
    white_label_settings: WhiteLabelSettingsRuntype,
    has_subscription_and_payouts: r.Boolean,
    dguide_contact_name: r.String.Or(r.Null),
    dguide_contact_email: r.String.Or(r.Null),
    dguide_contact_phone: r.String.Or(r.Null),
});
export type ConsoleTourOperator = r.Static<typeof ConsoleTourOperatorRuntype>;

export const ConsoleTourOperatorDetailsRuntype = TourOperatorDetailsRuntime.extend({
    owner: UserRuntype,
    metrics: r.Record({
        total_products: r.Number,
        total_resources: r.Number,
        total_bookings: r.Number,
    }),
    white_label_settings: WhiteLabelSettingsRuntype,
    subscriptions: r.Array(SubscriptionRuntype),
});
export type ConsoleTourOperatorDetails = r.Static<typeof ConsoleTourOperatorDetailsRuntype>;

export const TourOperatorMemberRuntype = r.Record({
    id: r.Number,
    name: r.String,
    email: r.String.Or(r.Null),
    phone: r.String.Or(r.Null),
    is_verified: r.Boolean,
    access_level: r.Number,
    is_tester: r.Boolean.optional(),
});
export type TourOperatorMember = r.Static<typeof TourOperatorMemberRuntype>;

export const LibraryWithAccessRuntype = r.Record({
    id: r.Number,
    title: r.String,
    access_level: r.Number,
});
export type LibraryWithAccess = r.Static<typeof LibraryWithAccessRuntype>;

export const MemberAccessesRuntype = r.Record({
    global_access: r.Number,
    products_libraries: r.Array(LibraryWithAccessRuntype),
    resources_libraries: r.Array(LibraryWithAccessRuntype),
    products: r.Array(ProductWithAccessRuntype),
    resources: r.Array(ResourceWithAccessRuntype),
});
export type MemberAccesses = r.Static<typeof MemberAccessesRuntype>;

export const AccessItemRuntype = r.Record({
    id: r.Number,
    title: r.String,
});
export type AccessItem = r.Static<typeof AccessItemRuntype>;

export const EmptyTourOperator: TourOperator = {
    id: 0,
    title: '',
    created_on: 0,
    stripe_connect_status_id: 0,
    image: null,
    status_id: TourOperatorStatusID.Trial,
    is_suspended: false,
    has_active_subscription: false,
    currency: EmptyCurrencyData,
    current_subscription_status: SubscriptionStatus.NoSubscription,
};

export const EmptyTourOperatorDetails: TourOperatorDetails = {
    ...EmptyTourOperator,
    uri: '',
    legal_name: '',
    tax_id: '',
    address_country: '',
    address_country_alpha2: '',
    address_city: '',
    address_street: '',
    address_zip: '',
    public_contact_name: '',
    public_contact_phone: null,
    public_contact_email: null,
    dguide_contact_name: '',
    dguide_contact_email: '',
    dguide_contact_phone: '',
    is_representative_same_as_dguide: true,
    representative_name: '',
    representative_email: '',
    representative_phone: '',
    legal_notice: null,
    privacy_policy: null,
    terms_and_conditions: null,
    license_or_insurance: null,
    company_registration: null,
    liability_insurance: null,
    instagram_url: null,
    facebook_url: null,
    youtube_url: null,
    website_url: null,
    whatsapp_phone: null,
    has_agreed_to_terms: false,
    has_registered_with_trial_country: false,
    fees: {
        ticket_fee: 0,
        online_fixed_fee: 0,
        online_percent_fee: 0,
        subscription_price: 0,
        late_online_fee: false,
    },
    currency: EmptyCurrencyData,
};

export const EmptySessionTourOperator: SessionTourOperatorItem = {
    tour_operator: {...EmptyTourOperator},
    total_resources: 0,
    policies: {
        can_delete: false,
        can_edit: false,
        can_manage_authors: false,
        can_manage_bookings: false,
        can_manage_billing: false,
        can_manage_coupons: false,
        can_manage_products_libraries: false,
        can_manage_resources_libraries: false,
        can_manage_users: false,
        can_manage_widgets: false,
        can_manage_apps: false,
        can_manage_payouts: false,
        is_member: false,
    },
};

export const TinyTourOperatorRuntype = r.Record({
    id: r.Number,
    title: r.String,
    uri: r.String,
});

export type TinyTourOperator = r.Static<typeof TinyTourOperatorRuntype>;
