import {CreateSheet, css, CSSProperties} from 'packages/aphrodite/aphrodite';
import {toast, ToastContent} from 'react-toastify';

type callback = () => void;

const OnOpen = (withSound: boolean, callback?: callback) => () => {
    if (typeof callback === 'function') {
        callback();
    }

    if (withSound) {
        const audio = new Audio('/sounds/toast.mp3');
        audio.play();
    }
};

const OnClose = (callback?: callback) => () => {
    if (typeof callback === 'function') {
        callback();
    }
};

export type options = {
    sound?: boolean;
    timeout?: number | false;
    onOpen?: () => void;
    onClose?: () => void;
};

export type ToastTheme = {
    backgroundColor: CSSProperties['backgroundColor'];
    prgoressColor: CSSProperties['backgroundColor'];
    crossColor: CSSProperties['backgroundColor'];
    color: CSSProperties['color'];
};

export type ToastLayout = {
    borderRadius: CSSProperties['borderRadius'];
    boxShadow: CSSProperties['boxShadow'];
    padding: CSSProperties['padding'];
    fontSize: CSSProperties['fontSize'];
    fontWeight: CSSProperties['fontWeight'];
};

export type {ToastContent};

type InternalTheme = {
    styles: ToastTheme;
    layout: ToastLayout;
};

export function ShowToast(
    Content: ToastContent,
    theme: ToastTheme,
    layout: ToastLayout,
    options?: options
): string | number {
    options = options || {};
    options.onClose = OnClose(options.onClose);
    options.onOpen = OnOpen(options.sound || false, options.onClose);

    const styles = CreateStyles({styles: theme, layout});

    return toast(Content, {
        ...options,
        autoClose: typeof options.timeout !== 'undefined' ? options.timeout : 3000,
        position: 'top-right',
        className: css(styles.toast),
        bodyClassName: css(styles.body),
        progressClassName: css(styles.progress),
    });
}

const CreateStyles = (theme?: InternalTheme) =>
    CreateSheet({
        toast: {
            borderRadius: theme?.layout.borderRadius || 0,
            padding: theme?.layout.padding,
            backgroundColor: theme?.styles.backgroundColor,
            color: theme?.styles.color,
            minHeight: 'auto',
            alignItems: 'center',
            ':nth-child(1n) .Toastify__close-button': {
                display: 'flex',
                color: theme?.styles.crossColor,
                opacity: 1,
            },
        },
        body: {
            padding: 0,
            margin: 0,
            fontSize: theme?.layout.fontSize,
            fontWeight: theme?.layout.fontWeight,
        },
        progress: {
            background: theme?.styles.prgoressColor,
        },
    });
