import i18next from 'i18next';
import {css, StyleDeclaration} from 'aphrodite';
// import QrBtn from './img/qr.png';

interface Styles {
    parent?: StyleDeclaration;
    link?: StyleDeclaration;
}

interface Props {
    styles?: Styles;
    isPartner?: boolean;
}

export default function AppsLinks({styles, isPartner}: Props) {
    let lng: 'en' | 'de' | 'it' | 'nl' | 'ru' | 'fr' | 'es' = 'en';
    switch (i18next.language) {
        case 'de':
            lng = 'de';
            break;
        case 'it':
            lng = 'it';
            break;
        case 'nl':
            lng = 'nl';
            break;
        case 'ru':
            lng = 'ru';
            break;
        case 'fr':
            lng = 'fr';
            break;
        case 'es':
            lng = 'es';
            break;
        default:
            lng = 'en';
            break;
    }

    const appleLink = isPartner
        ? 'https://apps.apple.com/ru/app/dgtalguide-partners/id1631747113'
        : 'https://apps.apple.com/us/app/dgtalguide/id1616832157';
    const googleLink = isPartner
        ? 'https://play.google.com/store/apps/details?id=com.dgtalguide.partners'
        : 'https://play.google.com/store/apps/details?id=com.eighthbyte.dgtalguide';

    return (
        <ul className={css(styles && styles.parent && styles.parent)}>
            <li>
                <a
                    {...{
                        href: appleLink,
                        className: css(styles && styles.link && styles.link),
                        target: '_blank',
                        rel: 'noreferrer',
                        'aria-label': 'DGtalguide iOS',
                    }}
                >
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/app/apple-${lng}.png`}
                        width="120"
                        height="40"
                        alt=""
                    />
                </a>
            </li>
            <li>
                <a
                    {...{
                        href: googleLink,
                        className: css(styles && styles.link && styles.link),
                        target: '_blank',
                        rel: 'noreferrer',
                        'aria-label': 'DGtalguide Android',
                    }}
                >
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/app/google-${lng}.png`}
                        height="40"
                        width="135"
                        alt=""
                    />
                </a>
            </li>
            {/* <li>
                <a
                    href="#"
                    className={css((styles && styles.link) && styles.link))}
                >
                    <img
                        src={QrBtn}
                        height="40"
                        alt=""
                    />
                </a>
            </li> */}
        </ul>
    );
}
