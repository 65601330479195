import {MakeAnimation} from 'packages/aphrodite/aphrodite';

const swipeHorizontalRight = MakeAnimation(
    {
        '0%': {
            transform: 'translateX(100%)',
        },
        '100%': {
            transform: 'translateX(0)',
        },
    },
    {
        '0%': {
            transform: 'translateX(0)',
        },
        '100%': {
            transform: 'translateX(-100%)',
        },
    }
);

export default swipeHorizontalRight;
