import {CreateSheet, GetColor, css} from 'packages/aphrodite/aphrodite';
import {session} from 'packages/session/session';
import {MakeButton, WhiteTransparent} from 'src/themes/button';
import {PrimaryManager} from 'src/themes/colors';

export default function LoggedInAsUser() {
    if (session.extra.su_login && session.extra.su_login.guid) {
        return (
            <div className={css(Styles.logged_in_as)}>
                logged in as {session.user.full_name}{' '}
                <button
                    {...{
                        className: css(
                            MakeButton(WhiteTransparent, {padding: '0 0 0 20px', fontSize: 12, border: 'none'})
                        ),
                        onClick: () => {
                            window.location.href = `/console/users/${session.extra.su_login!.guid}/signout`;
                        },
                    }}
                >
                    <>switch back to {session.extra.su_login.full_name}</>
                </button>
            </div>
        );
    }

    return null;
}

const Styles = CreateSheet({
    logged_in_as: {
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: GetColor('accent'),
        zIndex: 10000,
        color: 'white',
        fontSize: 12,
        padding: '5px 10px',
        borderBottomRightRadius: 6,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 500,
        lineHeight: '12px',
        borderRight: `1px solid ${PrimaryManager.default}`,
        borderBottom: `1px solid ${PrimaryManager.default}`,
    },
});
