import {history} from 'packages/history/history';
import {useCallback, useState} from 'react';
import useOnce from './useOnce';

const getQuery = () => {
    if (typeof window !== 'undefined') {
        return new URLSearchParams(window.location.search);
    }

    return new URLSearchParams();
};

const getQueryStringVal = (key: string): string | null => {
    return getQuery().get(key);
};

type UpdateType = 'push' | 'replace';

const useQueryParam = (key: string): [string, (val: string, type: UpdateType) => void] => {
    const [query, setQuery] = useState(getQueryStringVal(key) || '');

    useOnce(() => {
        const checkParams = () => setQuery(getQueryStringVal(key) || '');
        window.addEventListener('popstate', checkParams);
        return () => {
            window.removeEventListener('popstate', checkParams);
        };
    });

    const updateUrl = useCallback(
        (newVal: string, updateType: UpdateType = 'push') => {
            setQuery(newVal);

            const query = getQuery();

            if (newVal.trim() !== '') {
                query.set(key, newVal);
            } else {
                query.delete(key);
            }

            if (typeof window !== 'undefined') {
                const {pathname} = window.location;
                const newUrl = `${pathname}?${query.toString()}`;
                if (updateType === 'push') {
                    history.push(newUrl);
                } else {
                    history.replace(newUrl);
                }
            }
        },
        [key]
    );

    return [query, updateUrl];
};

export default useQueryParam;
