import * as r from 'runtypes';

export const CurrencyDataRuntype = r.Record({
    id: r.Number,
    name: r.String,
    symbol: r.String,
    code: r.String,
});

export type CurrencyData = r.Static<typeof CurrencyDataRuntype>;

export const EmptyCurrencyData: CurrencyData = {
    id: 0,
    name: '',
    symbol: '',
    code: '',
};
