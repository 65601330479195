import PageHeader from '../headers/PageHeader.react';
import {CreateSheet, css} from 'aphrodite';

export default function Page404() {
    return (
        <article className={css(Styles.page)}>
            <PageHeader active="home" />
            <div className={css(Styles.main)}>Page not found</div>
        </article>
    );
}

const Styles = CreateSheet({
    page: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    main: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 28,
        fontWeight: 500,
    },
});
