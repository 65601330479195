import {MakeAnimation} from 'packages/aphrodite/aphrodite';

const swipeDown = MakeAnimation(
    {},
    {
        '0%': {
            transform: 'translateY(0)',
        },
        '100%': {
            transform: 'translateY(100%)',
        },
    }
);

export default swipeDown;
