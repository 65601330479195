import {useLocalization} from 'packages/localization/localization';
import {Dark, Primary} from 'src/themes/colors';
import {CreateSheet, GetColor, css} from 'packages/aphrodite/aphrodite';
import {ReactComponent as MagicLinkIcon} from 'src/icons/magic-button.svg';
import React from 'react';

type MagicLinkButtonProps = {
    onClick?: (event: React.MouseEvent) => void;
};

const MagicLinkButton: React.FC<MagicLinkButtonProps> = ({onClick}) => {
    const {t, tReady} = useLocalization('login');

    if (!tReady) return null;

    return (
        <button
            {...{
                type: 'button',
                className: css(Styles.magicButton),
                onClick,
            }}
        >
            <MagicLinkIcon className={css(Styles.icon)} />
            <span>{t('magic.button')}</span>
        </button>
    );
};

export default MagicLinkButton;

const Styles = CreateSheet({
    magicButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '8px 30px',
        borderRadius: 13,
        boxShadow: '0px 5px 15px rgba(48, 48, 48, 0.1)',
        border: `2px solid ${GetColor('gray3')}`,
        cursor: 'pointer',
        background: '#fff',

        color: Dark.default,
        fontWeight: 600,
        lineHeight: '1.429rem',
        fontSize: '1.143rem',
    },
    icon: {
        display: 'block',
        marginRight: 12,
        color: Primary.default,
    },
});
