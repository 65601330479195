import moment from 'moment';
import {AuthorRuntype} from 'packages/authors/models';
import {AvailabilitySettingsRuntype, EmptyAvailability} from 'packages/availability/models';
import {CurrencyDataRuntype, EmptyCurrencyData} from 'packages/currency/model';
import {FileRuntime} from 'packages/files/models';
import {FilterItemRuntype} from 'packages/filter/models';
import {MediaItemRuntype} from 'packages/media/model';
import {RoutesSettingsRuntype} from 'packages/routes/model';
import {ItemTranslationStatusRuntype} from 'packages/translations/models';
import * as r from 'runtypes';

export enum UnactiveReasonID {
    None = 0,
    Default = 1,
    Expired = 2,
}

export const StartTimeSettingsRuntype = r.Record({
    id: r.Number,
    product_id: r.Number,
    start_time: r.String,
    routes_setting: RoutesSettingsRuntype,
});
export type StartTimeSettings = r.Static<typeof StartTimeSettingsRuntype>;

export const StartTimesSettingsRuntype = r.Array(StartTimeSettingsRuntype);
export type StartTimesSettings = r.Static<typeof StartTimesSettingsRuntype>;

export const ProductRuntype = r.Record({
    id: r.Number,
    uri: r.String,
    created_at: r.Number,
    title: r.String,
    price: r.Number,
    is_active: r.Boolean,
    unactive_reason_id: r.Number,
    is_liked: r.Boolean,
    short_description: r.String,
    average_rating: r.Number,
    highlights: r.Array(r.String),
    requirements: r.String,
    brings: r.String,
    included: r.String,
    additional_information: r.String,
    not_included: r.String,
    important_info: r.String,
    description: r.String,
    location_name: r.String,
    location_info: r.String,
    keywords: r.Array(r.String),
    duration: r.Number,
    distance: r.Number,
    media: r.Array(MediaItemRuntype),
    videos: r.Array(r.String),
    translations: r.Array(ItemTranslationStatusRuntype),
    filter_settings: r.Dictionary(r.Array(FilterItemRuntype), r.String),
    availability: AvailabilitySettingsRuntype.Or(r.Null),
    author: AuthorRuntype.Or(r.Null),
    map_image: FileRuntime.Or(r.Null),
    start_times: r.Array(r.String),
    currency: CurrencyDataRuntype,
    tour_operator_name: r.String,
    tour_operator_uri: r.String,
    is_demo: r.Boolean,
    copying_status: r.Number.Or(r.Null), // null - not copying, 1 - in progress, 2 - finished
});
export type Product = r.Static<typeof ProductRuntype>;

export const ProductsRuntype = r.Array(ProductRuntype);
export type Products = r.Static<typeof ProductsRuntype>;

export const EmptyProduct: Product = {
    id: 0,
    uri: '',
    created_at: moment().unix(),
    title: '',
    price: 0,
    is_active: true,
    unactive_reason_id: UnactiveReasonID.None,
    is_liked: false,
    average_rating: 0,
    short_description: '',
    highlights: [],
    requirements: '',
    brings: '',
    included: '',
    additional_information: '',
    not_included: '',
    important_info: '',
    description: '',
    availability: {...EmptyAvailability()},
    location_name: '',
    location_info: '',
    media: [],
    videos: [],
    translations: [],
    filter_settings: {},
    keywords: [],
    author: null,
    duration: 0,
    distance: 0,
    map_image: null,
    start_times: [],
    currency: EmptyCurrencyData,
    tour_operator_name: '',
    tour_operator_uri: '',
    is_demo: false,
    copying_status: null,
};

export const ProductWithAccessRuntype = r.Record({
    id: r.Number,
    title: r.String,
    access_level: r.Number,
});
export type ProductWithAccess = r.Static<typeof ProductWithAccessRuntype>;

export const ProductInCluster = r.Record({
    id: r.Number,
    uri: r.String,
    main_photo: MediaItemRuntype.Or(r.Null),
    title: r.String,
});

export const ClusterRuntype = r.Record({
    lat: r.Number,
    lng: r.Number,
    number: r.Number,
    products: r.Array(ProductInCluster).Or(r.Null),
    included: r.Array(
        r.Record({
            lat: r.Number,
            lng: r.Number,
        })
    ),
});
export type Cluster = r.Static<typeof ClusterRuntype>;

export const ClustersRuntype = r.Array(ClusterRuntype);
export type Clusters = r.Static<typeof ClustersRuntype>;

export const ProductDetailsRuntype = r.Record({
    id: r.Number,
    title: r.String,
    uri: r.String,
    filter_settings: r.Dictionary(r.Array(FilterItemRuntype), r.String),
    availability: AvailabilitySettingsRuntype.Or(r.Null),
    duration: r.Number,
    distance: r.Number,
    author: AuthorRuntype.Or(r.Null),
    translations: r.Array(ItemTranslationStatusRuntype),
    requirements: r.String,
    brings: r.String,
    is_active: r.Boolean,
    unactive_reason_id: r.Number,
    included: r.String,
    not_included: r.String,
    important_info: r.String,
    description: r.String,
    short_description: r.String,
    price: r.Number,
    map_image: FileRuntime.Or(r.Null),
    media: r.Array(MediaItemRuntype),
    videos: r.Array(r.String),
    highlights: r.Array(r.String),
    currency: CurrencyDataRuntype,
});
export type ProductDetails = r.Static<typeof ProductDetailsRuntype>;

export const ConsoleProductRuntype = r.Record({
    id: r.Number,
    uri: r.String,
    is_active: r.Boolean,
    title: r.String,
    tour_operator_title: r.String,
    changed_at: r.Number,
    created_at: r.Number,
});
export type ConsoleProduct = r.Static<typeof ConsoleProductRuntype>;
