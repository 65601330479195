import * as runtypes from 'runtypes';

export const FileSourceRuntime = runtypes.Record({
    id: runtypes.Number,
    url: runtypes.String,
    type: runtypes.String,
});

export const FileRuntime = runtypes.Record({
    id: runtypes.Number,
    title: runtypes.String,
    sources: runtypes.Dictionary(FileSourceRuntime, runtypes.String),
    thumbnails: runtypes.Dictionary(FileSourceRuntime, runtypes.String),
});

export type FileSource = runtypes.Static<typeof FileSourceRuntime>;
export type File = runtypes.Static<typeof FileRuntime>;

export const EmptyFile: File = {
    id: 0,
    title: '',
    sources: {},
    thumbnails: {},
};

export function GetFileImage(file: File): FileSource | undefined {
    return file.sources.jpeg ? file.sources.jpeg : file.sources.png;
}
