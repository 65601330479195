import {css, CreateSheet, StyleDeclarationValue, GetColor} from 'aphrodite';
import {useState} from 'react';
import {ReactComponent as Eye} from 'src/icons/eye.svg';
import {ReactComponent as EyeInactive} from 'src/icons/eye_inactive.svg';
import Tooltip from 'packages/tooltip/Tootip.react';
import {DarkTooltip} from 'src/themes/tooltip';
import {passwordStrength} from 'check-password-strength';

export default function PasswordInput({
    inputProps,
    className,
    showStrength,
}: {
    className?: StyleDeclarationValue;
    inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    showStrength?: boolean;
}) {
    const [Show, SetShow] = useState(false);
    const [Password, SetPassword] = useState<string>('');

    function getWidth() {
        return Password.length === 0 ? 0 : (passwordStrength(Password).id + 1) * 25;
    }

    function getBackground() {
        const colors = {
            0: '#e54242',
            1: '#f99f4a',
            2: '#1393ff',
            3: '#37c565',
        };
        const index = passwordStrength(Password).id as keyof typeof colors;
        return Password.length === 0 ? 'transparent' : colors[index];
    }

    return (
        <div className={css(className, Styles.main)}>
            <div className={css(Styles.inner)}>
                <input
                    {...{
                        ...inputProps,
                        className: `${inputProps?.className || ''} ${css(Styles.input)}`,
                        type: Show ? 'text' : 'password',
                        onChange: e => {
                            e.persist();
                            SetPassword(e.target.value);
                            if (inputProps?.onChange) {
                                inputProps.onChange(e);
                            }
                        },
                    }}
                />
                <Tooltip
                    {...{
                        content: Show ? 'Hide password' : 'Show password',
                        theme: DarkTooltip,
                    }}
                >
                    <div
                        className={css(Styles.icons)}
                        onClick={() => {
                            SetShow(e => !e);
                        }}
                        // TODO change SmoothAppear to handle position absoulute
                        style={{position: 'absolute'}}
                    >
                        {!Show ? (
                            <Eye
                                width="24"
                                height="24"
                            />
                        ) : (
                            <EyeInactive
                                width="24"
                                height="24"
                            />
                        )}
                    </div>
                </Tooltip>
            </div>
            {!!showStrength && (
                <div className={css(Styles.strength)}>
                    <div
                        {...{
                            className: css(Styles.strengthBar),
                            style: {
                                width: `${getWidth()}%`,
                                background: getBackground(),
                            },
                        }}
                    />
                    {getWidth() > 0 && <span>Password strength</span>}
                </div>
            )}
        </div>
    );
}

const Styles = CreateSheet({
    main: {},
    inner: {
        position: 'relative',
    },
    icons: {
        position: 'absolute',
        right: 10,
        top: '50%',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        ':nth-child(1n) svg': {
            display: 'block',
            fill: 'rgba(48, 48, 48, 0.3)',
        },
    },
    input: {
        paddingRight: 35,
    },
    strength: {
        marginTop: 10,
        height: 20,
        ':nth-child(1n) span': {
            display: 'block',
            fontSize: 10,
            marginTop: 2,
            fontWeight: 'bold',
            color: GetColor('supplementary'),
        },
    },
    strengthBar: {
        height: 5,
        borderRadius: 5,
        transition: 'all 0.2s ease-in-out',
    },
});
