import {SubscriptionCallback} from 'packages/pubsub/server';
import {session} from 'packages/session/session';
import {Messages} from './personal.list';
import {ResultWithError} from 'packages/errors/errors';
import {Connection} from './server';

export function bindOnPersonal<T extends keyof Messages>(
    marker: T,
    callback: SubscriptionCallback<Parameters<Messages[T]>[0], ReturnType<Messages[T]>>
) {
    Connection.CreateSubscription(`personal-${session.user.guid}`, marker, callback);
}

export function unbindOnPersonal<T extends keyof Messages>(
    marker: T,
    callback: SubscriptionCallback<Parameters<Messages[T]>[0], ReturnType<Messages[T]>>
) {
    Connection.RemoveSubscription(`personal-${session.user.guid}`, marker, callback);
}

export async function SendPersonal<T extends keyof Messages>(
    user_guid: string,
    marker: T,
    message: Parameters<Messages[T]>[0]
): Promise<ResultWithError<ReturnType<Messages[T]>>> {
    return await Connection.Request<Parameters<Messages[T]>[0], ReturnType<Messages[T]>>(
        `personal-${user_guid}`,
        marker,
        message
    );
}
