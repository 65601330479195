import {EmptyFile, FileRuntime} from 'packages/files/models';

import * as r from 'runtypes';

export const MediaItemRuntype = r.Record({
    file: FileRuntime,
    type: r.Union(r.Literal('photo'), r.Literal('video'), r.Literal('file'), r.Literal('audio')),
    label: r.String,
    download_on_demand: r.Boolean,
    cross_language_file: r.Boolean,
    cross_language_label: r.Boolean,
});

export type MediaItem = r.Static<typeof MediaItemRuntype>;

export const EmptyMediaItem: MediaItem = {
    file: EmptyFile,
    type: 'file',
    label: '',
    download_on_demand: false,
    cross_language_file: false,
    cross_language_label: false,
};
