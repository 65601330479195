import {useEffect} from 'react';
import {Executer} from './model';
import {useDetached} from './useDetached';

export type Options<Payload> = {
    initialState?: Payload;
    listenArgs?: boolean;
    ignoreInitalFetch?: boolean;
};

export function useDetachedEffect(effect: Executer<void | (() => void), never>, args?: ReadonlyArray<unknown>) {
    function executer(...args: ReadonlyArray<unknown>) {
        return effect();
    }

    const {state} = useDetached(executer, {executerName: effect.name}, args);

    useEffect(() => {
        return state.payload;
    }, [state.payload]);
}
