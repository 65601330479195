import * as r from 'runtypes';

export const SuperUserFlagsRuntype = r.Partial({
    has_users_access: r.Boolean,
    can_login_as_user: r.Boolean,
    has_admins_access: r.Boolean,
    can_manage_admins: r.Boolean,
    can_manage_filter: r.Boolean,
    can_manage_featured: r.Boolean,
    has_reports_access: r.Boolean,
    has_touroperators_access: r.Boolean,
    has_releasenotes_access: r.Boolean,
    has_payouts_access: r.Boolean,
    has_products_access: r.Boolean,
    has_invoices_access: r.Boolean,
    has_promo_codes_access: r.Boolean,
    has_sms_access: r.Boolean,
});
export type SuperUserFlags = r.Static<typeof SuperUserFlagsRuntype>;
