import {CreateSheetWithTheme, css, CSSProperties, GetColor, useTheme} from 'aphrodite';
import {ButtonTheme} from 'packages/buttons/button.theme';
import {InputTheme} from 'packages/forms/Input';
import PasswordInput from 'packages/forms/PasswordInput.react';
import PlusSignVerifier from 'packages/helpers/PlusSignVerifier';
import {history, OpenPage} from 'packages/history/history';
import useSimpleError from 'packages/hooks/useSimpleError';
import {useLocalization} from 'packages/localization/localization';
import SmoothLine from 'packages/motion/SmoothLine.react';
import {session, SignIn} from 'packages/session/session';
import React, {useState} from 'react';
import Trans from 'packages/localization/Trans';
import {LoadButton} from 'src/themes/button';
import {Dark, GrayLight, Primary} from 'src/themes/colors';
import {FormStyles} from 'src/themes/form';
import {MakeInput} from 'src/themes/input';
import MagicLinkButton from '../components/MagicLinkButton.react';
import SocialBtns from './SocialBtns.react';

export default function SignInReact({
    theme,
    identifier,
    origin,
    OnSignIn,
}: {
    theme: SignInTheme;
    identifier?: string;
    OnSignIn?: () => void;
    origin?: string;
}) {
    const {t, tReady} = useLocalization('login');
    const Styles = useTheme(theme, StylesWithTheme);
    const [loading, setLoading] = useState(false);
    const [ident, setIdent] = useState(PlusSignVerifier(identifier || ''));
    const [password, setPassword] = useState('');
    const [hasErr, setErr] = useSimpleError<'general', string>();

    async function onSubmit(e: React.FormEvent) {
        e.preventDefault();

        if (ident.trim().length === 0) {
            setErr('general', t('errors.identReq'));
            return;
        }

        setLoading(true);
        const err = await SignIn({
            identifier: ident,
            password: password,
        });
        setLoading(false);

        if (err !== null) {
            if (err.code === 501) {
                history.push(`/verification?id=${identifier}`);
                return;
            }

            setErr('general', err.text);
            return;
        }

        if (typeof OnSignIn === 'function') {
            OnSignIn();
        } else {
            if (!origin && session.extra.tour_operators.length > 0) {
                history.push('/manager');
                return;
            }

            history.push(origin || '/');
        }
    }

    const onMagicLinkButtonClick = (e: React.MouseEvent) => {
        OpenPage('magic')(e);
    };

    if (!tReady) {
        return null;
    }

    return (
        <form
            {...{
                method: 'POST',
                className: css(FormStyles.form, Styles.form),
                onSubmit: onSubmit,
            }}
        >
            <div className={css(Styles.mainPart)}>
                <input
                    {...{
                        id: 'identifier',
                        type: 'text',
                        placeholder: t('inputs.emailPhone'),
                        autoFocus: true,
                        autoComplete: 'off',
                        value: ident,
                        onChange: e => {
                            setErr('general', false);
                            setIdent(PlusSignVerifier(e.target.value.trim()));
                        },
                        disabled: Boolean(identifier && identifier.length > 0),
                        className: css(
                            MakeInput(theme.input, {
                                fontSize: 16,
                                fontWeight: 400,
                                padding: '8px 16px',
                                width: '100%',
                                borderRadius: 12,
                                lineHeight: '28px',
                            }),
                            Styles.input
                        ),
                    }}
                />
                <div className={css(Styles.password)}>
                    <div
                        {...{
                            className: css(Styles.label),
                        }}
                    >
                        <a
                            {...{
                                href: '/password_reset',
                                className: css(Styles.link),
                                onClick: OpenPage('password_reset'),
                            }}
                        >
                            {t('signIn.forgot')}
                        </a>
                    </div>
                    <PasswordInput
                        {...{
                            inputProps: {
                                id: 'password',
                                placeholder: t('inputs.password'),
                                autoComplete: 'off',
                                value: password,
                                onChange: e => {
                                    setErr('general', false);
                                    setPassword(e.target.value.trim());
                                },
                                className: css(
                                    MakeInput(theme.input, {
                                        fontSize: 16,
                                        fontWeight: 400,
                                        padding: '8px 16px',
                                        width: '100%',
                                        borderRadius: 12,
                                        lineHeight: '28px',
                                    })
                                ),
                            },
                        }}
                    />
                    <div className={css(Styles.error)}>
                        <SmoothLine motion={['fade', 'swipe-up']}>{hasErr('general')}</SmoothLine>
                    </div>
                </div>
                <LoadButton
                    {...{
                        type: 'submit',
                        loading: loading,
                        disabled: ident.trim().length === 0 || password.trim().length === 0,
                        className: css(Styles.submit),
                        theme: theme.button,
                        layout: {
                            padding: '15px 0',
                            fontSize: '1.1429rem',
                            lineHeight: 1,
                            fontWeight: 600,
                            whiteSpace: 'nowrap',
                            borderRadius: 13,
                        },
                    }}
                >
                    {t('signIn.btn')}
                </LoadButton>

                <div className={css(Styles.formFooter)}>
                    <div className={css(Styles.formFooterTitle)}>{t('magic.signInTitle')}</div>
                    <div className={css(Styles.formFooterText)}>
                        <Trans
                            t={t}
                            i18nKey="magic.signInText"
                        >
                            Get a magic link to sign in to <strong>DGtalguide</strong> instantly!{' '}
                        </Trans>
                    </div>
                    <MagicLinkButton
                        {...{
                            onClick: onMagicLinkButtonClick,
                        }}
                    />
                    <div className={css(Styles.divider)}>
                        <div className={css(Styles.line)} />
                        <div className={css(Styles.dividerText)}>or</div>
                        <div className={css(Styles.line)} />
                    </div>
                    <SocialBtns />
                </div>
            </div>
        </form>
    );
}

export type SignInTheme = {
    input: InputTheme;
    button: ButtonTheme;
    label_color: CSSProperties['color'];
    reset_color: CSSProperties['color'];
};

const StylesWithTheme = CreateSheetWithTheme((theme?: SignInTheme) => {
    return {
        form: {
            position: 'relative',
            padding: '0 20px',
            zIndex: 2,
            marginBottom: 0,
        },
        mainPart: {
            flexGrow: 1,
        },
        password: {
            marginTop: 15,
        },
        label: {
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '5px 0',
        },
        link: {
            textDecoration: 'none',
            color: theme?.reset_color,
            fontWeight: 600,
            fontSize: '1rem',
            fontHeight: '1.214rem',
            ':hover': {
                textDecoration: 'underline',
            },
        },
        error: {
            display: 'flex',
            justifyContent: 'flex-start',
            fontSize: '0.85714rem',
            color: Primary.default,
            textAlign: 'center',
            fontWeight: 600,
            height: 28,
            paddingTop: 10,
        },
        input: {
            display: 'block',
        },
        submit: {
            marginTop: 20,
            width: '100%',
        },
        formFooter: {
            marginTop: 30,
        },
        formFooterTitle: {
            textAlign: 'center',
            marginBottom: 10,
            fontWeight: 700,
            fontSize: '1.143rem',
            fontHeight: '1.429rem',
            color: Dark.default,
        },
        formFooterText: {
            textAlign: 'center',
            marginBottom: 20,
            fontWeight: 500,
            fontSize: '1rem',
            fontHeight: '1.214rem',
            color: Dark.default,
        },
        formFooterLinks: {
            textAlign: 'center',
        },
        formFooterLink: {
            fontSize: '0.75rem',
            fontWeight: 700,
            color: Dark.default,
            textDecoration: 'none',
            cursor: 'pointer',
            ':hover': {
                textDecoration: 'underline',
            },
        },
        divider: {
            margin: '20px 0',
            display: 'flex',
            alignItems: 'center',
            gap: 15,
        },
        line: {
            height: 1,
            width: '100%',
            background: GetColor('gray3'),
        },
        dividerText: {
            color: GrayLight.default,
            fontWeight: 500,
            fontSize: '1.143rem',
            lineHeight: '1.429rem',
            letterSpacing: '0.03em',
            textTransform: 'lowercase',
        },
    };
});
