import {useLocalization} from 'packages/localization/localization';
import {useEffect, useRef, useState} from 'react';
// components
import Portal from 'packages/portal/portal.react';
// img
import Image from './img.png';
// styles
import {CreateSheet, GetColor, css} from 'aphrodite';
import {Dark} from 'src/themes/colors';
import {MakeButton, PrimaryFilled} from 'src/themes/button';
import IsIFrame from 'packages/helpers/IsIFrame';

var cookieName = 'DGtalguide';
var cookieValue = 'Site';
var cookieExpireDays = 365;

// get cookie from the web browser
let getCookie = function () {
    let name = cookieName + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};
// check cookie is set or not
let checkCookie = function () {
    let check = getCookie();
    return check === '';
};

export default function Cookies() {
    const {t, tReady} = useLocalization('cookies');
    const [show, setShow] = useState<boolean>(false);
    const shouldShow = useRef(checkCookie() && !IsIFrame());

    const onAccept = () => {
        let currentDate = new Date();
        currentDate.setTime(currentDate.getTime() + cookieExpireDays * 24 * 60 * 60 * 1000);
        let expires = 'expires=' + currentDate.toUTCString();
        document.cookie = cookieName + '=' + cookieValue + ';' + expires + ';path=/;SameSite=None; Secure';
        if (!document.cookie) {
            console.log('Unable to set cookie. Please allow all cookies site from cookie setting of your browser');
        }
        shouldShow.current = false;
        setShow(false);
    };

    useEffect(() => {
        let timer = setTimeout(() => {
            if (!show && shouldShow.current) {
                setShow(true);
            }
        }, 3 * 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [show]);

    if (!tReady) {
        return null;
    }

    if (!shouldShow.current) return null;

    return (
        <Portal>
            <div className={css(Styles.box, show && Styles.showBox)}>
                <div className={css(Styles.container)}>
                    <img
                        src={Image}
                        className={css(Styles.img)}
                        height="48"
                        alt=""
                    />
                    <div className={css(Styles.main)}>
                        <h3 className={css(Styles.title)}>{t('title')}</h3>
                        <p className={css(Styles.text)}>
                            {t('text')}.{' '}
                            <a
                                className={css(Styles.link)}
                                href="/privacy"
                                rel="noreferrer"
                                target="_blank"
                            >
                                {t('nav.privacy')}
                            </a>
                        </p>
                    </div>
                    <button
                        type="button"
                        className={css(MakeButton(PrimaryFilled), Styles.btn)}
                        onClick={onAccept}
                    >
                        {t('nav.accept')}
                    </button>
                </div>
            </div>
        </Portal>
    );
}

const Styles = CreateSheet({
    box: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        marginBottom: -500,
        padding: '15px 0',
        fontSize: '1rem',
        fontWeight: 600,
        color: Dark.default,
        borderTop: `1px solid ${GetColor('gray3')}`,
        background: '#fff',
        boxShadow: '0px 0px 20px 0px rgba(48, 48, 48, 0.05)',
        transition: 'margin-bottom 0.2s',
        zIndex: 1298,
    },
    showBox: {
        marginBottom: 0,
    },
    container: {
        display: 'flex',
        width: 1240,
        maxWidth: '100%',
        margin: '0 auto',
        padding: '0 20px',
        alignItems: 'center',
    },
    close: {
        position: 'absolute',
        top: 18,
        right: 18,
        cursor: 'pointer',
        ':nth-child(1n) svg': {
            display: 'block',
            fill: GetColor('secondary'),
        },
        ':hover': {
            ':nth-child(1n) svg': {
                display: 'block',
                fill: Dark.default,
            },
        },
    },
    img: {
        display: 'block',
        flexShrink: 0,
    },
    main: {
        margin: '0 15px',
        flexGrow: 1,
    },
    title: {
        margin: 0,
        fontSize: '1.285rem',
        fontWeight: 700,
    },
    text: {
        margin: 0,
    },
    btn: {
        height: 38,
        padding: '9px 20px',
        lineHeight: 1,
        whiteSpace: 'nowrap',
    },
    link: {
        color: GetColor('accent'),
        textDecoration: 'underline',
    },
});
