import {Connection} from './server';

export type Tracking = {
    page_views: {
        path: string;
    };
};

export async function Track<T extends keyof Tracking>(key: T, data: Tracking[T]) {
    Connection.Push(`tracking`, key, data);
}
