import {useLocalization} from 'packages/localization/localization';
import {css, CreateSheetWithTheme, useTheme, CSSProperties} from 'aphrodite';
import {LoadButton} from 'src/themes/button';
import {MakeInput} from 'src/themes/input';
import {useForm} from 'react-hook-form';
import {Primary} from 'src/themes/colors';
import {useState} from 'react';
import {RequestResetPassword, SetNewPasswordFromReset} from 'packages/session/session';
import {InputTheme} from 'packages/forms/Input';
import {ButtonTheme} from 'packages/buttons/button.theme';
import ReactCodeInput from 'packages/forms/CodeInput.react';
import {DefaultLayout} from 'src/themes/code_input';
import {history, OpenPage} from 'packages/history/history';
import {SmoothSteper, SmoothSteperItem} from 'packages/motion/SmoothStepper.react';
import {FormStyles} from 'src/themes/form';
import SmoothLine from 'packages/motion/SmoothLine.react';
import PasswordInput from 'packages/forms/PasswordInput.react';
import {RequestResetPasswordRequest} from 'packages/session/API/RequestResetPasswordAPI';
import {useQueryParser} from 'typed-query-parser';
import PlusSignVerifier from 'packages/helpers/PlusSignVerifier';

export default function ResetPasswordReact({theme}: {theme: ResetPasswordTheme}) {
    const [queryData] = useQueryParser(history, types => ({
        code: types.string(),
        id: types.string(),
    }));

    const [Identifier, SetIdentifier] = useState(PlusSignVerifier(queryData.id?.trim() ?? ''));
    const [ActiveStep, SetActiveStep] = useState(queryData.code?.trim() ? 1 : 0); // 0 - enter email, 1 - enter code and password, 2 - success

    function OnSubmitReqeust(email: string) {
        SetIdentifier(email);
        SetActiveStep(1);
    }

    function OnSetPassword() {
        SetActiveStep(2);
    }

    return (
        <SmoothSteper {...{active: ActiveStep, duration: 300, motion: ['fade']}}>
            <SmoothSteperItem>
                <EnterIdentifier {...{theme, OnSubmit: OnSubmitReqeust}} />
            </SmoothSteperItem>
            <SmoothSteperItem>
                <EnterCodeAndPassword
                    {...{
                        theme,
                        identifier: Identifier,
                        OnSubmit: OnSetPassword,
                        defaultCode: queryData.code,
                    }}
                />
            </SmoothSteperItem>
            <SmoothSteperItem>
                <Success {...{theme}} />
            </SmoothSteperItem>
        </SmoothSteper>
    );
}

function EnterIdentifier({theme, OnSubmit}: {theme: ResetPasswordTheme; OnSubmit: (identifier: string) => void}) {
    const {t, tReady} = useLocalization('login');

    const Styles = useTheme(theme, StylesWithTheme);
    const [Loading, SetLoading] = useState(false);

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        setError,
        formState: {errors},
    } = useForm<RequestResetPasswordRequest>({
        defaultValues: {
            identifier: '',
        },
    });

    const OnSubmitForm = handleSubmit(async data => {
        SetLoading(true);
        const err = await RequestResetPassword({
            identifier: data.identifier,
        });
        SetLoading(false);

        if (err !== null) {
            setError('identifier', {message: err.text});
        } else {
            OnSubmit(data.identifier);
        }
    });

    if (!tReady) {
        return null;
    }

    return (
        <form
            {...{
                method: 'POST',
                className: css(FormStyles.form),
                onSubmit: OnSubmitForm,
            }}
        >
            <div
                {...{
                    htmlFor: 'identifier',
                    className: css(FormStyles.note, Styles.note),
                }}
            >
                {t('reset.text')}
            </div>
            <input
                {...register('identifier', {
                    required: true,
                })}
                {...{
                    id: 'res-identifier',
                    name: 'identifier',
                    type: 'text',
                    placeholder: t('inputs.emailPhone'),
                    autoFocus: true,
                    autoComplete: 'off',
                    onChange: e => {
                        setValue('identifier', PlusSignVerifier(e.target.value));
                    },
                    className: css(
                        MakeInput(theme.input, {
                            fontSize: 16,
                            fontWeight: 400,
                            padding: '8px 16px',
                            width: '100%',
                            borderRadius: 12,
                            lineHeight: '28px',
                        })
                    ),
                }}
            />
            <div className={css(Styles.error)}>
                <SmoothLine motion={['fade', 'swipe-up']}>{errors.identifier?.message}</SmoothLine>
            </div>
            <LoadButton
                {...{
                    type: 'submit',
                    disabled: watch('identifier').length === 0,
                    loading: Loading,
                    className: css(Styles.submit),
                    theme: theme.button,
                    layout: {
                        width: '100%',
                        padding: '15px 0',
                        fontSize: '1.1429rem',
                        lineHeight: 1,
                        fontWeight: 600,
                        whiteSpace: 'nowrap',
                        borderRadius: 13,
                        disabled: watch('identifier').length === 0,
                    },
                }}
            >
                Continue
            </LoadButton>
            <footer className={css(Styles.footer)}>
                <a
                    {...{
                        className: css(Styles.footer_link),
                        href: '/login',
                        onClick: OpenPage('login'),
                    }}
                >
                    {t('reset.return')}
                </a>
            </footer>
        </form>
    );
}

function EnterCodeAndPassword({
    theme,
    identifier,
    OnSubmit,
    defaultCode,
}: {
    theme: ResetPasswordTheme;
    identifier: string;
    OnSubmit: () => void;
    defaultCode?: string;
}) {
    const {t, tReady} = useLocalization('login');
    const Styles = useTheme(theme, StylesWithTheme);
    const [Code, SetCode] = useState(defaultCode ?? '');
    const [Loading, SetLoading] = useState(false);

    const {
        register,
        handleSubmit,
        watch,
        setError,
        formState: {errors},
    } = useForm<{password: string; confirm_password: string}>({
        defaultValues: {
            password: '',
            confirm_password: '',
        },
    });

    async function OnChangeCode(code: string) {
        SetCode(code);
    }

    const OnSubmitForm = handleSubmit(async data => {
        if (data.password !== data.confirm_password) {
            setError('password', {message: t('reset.passwordError')});
        } else {
            SetLoading(true);
            const err = await SetNewPasswordFromReset({...data, identifier, code: Code});
            SetLoading(false);
            if (err !== null) {
                setError('password', {message: err.text});
            } else {
                OnSubmit();
            }
        }
    });

    const isEmpty = Code.length === 0 || watch('password').length === 0 || watch('confirm_password').length === 0;

    if (!tReady) {
        return null;
    }

    return (
        <div
            {...{
                className: css(FormStyles.form),
            }}
        >
            {!defaultCode && (
                <div
                    {...{
                        htmlFor: 'email',
                        className: css(FormStyles.note, Styles.note, Styles.note_sent),
                    }}
                >
                    {t('reset.sent')} <strong>{identifier}</strong>
                </div>
            )}
            {!defaultCode && (
                <div className={css(Styles.code)}>
                    <ReactCodeInput
                        {...{
                            inputMode: 'numeric',
                            fields: 6,
                            inputLayout: {...DefaultLayout, width: 45},
                            inputTheme: theme.input,
                            onChange: OnChangeCode,
                            disabled: Loading,
                        }}
                    />
                </div>
            )}
            <form
                {...{
                    method: 'POST',
                    className: css(Styles.passwords),
                    onSubmit: OnSubmitForm,
                }}
            >
                <PasswordInput
                    {...{
                        inputProps: {
                            ...register('password', {
                                required: true,
                            }),
                            id: 'res-password',
                            type: 'password',
                            autoComplete: 'off',
                            placeholder: t('inputs.newPassword'),
                            className: css(
                                MakeInput(theme.input, {
                                    fontSize: '1.2857rem',
                                    fontWeight: 600,
                                    padding: 20,
                                    width: '100%',
                                    borderRadius: 17,
                                }),
                                Styles.input
                            ),
                        },
                    }}
                />
                <PasswordInput
                    {...{
                        inputProps: {
                            ...register('confirm_password', {
                                required: true,
                            }),
                            id: 'confirm_password',
                            type: 'password',
                            autoComplete: 'off',
                            placeholder: t('inputs.confirmPassword'),
                            className: css(
                                MakeInput(theme.input, {
                                    fontSize: '1.2857rem',
                                    fontWeight: 600,
                                    padding: 20,
                                    width: '100%',
                                    borderRadius: 17,
                                })
                            ),
                        },
                    }}
                />
                <div className={css(Styles.error)}>
                    <SmoothLine motion={['fade', 'swipe-up']}>{errors.password?.message}</SmoothLine>
                </div>
                <LoadButton
                    {...{
                        type: 'submit',
                        disabled: isEmpty,
                        loading: Loading,
                        className: css(Styles.submit),
                        theme: theme.button,
                        layout: {
                            width: '100%',
                            padding: '15px 0',
                            fontSize: '1.1429rem',
                            lineHeight: 1,
                            fontWeight: 600,
                            whiteSpace: 'nowrap',
                            borderRadius: 13,
                            disabled: isEmpty,
                        },
                    }}
                >
                    {t('reset.setPassword')}
                </LoadButton>
            </form>
            <footer className={css(Styles.footer)}>
                <a
                    {...{
                        className: css(Styles.footer_link),
                        href: '/login',
                        onClick: OpenPage('login'),
                    }}
                >
                    {t('reset.return')}
                </a>
            </footer>
        </div>
    );
}

export function Success({theme}: {theme: ResetPasswordTheme}) {
    const {t, tReady} = useLocalization('login');
    const Styles = useTheme(theme, StylesWithTheme);

    if (!tReady) {
        return null;
    }

    return (
        <div
            {...{
                className: css(FormStyles.form),
            }}
        >
            <div
                {...{
                    className: css(FormStyles.note, Styles.note),
                }}
            >
                {t('reset.changedPassword')}{' '}
            </div>
            <footer className={css(Styles.footer)}>
                <a
                    {...{
                        className: css(Styles.footer_link),
                        href: '/login',
                        onClick: OpenPage('login'),
                    }}
                >
                    {t('reset.return')}
                </a>
            </footer>
        </div>
    );
}

export type ResetPasswordTheme = {
    input: InputTheme;
    button: ButtonTheme;
    label_color: CSSProperties['color'];
};

const StylesWithTheme = CreateSheetWithTheme((theme?: ResetPasswordTheme) => {
    return {
        passwords: {
            width: '100%',
        },
        label: {
            color: theme?.label_color,
        },
        note: {
            color: theme?.label_color,
            fontWeight: 500,
            fontSize: '1rem',
            fontHeight: '1.214rem',
            marginBottom: 25,
        },
        note_sent: {
            textAlign: 'center',
            marginBottom: 15,
        },
        input: {
            display: 'block',
            marginBottom: 25,
        },
        error: {
            display: 'flex',
            justifyContent: 'flex-start',
            fontSize: '0.85714rem',
            color: Primary.default,
            textAlign: 'center',
            fontWeight: 600,
            height: 28,
            paddingTop: 10,
        },
        code: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 20,
        },
        submit: {
            display: 'block',
            margin: '25px auto 0 auto',
        },
        footer: {
            paddingTop: 30,
            fontSize: '1.2857rem',
            fontWeight: 500,
            textAlign: 'center',
        },
        footer_link: {
            textDecoration: 'none',
            color: Primary.default,
            ':hover': {
                textDecoration: 'underline',
            },
        },
        link: {
            fontWeight: 500,
            textDecoration: 'none',
            color: Primary.default,
            marginTop: 5,
        },
    };
});
