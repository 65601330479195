import {StyleDeclaration, CreateSheet} from 'aphrodite';
import {ButtonTheme, ButtonLayout} from './button.theme';
export type {ButtonTheme, ButtonLayout};

const Button = ({disabled, ...styles}: ButtonLayout, theme: ButtonTheme) => {
    let css: StyleDeclaration = {
        userSelect: 'none',
        outline: 0,
        textDecoration: 'none',
        border: '2px solid transparent',
        ...styles,
        ...theme.plain,
        ':nth-child(1n) svg': {
            fill: theme.plain.color,
        },
        ':disabled': {
            opacity: 0.6,
            cursor: 'default',
            boxShadow: 'none',
        },
    };

    if (theme.hover) {
        css = {
            ...css,
            ...{
                ':hover and :not(:disabled)': {
                    ...theme.hover,
                    ':nth-child(1n) svg': {
                        fill: theme.hover.color,
                    },
                },
            },
        };
    }

    if (theme.active) {
        css = {
            ...css,
            ...{
                ':enabled': {
                    ':active': {
                        ...theme.active,
                        ':nth-child(1n) svg': {
                            fill: theme.active.color,
                        },
                    },
                },
            },
        };
    }

    return CreateSheet({base: css}).base;
};

export default Button;
