import CreateGUID from 'packages/helpers/CreateGUID';
import {Message, MessageBody} from './model';
import {Connection, CreateConnection, Request, Publish, Subscribe, Close, AuthResolver, Subscription} from './wrapper';
import {CreateError, ErrorData, isError, ResultWithError} from 'packages/errors/errors';
import EventEmitter from 'eventemitter3';

type config = {
    url: string;
    auth_resolver: AuthResolver;
    prefix: string;
};

const Events = new EventEmitter();
const EmptyResolver: AuthResolver = async () => {
    return {nkey: '', sig: ''};
};

export type Responder<R> = (response: R | ErrorData<R>) => void;
export type SubscriptionCallback<M, R> = (body: M, reply?: Responder<R>) => void;
export type {MessageBody};

class Pubsub {
    private nc: Connection | undefined;
    private config: config;
    private guid: string;
    private subscriptions: Record<string, {marker: string; sub: Subscription}>;
    private static instance: Pubsub;

    public constructor(config: config) {
        this.config = config;
        this.guid = CreateGUID();
        this.subscriptions = {};
    }

    private createMessage<M extends object>(marker: string, body: M): Message<M> {
        return {
            marker: marker,
            body: {
                status_code: 0,
                status_text: '',
                payload: body,
            },
            context: {
                u: this.getUserCookie(),
                a: window?.navigator?.userAgent || '',
            },
        };
    }

    private getUserCookie() {
        var match = document.cookie.match(new RegExp('(^| )auth-session-dguide=([^;]+)'));
        return match && match.length >= 3 ? match[2] : '';
    }

    private createError<M extends object>(marker: string, error: ErrorData<M>): Message<M> {
        const payload: any = undefined;
        return {
            marker: marker,
            body: {
                status_code: error.code,
                status_text: error.text,
                payload: error.payload || payload,
            },
            context: {
                u: this.getUserCookie(),
                a: window?.navigator?.userAgent || '',
            },
        };
    }

    private responder =
        <R extends object>(marker: string, reply?: string) =>
        (response: R | ErrorData<R>) => {
            if (!reply) {
                return null;
            }

            if (this.nc) {
                if (isError(response)) {
                    return Publish(this.nc, reply, this.createError(marker, response));
                }

                return Publish(this.nc, reply, this.createMessage(marker, response));
            } else {
                return CreateError('no active connection');
            }
        };

    private listenSubject<M extends object, R extends object>(subject: string, marker: string): ErrorData | null {
        if (this.nc) {
            const sub = Subscribe<M>(this.nc, `${this.config.prefix}_${subject}`, (msg, reply) => {
                Events.emit(`${subject}-${msg.marker}`, msg.body.payload, this.responder<R>(marker, reply));
            });
            this.subscriptions[subject] = {marker, sub};
            return null;
        }

        return CreateError('no active connection');
    }

    private disconnet() {
        this.nc && Close(this.nc);
    }

    private async reconnect() {
        const err = await this.CreateConnection();
        if (err !== null) {
            return err;
        }

        Object.entries(this.subscriptions).forEach(([subject, data]) => {
            this.listenSubject(subject, data.marker);
        });

        return null;
    }

    public static getInstance(config: config, force?: boolean): Pubsub {
        if (!Pubsub.instance || force) {
            if (Pubsub.instance) {
                Pubsub.instance.disconnet();
            }
            Pubsub.instance = new Pubsub(config);
        }

        return Pubsub.instance;
    }

    public async CreateConnection(): Promise<ErrorData | null> {
        try {
            this.nc = await CreateConnection(this.config.url, this.guid, this.config.auth_resolver);
            return null;
        } catch (error) {
            return CreateError(error as Error);
        }
    }

    public async Push<M extends object>(subject: string, marker: string, body: M): Promise<ErrorData | null> {
        if (this.nc) {
            if (this.nc.isClosed()) {
                const err = await this.reconnect();
                if (err !== null) {
                    return err;
                }
            }

            Publish(this.nc, `${this.config.prefix}_${subject}`, this.createMessage(marker, body));
            return null;
        } else {
            return CreateError('no active connection');
        }
    }

    public async Request<M extends object, R extends object | void>(
        subject: string,
        marker: string,
        body: M
    ): Promise<ResultWithError<R>> {
        if (this.nc) {
            if (this.nc.isClosed()) {
                const err = await this.reconnect();
                if (err !== null) {
                    return [null, err];
                }
            }
            const res = await Request<M, R>(
                this.nc,
                `${this.config.prefix}_${subject}`,
                this.createMessage(marker, body)
            );
            if (!res || !res.body) {
                return [null, CreateError('no response from the server')];
            }
            if (res.body.status_code > 0) {
                return [
                    null,
                    CreateError(res.body.status_code, res.body.status_text || 'bad request', res.body.payload),
                ];
            } else {
                return [res.body.payload, null];
            }
        }

        return [null, CreateError('no active connection')];
    }

    public CreateSubscription<M extends object, R extends object | void>(
        subject: string,
        marker: string,
        callback: SubscriptionCallback<M, R>
    ) {
        Events.addListener(`${subject}-${marker}`, callback);

        if (!this.subscriptions[subject]) {
            return this.listenSubject(subject, marker);
        }
    }

    public RemoveSubscription<M extends object, R extends object | void>(
        subject: string,
        marker: string,
        callback: SubscriptionCallback<M, R>
    ) {
        Events.removeListener(`${subject}-${marker}`, callback);
    }

    public RemoveSubjectSubscription(subject: string) {
        if (this.subscriptions[subject]) {
            this.subscriptions[subject].sub.unsubscribe();
            delete this.subscriptions[subject];
        }
    }
}

export let Conn: Pubsub = new Pubsub({url: '', auth_resolver: EmptyResolver, prefix: ''});

export async function InitConnection(config: config, force?: boolean): Promise<ErrorData | null> {
    Conn = Pubsub.getInstance(config, force);
    return await Conn.CreateConnection();
}
