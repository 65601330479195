import BuildIconsMap from 'packages/helpers/BuildIconsMap';
import {useEffect, useState} from 'react';
import InlineSVG, {Props as SVGProps} from 'react-inlinesvg';

export default function WebpackContextIcon({
    icons,
    icon,
    ...props
}: {icons: __WebpackModuleApi.RequireContext; icon?: string | null} & Omit<SVGProps, 'src'>) {
    const [iconSrc, setIconSrc] = useState<string | null>(null);

    useEffect(() => {
        const iconsMap = BuildIconsMap(icons);
        const Icon = icon ? iconsMap[icon] || iconsMap['Generic'] : iconsMap['Generic'];
        Icon?.then(icon => {
            setIconSrc(icon);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [icon]);

    if (!iconSrc) {
        return null;
    }

    return (
        <InlineSVG
            src={iconSrc}
            {...props}
        />
    );
}
