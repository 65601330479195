import {CreateSheetWithTheme, StyleDeclaration, css, useTheme} from 'packages/aphrodite/aphrodite';
import CreateGUID from 'packages/helpers/CreateGUID';
import {useRef, useState} from 'react';
import {RadioDefaultayout} from 'src/themes/radio';
import {RadioLayout, RadioTheme} from './Radio.theme';

export type RadioProps<T> = Omit<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>,
    'onChange'
> & {
    theme: RadioTheme;
    labelClassName?: string;
    onChange: (value: T) => void;
    default_value?: T;
    layout?: Partial<RadioLayout>;
    items: {
        label: React.ReactNode;
        value: T;
    }[];
    styles?: {
        checkcontainer?: StyleDeclaration;
        labelActive?: StyleDeclaration;
    };
};

export default function RadioReact<T extends string | ReadonlyArray<string> | number | undefined>({
    items,
    theme,
    layout,
    default_value,
    onChange,
    labelClassName,
    styles,
    ...rest
}: RadioProps<T>) {
    const [selected, setSelected] = useState(default_value);
    const Styles = useTheme(theme, StylesWithTheme, {...RadioDefaultayout, ...(layout || {})});
    const Name = useRef(CreateGUID());

    return (
        <section {...rest}>
            {items.map((item, index) => {
                return (
                    <label
                        className={`${css(
                            Styles.checkcontainer,
                            styles?.checkcontainer || undefined,
                            item.value === selected && styles?.labelActive ? styles?.labelActive : undefined
                        )} ${labelClassName || ''}`}
                        key={index}
                    >
                        <input
                            {...{
                                type: 'radio',
                                name: Name.current,
                                value: item.value,
                                defaultChecked: default_value ? default_value === item.value : index === 0,
                                onChange: e => {
                                    if (e.target.checked) {
                                        setSelected(item.value);
                                        onChange(item.value);
                                    }
                                },
                                className: css(Styles.input),
                            }}
                        />
                        <span className={`${css(Styles.radio)} radiobtn`} />
                        <div>{item.label}</div>
                    </label>
                );
            })}
        </section>
    );
}

const StylesWithTheme = CreateSheetWithTheme((theme?: RadioTheme, layout?: RadioLayout) => {
    return {
        checkcontainer: {
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            marginBottom: 10,
            cursor: 'pointer',
            fontSize: layout?.fontSize,
            userSelect: 'none',
            flexShrink: 0,
            ':hover .radiobtn': {
                //backgroundColor: theme?.radio_hover_background_color,
            },
            ':hover input:checked ~ .radiobtn': {
                //backgroundColor: theme?.radio_checked_color,
            },
            ':last-child': {
                marginBottom: 'unset',
            },
        },
        radio: {
            height: layout?.size,
            width: layout?.size,
            marginRight: 16,
            backgroundColor: theme?.radio_background_color,
            borderRadius: '50%',
            position: 'relative',
            outline: theme?.radio_border,
            flexShrink: 0,
            ':after': {
                content: '""',
                opacity: 0,
                display: 'block',
                position: 'absolute',
                top: 0,
                left: 0,
                width: layout?.size || 20,
                height: layout?.size || 20,
                borderRadius: '50%',
                background: theme?.radio_checked_color,
                zIndex: 2,
                transform: 'scale(0)',
                transition: 'all 0.2s',
            },
            ':before': {
                content: '""',
                opacity: 0,
                display: 'block',
                position: 'absolute',
                top: 0,
                left: 0,
                width: layout?.size || 20,
                height: layout?.size || 20,
                borderRadius: '50%',
                backgroundColor: theme?.radio_hover_background_color,
                zIndex: -1,
                transform: 'scale(0)',
                transition: 'all 0.2s',
            },
        },
        input: {
            position: 'absolute',
            opacity: 0,
            cursor: 'pointer',
            ':checked ~ .radiobtn:after': {
                opacity: 1,
                transform: 'scale(0.8)',
            },
            ':checked': {
                ':hover ~ .radiobtn:before': {
                    opacity: 0,
                },
            },
            ':hover ~ .radiobtn:before': {
                opacity: 1,
                transform: 'scale(2)',
            },
        },
    };
});
