import moment from 'moment';
import {AvailabilitySettingsRuntype, EmptyAvailability} from 'packages/availability/models';
import CreateGUID from 'packages/helpers/CreateGUID';
import {MediaItemRuntype} from 'packages/media/model';
import {ItemTranslationStatusRuntype} from 'packages/translations/models';
import * as r from 'runtypes';

export const CategoryRuntype = r.Record({
    id: r.Number,
    title: r.String,
    icon: r.String.Or(r.Null),
});

export type Category = r.Static<typeof CategoryRuntype>;

export const CategoriesRuntype = r.Array(CategoryRuntype);
export type Categories = r.Static<typeof CategoriesRuntype>;

export const ResourcePriceRuntype = r.Record({
    id: r.Number,
    label: r.String,
    guid: r.String,
    price: r.Number.Or(r.Null),
    is_limited_capacity: r.Boolean,
    max_capacity: r.Number.Or(r.Null),
    partner_price: r.Number.Or(r.Null),
    description: r.String,
    manager_app_id: r.Number.Or(r.Null),
    manager_app_instance: r.String.Or(r.Null),
    media: r.Array(MediaItemRuntype),
});
export type ResourcePrice = r.Static<typeof ResourcePriceRuntype>;

export const PublicResourceRuntype = r.Record({
    id: r.Number,
    guid: r.String.Or(r.Null),
    title: r.String,
    price_label: r.String,
    price_description: r.String,
    description: r.String,
    website: r.String,
    address: r.String,
    lat: r.Number,
    lng: r.Number,
    created_at: r.Number,
    category: CategoryRuntype,
    prices: r.Array(ResourcePriceRuntype),
    videos: r.Array(r.String),
    media: r.Array(MediaItemRuntype),
});
export type PublicResource = r.Static<typeof PublicResourceRuntype>;

export const PublicResourcesRuntype = r.Array(PublicResourceRuntype);
export type PublicResources = r.Static<typeof PublicResourcesRuntype>;

export const ResourceRuntype = PublicResourceRuntype.extend({
    is_paid: r.Boolean,
    purchased_description: r.String,
    is_limited_capacity: r.Boolean,
    max_capacity: r.Number.Or(r.Null),
    is_limited_stay: r.Boolean,
    stay_duration: r.Number.Or(r.Null),
    max_delay: r.Number,
    prior_book_limit: r.Number,
    availability: AvailabilitySettingsRuntype.Or(r.Null),
    translations: r.Array(ItemTranslationStatusRuntype),
});
export type Resource = r.Static<typeof ResourceRuntype>;

export const ResourcesRuntype = r.Array(ResourceRuntype);
export type Resources = r.Static<typeof ResourcesRuntype>;

export const DefaultCategory: Category = {
    id: 1,
    title: 'Generic',
    icon: null,
};

export const EmptyResource: Resource = {
    id: 0,
    guid: '',
    title: '',
    description: '',
    price_description: '',
    price_label: '',
    is_paid: false,
    purchased_description: '',
    website: '',
    max_delay: 0,
    prior_book_limit: 16,
    is_limited_capacity: false,
    max_capacity: null,
    is_limited_stay: false,
    stay_duration: null,
    lat: 0,
    lng: 0,
    address: '',
    created_at: moment().unix(),
    category: {...DefaultCategory},
    availability: {...EmptyAvailability()},
    prices: [],
    videos: [],
    media: [],
    translations: [],
};

export const EmptyPrice: () => ResourcePrice = () => ({
    id: 0,
    label: '',
    guid: CreateGUID(),
    price: null,
    partner_price: null,
    description: '',
    media: [],
    is_limited_capacity: false,
    max_capacity: 0,
    manager_app_id: null,
    manager_app_instance: null,
});

export const ResourceWithAccessRuntype = r.Record({
    id: r.Number,
    title: r.String,
    access_level: r.Number,
});
export type ResourceWithAccess = r.Static<typeof ResourceWithAccessRuntype>;
