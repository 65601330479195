import {CreateSheetWithTheme, css, useTheme} from 'packages/aphrodite/aphrodite';
import React, {Fragment, JSXElementConstructor, ReactElement, useEffect} from 'react';
import ReactDOMServer from 'react-dom/server';
import ReactTooltip, {Effect, Place, Type} from 'react-tooltip';
import './Tooltip.css';
import {InternalTheme, TooltipLayout, TooltipTheme} from './Tooltip.theme';

export default function Tooltip({
    theme,
    layout,
    event,
    place,
    color,
    effect,
    children,
    content,
    disabled,
    clickable,
    delayUpdate,
    delayHide,
    delayShow,
}: React.PropsWithChildren<{
    theme: TooltipTheme;
    event?: 'hover' | 'click';
    layout?: TooltipLayout;
    effect?: Effect;
    place?: Place;
    color?: Type;
    content: ReactElement<any, string | JSXElementConstructor<any>> | string;
    disabled?: boolean;
    clickable?: boolean;
    delayUpdate?: number;
    delayHide?: number;
    delayShow?: number;
}>) {
    const Styles = useTheme({theme, layout}, StylesWithTheme);

    const availableTypes = ['span', 'div', 'ul', 'li', 'svg', 'button', 'small', 'label', 'img', '[object Object]'];

    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            if (!availableTypes.includes(child.type.toString())) {
                throw new Error(`Child shoud be one of elements: ${availableTypes.join(', ')}`);
            }

            return React.cloneElement(child as ReactElement<any>, {
                'data-tip': typeof content === 'string' ? content : ReactDOMServer.renderToStaticMarkup(content),
                'data-html': typeof content !== 'string',
                'data-place': place || 'top',
                'data-type': color || 'dark',
                'data-effect': effect || 'solid',
                'data-event': event,
                'data-class': css(Styles.base),
                'data-tip-disable': disabled,
                'data-clickable': clickable,
                'data-delay-update': delayUpdate,
                'data-delay-hide': delayHide,
                'data-delay-show': delayShow,
            });
        }
        return child;
    });

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    return <Fragment>{childrenWithProps}</Fragment>;
}

const StylesWithTheme = CreateSheetWithTheme((theme?: InternalTheme) => {
    return {
        base: {
            color: theme?.theme.color,
            fontSize: '0.85714rem',
            fontWeight: 600,
            backgroundColor: theme?.theme.backgroundColor,
            borderColor: theme?.theme.borderColor,
            boxShadow: theme?.theme.boxShadow,
            padding: theme?.layout?.padding || '4px 10px',
            borderRadius: theme?.layout?.borderRadius || 4,
            borderWidth: theme?.layout?.borderWidth || 0,
            zIndex: 10000,
            ':after': {
                backgroundColor: theme?.theme.backgroundColor,
                zIndex: -3,
            },
            '&.place-top:after': {
                bottom: -5,
                borderTopColor: theme?.theme.backgroundColor,
            },
            '&.place-bottom:after': {
                top: -5,
                borderBottomColor: theme?.theme.backgroundColor,
            },
            '&.place-left:after': {
                right: -5,
                borderLeftColor: theme?.theme.backgroundColor,
            },
            '&.place-right:after': {
                left: -5,
                borderRightColor: theme?.theme.backgroundColor,
            },
        },
    };
});
