import React from 'react';
import ReactDOM from 'react-dom/client';
import 'packages/localization/init_i18n';
import App from './jsx/app.react';
import '../css/animations.css';
import '../generated/colors.css';
import '../css/base.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);
