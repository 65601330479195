//import AppleOAuthReact from "src/jsx/apple/OAuth.react";
import FacebookOAuthReact from 'src/jsx/facebook/OAuth.react';
//import GoogleOAuthReact from "src/jsx/google/OAuth.react";

export default function SocialBtns() {
    return (
        <>
            <FacebookOAuthReact />
            {/* <GoogleOAuthReact />
            
            <AppleOAuthReact /> */}
        </>
    );
}
