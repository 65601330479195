import CheckResultRuntime from 'packages/helpers/CheckResultRuntime';
import {put} from 'packages/rest/api';
import * as r from 'runtypes';

export async function ChangeActiveTourOperator(tour_operator_id: number) {
    const res = await put<true>({
        url: 'session/touroperators',
        body: {
            tour_operator_id,
        },
    });

    return CheckResultRuntime(r.Literal(true), res);
}
