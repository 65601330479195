import {get} from 'packages/rest/api';
import {CongintoCredentials, setPublicCognitoCreds} from './PublicCreds';

export default async function RequstCognitoPublicCredentials(IdentityId: string) {
    const res = await get<CongintoCredentials>({url: `session/cognito/${IdentityId}`});
    if (res[1] !== null) {
        throw res[1];
    }

    setPublicCognitoCreds(IdentityId, res[0]);

    return res[0];
}
