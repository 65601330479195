// styles
import {CreateSheet, css} from 'aphrodite';
import useQueryParam from 'packages/hooks/useQueryParam';
import {useEffect, useState} from 'react';
// components
import PageFooter from '../footers/PageFooter.react';
import PageHeader from '../headers/PageHeader.react';
import {DeletionRequest} from '../../../packages/facebook/models';
import GetDeletionRequest from 'packages/facebook/API/GetDeletionRequest';
import Spinner from 'packages/spinners/spinner.react';
import {PrimaryManagerPath} from 'src/themes/spinner';
import {OpenPage} from 'packages/history/history';

export default function DeletionRequestPage() {
    const [Code] = useQueryParam('id');
    const [Request, SetRequest] = useState<DeletionRequest | null>();

    useEffect(() => {
        async function Load() {
            const [res, err] = await GetDeletionRequest(Code);
            if (err === null && res != null) {
                SetRequest(res);
            } else {
                OpenPage('')();
            }
        }

        Load();
    }, [Code]);

    return (
        <article className={css(Styles.page)}>
            <PageHeader active="about" />
            <div className={css(Styles.main)}>
                {Request === null ? (
                    <Spinner
                        {...{
                            theme: PrimaryManagerPath,
                            height: 50,
                        }}
                    />
                ) : (
                    <div>
                        <div>
                            <b>Request code</b>: {Request?.code}
                        </div>
                        <div>
                            <b>Last request time</b>: {Request?.last_changed_at}
                        </div>
                        <div>
                            <b>Status</b>: {Request?.status === 1 ? 'User data was successfully deleted' : 'Error'}
                        </div>
                    </div>
                )}
            </div>
            <PageFooter showWave />
        </article>
    );
}

const Styles = CreateSheet({
    page: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    main: {
        paddingTop: 100,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        height: 200,
        alignItems: 'center',
        justifyContent: 'center',
    },
});
