import DecodeUriComponent from 'decode-uri-component';
import { useRef, useState, useEffect } from 'react';

function isArrayOptionsPreset(item) {
  return item.array_preset !== void 0;
}

class Boolean {
  validate(val, settings) {
    if (val.length > 1 && (settings == null ? void 0 : settings.shouldThrow)) {
      throw new Error("Expected boolean but got array");
    }
    return val.length !== 0 ? true : this.defaultValue;
  }
  static stringify(val) {
    return val ? "" : false;
  }
}
function boolean() {
  return new Boolean();
}
class Number {
  constructor(defaultValue) {
    this.defaultValue = defaultValue;
  }
  validate(val, settings) {
    if (val.length > 1 && (settings == null ? void 0 : settings.shouldThrow)) {
      throw new Error("Expected number but got array");
    }
    if (val[0]) {
      if (/^-?\d+\.?\d*$/.test(val[0])) {
        return +val[0];
      } else {
        if (settings == null ? void 0 : settings.shouldThrow) {
          throw new Error(`Expected number but got "${val[0]}"`);
        }
        return this.defaultValue;
      }
    }
    return this.defaultValue;
  }
  static stringify(val) {
    return typeof val === "number" ? val.toString() : false;
  }
}
function number(defaultValue) {
  return new Number(defaultValue);
}
class String {
  constructor(defaultValue) {
    this.defaultValue = defaultValue;
  }
  validate(val, settings) {
    if (val.length > 1 && (settings == null ? void 0 : settings.shouldThrow)) {
      throw new Error("Expected string but got array");
    }
    return val.length !== 0 ? val[0] : this.defaultValue;
  }
  static stringify(val) {
    return typeof val === "string" ? val : false;
  }
}
function string(defaultValue) {
  return new String(defaultValue);
}
class Literal {
  constructor(literals, defaultValue) {
    this.defaultValue = defaultValue;
    this.literals = literals;
  }
  validate(val, settings) {
    if (val.length > 1 && (settings == null ? void 0 : settings.shouldThrow)) {
      throw new Error("Expected literal but got array");
    }
    if (val[0]) {
      const literal2 = this.literals.find((literal3) => typeof literal3 === "number" && val[0] ? literal3 === parseInt(val[0]) : literal3 === val[0]);
      if (literal2) {
        return literal2;
      } else if (settings == null ? void 0 : settings.shouldThrow) {
        throw new Error(`Expected literal one of [${this.literals.join(",")}] but got ${val[0]}`);
      }
    }
    return this.defaultValue;
  }
  static stringify(val) {
    return typeof val === "string" ? val : typeof val === "number" ? val.toString() : false;
  }
}
function literal(...literals) {
  function createLiteral(defaultValue) {
    return new Literal(literals, defaultValue);
  }
  return createLiteral;
}
class QueryArray {
  constructor(element, defaultValue) {
    this.defaultValue = defaultValue;
    this.element = element;
  }
  validate(val, settings) {
    if (val) {
      const elems = [];
      let list = [];
      if (!settings || !isArrayOptionsPreset(settings)) {
        if (val.length > 1 && (settings == null ? void 0 : settings.shouldThrow)) {
          throw new Error(`Array separator is "${settings.array_separator}" set but query contains more than one key:value pair`);
        }
        if (val[0]) {
          list = val[0].split((settings == null ? void 0 : settings.array_separator) || ",");
        }
      } else {
        list = val;
      }
      list.forEach((elem) => {
        const val2 = this.element.validate([elem], settings);
        if (typeof val2 !== "undefined") {
          elems.push(val2);
        }
      });
      return elems.length ? elems : this.defaultValue;
    }
    return this.defaultValue;
  }
  static stringify(val, options) {
    if (!isArrayOptionsPreset(options)) {
      return val.join(options.array_separator);
    }
    return val;
  }
}
function array(element) {
  function createArray(defaultValue) {
    return new QueryArray(element, defaultValue);
  }
  return createArray;
}
function isQueryArray(item) {
  return item.element !== void 0;
}

var __defProp$1 = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols$1 = Object.getOwnPropertySymbols;
var __hasOwnProp$1 = Object.prototype.hasOwnProperty;
var __propIsEnum$1 = Object.prototype.propertyIsEnumerable;
var __defNormalProp$1 = (obj, key, value) => key in obj ? __defProp$1(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues$1 = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp$1.call(b, prop))
      __defNormalProp$1(a, prop, b[prop]);
  if (__getOwnPropSymbols$1)
    for (var prop of __getOwnPropSymbols$1(b)) {
      if (__propIsEnum$1.call(b, prop))
        __defNormalProp$1(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
function CreateSchema(builder) {
  return builder({
    boolean,
    string,
    number,
    literal,
    array
  });
}
function ParseQuery(queryString, second, settings) {
  const query = new URLSearchParams(queryString);
  const schema = typeof second === "function" ? CreateSchema(second) : second;
  const res = {};
  let validateSettings;
  if (settings) {
    if ("array_preset" in settings && settings.array_preset) {
      validateSettings = __spreadProps(__spreadValues$1({}, settings), {
        array_preset: settings.array_preset
      });
    } else if ("array_separator" in settings && settings.array_separator) {
      validateSettings = __spreadProps(__spreadValues$1({}, settings), {
        array_separator: settings.array_separator
      });
    } else {
      validateSettings = __spreadProps(__spreadValues$1({}, settings), {
        array_separator: ","
      });
    }
  } else {
    validateSettings = { array_separator: "," };
  }
  for (const key in schema) {
    if (Object.prototype.hasOwnProperty.call(schema, key)) {
      const elem = schema[key];
      if (!elem) {
        continue;
      }
      let queryKey = key;
      if (settings && isQueryArray(elem)) {
        if ("array_preset" in settings) {
          queryKey = settings.array_preset === "elements[]" ? `${queryKey}[]` : queryKey;
        }
      }
      try {
        res[key] = elem.validate(query.getAll(queryKey), validateSettings);
      } catch (error) {
        if (settings == null ? void 0 : settings.shouldThrow) {
          throw new Error(`${key}: ${error}`);
        }
      }
    }
  }
  return res;
}

function Stringify(data, settings) {
  return StringifyOn("", data, settings);
}
function StringifyOn(on, data, settings) {
  const query = new URLSearchParams(on);
  let arrayOptions;
  if (settings && "array_preset" in settings && settings.array_preset) {
    arrayOptions = {
      array_preset: settings.array_preset
    };
  } else if (settings && "array_separator" in settings && settings.array_separator) {
    arrayOptions = {
      array_separator: settings.array_separator
    };
  } else {
    arrayOptions = { array_separator: "," };
  }
  if (data) {
    Object.entries(data).forEach(([key, val]) => {
      let value = false;
      switch (typeof val) {
        case "boolean":
          value = Boolean.stringify(val);
          break;
        case "number":
          value = Number.stringify(val);
          break;
        case "string":
          value = String.stringify(val);
          break;
      }
      if (Array.isArray(val) && val.length > 0) {
        const value2 = QueryArray.stringify(val, arrayOptions);
        if (Array.isArray(value2)) {
          let preset = "elements";
          if ("array_preset" in arrayOptions) {
            preset = arrayOptions.array_preset;
          }
          query.delete(key);
          value2.forEach((val2) => {
            query.append(preset === "elements" ? key : `${key}[]`, typeof val2 === "string" ? val2 : val2.toString());
          });
        } else {
          query.set(key, value2);
        }
      } else if (value !== false) {
        query.set(key, value);
      } else {
        query.delete(key);
      }
    });
  }
  return `${on.length > 0 && on[0] === "?" ? "?" : ""}${(settings == null ? void 0 : settings.decode) ? DecodeUriComponent(query.toString()) : query.toString()}`;
}

var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
function useQueryParser(query, schema, parser_settings) {
  const queryString = typeof query === "string" ? query : query.location.search;
  const _a = parser_settings || {}, { onUpdateAction } = _a, settings = __objRest(_a, ["onUpdateAction"]);
  const currentQueryString = useRef(queryString);
  const currentSchema = useRef(schema);
  const currentSettings = useRef(settings);
  const [parsedQuery, setParsedQuery] = useState(ParseQuery(queryString, currentSchema.current, currentSettings.current));
  const modifyQueryString = (queryVars, action) => {
    const newQuery = StringifyOn(queryString, queryVars, currentSettings.current);
    action = action || onUpdateAction;
    if (action) {
      if (typeof query !== "string") {
        query[action]({ search: newQuery });
      } else {
        if (typeof window !== "undefined") {
          window.history[`${action}State`](null, "", `${window.location.pathname}${newQuery.length && newQuery[0] !== "?" ? "?" : ""}${newQuery}`);
        }
      }
    }
    currentQueryString.current = newQuery;
    setParsedQuery((s) => __spreadValues(__spreadValues({}, s), queryVars));
    return newQuery;
  };
  useEffect(() => {
    if (currentQueryString.current !== queryString) {
      currentQueryString.current = queryString;
      setParsedQuery(ParseQuery(queryString, currentSchema.current, currentSettings.current));
    }
  }, [queryString]);
  return [parsedQuery, modifyQueryString];
}

export { CreateSchema, ParseQuery, Stringify, StringifyOn, useQueryParser };
