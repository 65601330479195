import moment from 'moment';
import RequstCognitoPublicCredentials from './RequstCognitoPublicCredentials';

export type CongintoCredentials = {
    Credentials: {
        AccessKeyId: string;
        Expiration: number;
        SecretKey: string;
        SessionToken: string;
    };
    IdentityId: string;
};

let publicCognitoCreds: Record<
    string,
    {
        reefresher?: NodeJS.Timeout;
        creds: CongintoCredentials;
    }
> = {};

export function getPublicCognitoCreds(IdentityId: string): CongintoCredentials | undefined {
    return publicCognitoCreds[IdentityId] ? publicCognitoCreds[IdentityId].creds : undefined;
}

export function setPublicCognitoCreds(IdentityId: string, creds?: CongintoCredentials) {
    if (creds) {
        const refreshIn = moment(creds.Credentials.Expiration).unix() - moment().unix() - 10 * 60;

        publicCognitoCreds[IdentityId] = {
            creds: creds,
            reefresher:
                refreshIn >= 0
                    ? setTimeout(() => {
                          RequstCognitoPublicCredentials(IdentityId);
                      }, refreshIn * 1000)
                    : undefined,
        };
    } else if (publicCognitoCreds[IdentityId]) {
        delete publicCognitoCreds[IdentityId];
    }

    return creds;
}
