import Config from 'src/config.json';

export default function GetRecaptchaToken(): Promise<string> {
    return new Promise((res, rej) => {
        grecaptcha.ready(function () {
            grecaptcha.execute(Config.google.recaptcha_key, {action: 'submit'}).then((token: string) => {
                return res(token);
            });
        });
    });
}
