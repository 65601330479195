// components
import PageFooter from '../footers/PageFooter.react';
import PageHeader from '../headers/PageHeader.react';
// styles
import {css, CreateSheet} from 'packages/aphrodite/aphrodite';

export default function StaticWithBlock({children, headerActive}: React.PropsWithChildren<{headerActive?: string}>) {
    return (
        <article className={css(Styles.page)}>
            <PageHeader active={headerActive} />
            <div className={css(Styles.main)}>{children}</div>
            <PageFooter showWave />
        </article>
    );
}

const Styles = CreateSheet({
    page: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    main: {
        padding: '40px 0',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
});
