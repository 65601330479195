import InitCognitoCreds from 'packages/aws/InitCognitoCreds';
import LoggedInAsUser from 'packages/console/components/LoggedInAsUser.react';
import Cookies from 'packages/cookies/cookies.react';
import {history, OnHistoryChange} from 'packages/history/history';
import Renderer from 'packages/lightbox/ModalRoot.react';
import {
    listenOnSessionChanges,
    listenOnSignIn,
    listenOnSignOut,
    LoadSession,
    unlistenOnSessionChanges,
    unlistenOnSignIn,
    unlistenOnSignOut,
} from 'packages/session/session';
import ToastContainer from 'packages/toasts/Container.react';
import {useDetachedEffect} from 'packages/useDetached';
import {Fragment, useEffect, useState} from 'react';
import ReactTooltip from 'react-tooltip';
import Config from 'src/config.json';
import {InitConnection} from 'src/runtime/pubsub/server';
import {bindOnPersonal, unbindOnPersonal} from '../runtime/pubsub/personal';
import RouterApp from './router.react';

export default function App() {
    const [Loading, SetLoading] = useState(true);
    const [Reload, SetReload] = useState(false);

    useDetachedEffect(async function InitSession() {
        try {
            await LoadSession();
            await InitCognitoCreds(Config.aws['map-cognito-identity-pool-id']);
            SetLoading(false);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useDetachedEffect(async function InitNats() {
        try {
            const res = await InitConnection();
            if (res !== null) {
                console.log(res);
            }

            OnHistoryChange(history.location, true);
        } catch (error) {
            console.log(error);
        }
    }, []);

    function onSessionChange() {
        SetReload(!Reload);
    }

    function numberInputHandle(e: WheelEvent) {
        const target = e.target as HTMLElement;
        if (target?.tagName === 'INPUT') {
            if ((target as HTMLInputElement).type === 'number') {
                target.blur();
            }
        }
    }

    useEffect(() => {
        bindOnPersonal('session_changes', onSessionChange);

        listenOnSessionChanges(onSessionChange);
        listenOnSignIn(onSessionChange);
        listenOnSignOut(onSessionChange);
        document.addEventListener('wheel', numberInputHandle);

        return () => {
            unbindOnPersonal('session_changes', onSessionChange);

            unlistenOnSessionChanges(onSessionChange);
            unlistenOnSignIn(onSessionChange);
            unlistenOnSignOut(onSessionChange);
            document.removeEventListener('wheel', numberInputHandle);
        };
    });

    if (Loading) {
        return null;
    }

    return (
        <Fragment>
            <LoggedInAsUser />
            <Renderer />
            <ToastContainer />
            <RouterApp />
            <ReactTooltip />
            <Cookies />
        </Fragment>
    );
}
