import {SpinnerTheme} from 'packages/spinners/spinner.theme';
import {Danger, Dark, Partners, PrimaryManager} from './colors';
import {GetColor} from 'packages/aphrodite/aphrodite';

export const PrimaryTheme: SpinnerTheme = {
    color: GetColor('accent'),
    pathColor: undefined,
};

export const PrimaryPath: SpinnerTheme = {
    color: GetColor('accent'),
    pathColor: 'rgba(0,0,0,0.1)',
};

export const PrimaryManagerPath: SpinnerTheme = {
    color: PrimaryManager.default,
    pathColor: 'rgba(0,0,0,0.1)',
};

export const DarkSpinner: SpinnerTheme = {
    color: Dark.default,
    pathColor: 'rgba(0,0,0,0.1)',
};

export const Red: SpinnerTheme = {
    color: Danger.default,
    pathColor: undefined,
};

export const RedWithPath: SpinnerTheme = {
    color: Danger.default,
    pathColor: 'rgba(0,0,0,0.1)',
};

export const PartnersTheme: SpinnerTheme = {
    color: Partners.default,
    pathColor: 'rgba(0,0,0,0.1)',
};

export const White: SpinnerTheme = {
    color: 'white',
    pathColor: undefined,
};
