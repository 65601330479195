import {RuntypeFromEnum} from 'packages/helpers/RuntypeFromEnum';
import * as r from 'runtypes';

export enum SubscriptionStatus {
    NoSubscription = '',
    Active = 'active',
    Canceled = 'canceled',
    Incomplete = 'incomplete',
    IncompleteExpired = 'incomplete_expired',
    PastDue = 'past_due',
    Paused = 'paused',
    Trialing = 'trialing',
    Unpaid = 'unpaid',
}
export const SubscriptionStatusRuntype = RuntypeFromEnum(SubscriptionStatus) as r.Runtype<SubscriptionStatus>;

export const PlanRuntype = r.Record({
    id: r.Number,
    marker: r.String,
    stripe_product_id: r.String,
    stripe_price_id: r.String,
    price: r.Number,
    trial_period: r.Number,
});
export type Plan = r.Static<typeof PlanRuntype>;

export const SubscriptionRuntype = r.Record({
    id: r.Number,
    stripe_subscription_id: r.String,
    stripe_customer_id: r.String,
    stripe_product_id: r.String,
    stripe_price_id: r.String,
    status: SubscriptionStatusRuntype,
    amount: r.Number,
    currency: r.String,
    current_period_start_at: r.Number.nullable(),
    current_period_end_at: r.Number.nullable(),
    trial_start_at: r.Number.nullable(),
    trial_end_at: r.Number.nullable(),
    should_cancel_at_period_end: r.Boolean,
    canceled_at: r.Number.nullable(),
    created_at: r.Number,
    plan: PlanRuntype,
});
export type Subscription = r.Static<typeof SubscriptionRuntype>;
