import {CreateSheet, css} from 'aphrodite';
// import DemoRequest from 'src/jsx/demo/components/DemoRequest.react';
import {SendMessageToIFrame} from 'packages/helpers/SendMessageToIFrame';
import {history} from 'packages/history/history';
import {GetPageMeta} from 'packages/history/metatags';
import {GetQueryParam} from 'packages/history/query_string';
import {useDetachedEffect} from 'packages/useDetached';
import {Suspense, useEffect, useRef, useState} from 'react';
import {useResizeDetector} from 'react-resize-detector';
import {Redirect, Route, Router, Switch, useLocation} from 'react-router-dom';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {FindPage, Page, Page404, pages, redirects} from 'src/runtime/pages';
import IsIFrame from 'packages/helpers/IsIFrame';
import WhatsappPopup from './contact/whatsappPopup.react';
import Spinner from 'packages/spinners/spinner.react';
import {PrimaryPath} from 'src/themes/spinner';

export type SwitcherProps = {
    Routes: Page[];
    IsRoot?: boolean;
};

export default function RouterApp() {
    return (
        <Router history={history}>
            <Switcher
                {...{
                    Routes: pages.filter(p => !p.parent_id),
                    IsRoot: true,
                }}
            />
            {!IsIFrame() && <WhatsappPopup />} {/* it needs to be here because we need useLocation hook */}
        </Router>
    );
}
function Switcher({Routes, IsRoot}: SwitcherProps) {
    const location = useLocation();
    const page = FindPage(Routes, location.pathname);

    return (
        <TransitionGroup className={css(Styles.transition)}>
            <CSSTransition
                key={page?.id}
                {...{
                    in: true,
                    timeout: {enter: 150, exit: 150},
                    classNames: 'fade',
                    unmountOnExit: true,
                }}
            >
                <Switch location={location}>
                    {IsRoot &&
                        redirects.map(Rdr => (
                            <Redirect
                                key={Rdr.from}
                                {...Rdr}
                            />
                        ))}

                    {Routes.map(Page => (
                        <Route
                            key={typeof Page.path === 'string' ? Page.path : Page.path.join('-')}
                            {...{
                                path: typeof Page.path === 'string' ? `/${Page.path}` : Page.path.map(p => `/${p}`),
                                exact: Page.exact,
                            }}
                        >
                            <PageLoader {...{Page}} />
                        </Route>
                    ))}
                    {IsRoot && (
                        <Route>
                            <PageLoader
                                {...{
                                    Page: Page404,
                                }}
                            />
                        </Route>
                    )}
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    );
}

function PageLoader({Page}: {Page: Page}) {
    const origin = GetQueryParam('origin');
    const location = useLocation();
    const [Meta, SetMeta] = useState<any | 'error' | 'loading'>('loading');
    const Pathname = useRef(location.pathname);
    const MetaCheck = useRef(Page.meta);
    const Component = useRef(Page.Component);
    const originVar = useRef<string | undefined>(origin && typeof origin[0] === 'string' ? origin[0] : undefined);
    const {height, ref} = useResizeDetector();

    useEffect(() => {
        Pathname.current = location.pathname;
        const origin = GetQueryParam('origin');
        if (typeof origin[0] === 'string') {
            originVar.current = origin[0];
        } else {
            originVar.current = undefined;
        }
    }, [location.pathname]);

    useDetachedEffect(() => {
        async function load() {
            try {
                const res = await GetPageMeta<any>(Pathname.current);
                if (res[1] !== null) {
                    if (res[1].code === 404) {
                        Component.current = Page404.Component;
                    }
                    SetMeta('error');
                } else {
                    MetaCheck.current.check(res[0]);
                    SetMeta(res[0]);
                }
            } catch (error) {
                SetMeta('error');
            }
        }

        if (Page.subRoutes?.length === 0) {
            load();
        }
    }, [location.pathname]);

    const PageComponent = Component.current;
    const ChildRoutes = pages.filter(p => p.parent_id === Page.id);

    useEffect(() => {
        SendMessageToIFrame({dgtalGuideFrameHeight: Math.max(70, height ?? 0)});
    }, [height]);

    return (
        <div
            className={css(Styles.wrapper)}
            ref={ref}
        >
            <Suspense
                {...{
                    fallback: (
                        <Spinner
                            theme={PrimaryPath}
                            height={50}
                        />
                    ),
                }}
            >
                <PageComponent
                    {...{
                        id: Page.id,
                        meta: {
                            is_loading: Meta === 'loading',
                            is_error: Meta === 'error',
                            payload: Meta,
                            redirect_source: originVar.current,
                        },
                        childRoutes: ChildRoutes,
                    }}
                >
                    {ChildRoutes.length > 0 ? (
                        <Switcher
                            {...{
                                Routes: ChildRoutes,
                            }}
                        />
                    ) : undefined}
                </PageComponent>
            </Suspense>
        </div>
    );
}

const Styles = CreateSheet({
    transition: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    wrapper: {
        height: '100%',
    },
    fallback: {
        flexGrow: 1,
        display: 'flex',
        background: 'red',
        minHeight: 100,
    },
});
