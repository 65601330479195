import config from 'src/config.json';
import {Conn, InitConnection as InitPubsub} from 'packages/pubsub/server';
import {ResolveData} from 'packages/pubsub/wrapper';
import {put} from 'packages/rest/api';

export {Conn as Connection};

export async function InitConnection() {
    return await InitPubsub({
        url: config.nats.server,
        auth_resolver: Resolver,
        prefix: config.nats.prefix,
    });
}

async function Resolver(nonce: string): Promise<ResolveData> {
    const res = await ResolveNats(nonce);
    if (res[0] === null) {
        throw new Error('Can not resolve connection');
    }

    return res[0];
}

async function ResolveNats(nonce: string) {
    return await put<ResolveData>({
        url: 'session/nats',
        body: {
            nonce,
        },
    });
}
