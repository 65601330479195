import EventEmitter from 'eventemitter3';
import SafeUpdate from 'packages/helpers/SafeUpdate';
import {createContext} from 'react';
import {Config, ComponentContextType} from './model';

export const Events = new EventEmitter();
export let ActiveBoxes = new Map<string, Config<any>>();

export function AddBox<C>(cfg: Config<C>) {
    ActiveBoxes = SafeUpdate(ActiveBoxes, {$add: [[cfg.selector, cfg]]});
}

export function RemoveBox(selector: string) {
    ActiveBoxes = SafeUpdate(ActiveBoxes, {$remove: [selector]});
}

export const ComponentContext = createContext<ComponentContextType>({
    selector: '',
});
