import moment from 'moment';
import * as r from 'runtypes';

export enum Weekday {
    'Sunday' = 0,
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
}

export enum AvailabilityType {
    'OpeningHours' = 1,
    'RecurringAppointment',
    'SpecificDate',
    'AlwaysOpen',
}

export const AvailabilitySettingsHolidaysRuntype = r.Record({
    start: r.String,
    end: r.String,
});
export type AvailabilitySettingsHolidays = r.Static<typeof AvailabilitySettingsHolidaysRuntype>;

export const AvailabilitySettingsHoursRuntype = r.Record({
    start: r.String,
    end: r.String,
    whole_day: r.Boolean,
    weekday: r.Union(r.Literal(1), r.Literal(2), r.Literal(3), r.Literal(4), r.Literal(5), r.Literal(6), r.Literal(0)),
    specific_date: r.String.Or(r.Null),
});
export type AvailabilitySettingsHours = Omit<r.Static<typeof AvailabilitySettingsHoursRuntype>, 'weekday'> & {
    weekday: Weekday;
};

export const AvailabilitySettingsRuntype = r.Record({
    id: r.Number,
    title: r.String,
    start_at: r.String,
    end_at: r.String,
    type_id: r.Number,
    is_weekly: r.Boolean,
    is_bi_weekly: r.Boolean,
    is_monthly: r.Boolean,
    week_number: r.Union(r.Literal(0), r.Literal(1), r.Literal(2), r.Literal(3), r.Literal(4)),
    hours: r.Array(AvailabilitySettingsHoursRuntype),
    holidays: r.Array(AvailabilitySettingsHolidaysRuntype),
});
export type AvailabilitySettings = Omit<r.Static<typeof AvailabilitySettingsRuntype>, 'type_id'> & {
    type_id: AvailabilityType;
};
export type AvailabilitySettingsMoment = Omit<AvailabilitySettings, 'start_at' | 'end_at' | 'holidays'> & {
    start_at: moment.Moment;
    end_at: moment.Moment;
    hours: AvailabilitySettingsHours[];
    holidays: Array<{
        start: moment.Moment;
        end: moment.Moment;
    }>;
};

export function AvailabilityToMoment(avil: AvailabilitySettings): AvailabilitySettingsMoment {
    return {
        ...avil,
        start_at: moment(avil.start_at, 'YYYY-MM-DD'),
        end_at: moment(avil.end_at, 'YYYY-MM-DD').endOf('day'),
        holidays: avil.holidays.map(holiday => ({
            start: moment(holiday.start, 'YYYY-MM-DD'),
            end: moment(holiday.end, 'YYYY-MM-DD').endOf('day'),
        })),
        hours: avil.hours,
    };
}

export const EmptyAvailabilityHours: AvailabilitySettingsHours = {
    start: '08:00:00',
    end: '20:00:00',
    weekday: 0,
    whole_day: false,
    specific_date: null,
};

export const EmptyAvailability = (): AvailabilitySettings => {
    return {
        id: 0,
        title: '',
        start_at: moment().format('YYYY-MM-DD'),
        end_at: moment().add(1, 'years').format('YYYY-MM-DD'),
        is_weekly: true,
        is_bi_weekly: false,
        is_monthly: false,
        week_number: 0,
        type_id: AvailabilityType.AlwaysOpen,
        hours: [],
        holidays: [],
    };
};
