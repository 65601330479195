import {CreateSheet, css, flushToStyleTag} from 'aphrodite';
import useTimeout from 'packages/hooks/useTimeout';
import {Children, createContext, PropsWithChildren, useContext, useEffect, useRef, useState} from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {CreateTransitionAnimation, Motion} from './CreateAnimation';
import {SmoothHeightWithIgnore} from './SmoothHeight.react';

/* 
    be carefull with "grow" property, it can slow down preformance
*/

type Settings = {
    active: number;
    grow?: boolean;
    className?: string;
    duration: number | {enter?: number; exit?: number};
    motion?: Motion;
};

const context = createContext<
    Omit<Settings, 'active'> & {
        LastHeight: number;
        SetLastHeight: (h: number) => void;
        init: boolean;
    }
>({
    init: true,
    duration: 300,
    motion: ['fade'],
    LastHeight: 0,
    grow: false,
    SetLastHeight: () => {},
});

export function SmoothSteper({active, className, children, ...rest}: PropsWithChildren<Settings>) {
    const ActiveStep = Children.toArray(children)[active];
    const init = useRef<number | false>(active);
    const [LastHeight, SetLastHeight] = useState(0);

    if (init.current !== active) {
        init.current = false;
    }

    return (
        <context.Provider {...{value: {...rest, LastHeight, SetLastHeight, init: init.current !== false}}}>
            <TransitionGroup className={`${className || ''} ${css(Styles.root)}`}>{ActiveStep}</TransitionGroup>
        </context.Provider>
    );
}

export function SmoothSteperItem({
    children,
    className,
    onClick,
    ...rest
}: React.PropsWithChildren<{
    className?: string;
    onClick?: (e: React.MouseEvent) => void;
    in?: boolean;
}>) {
    const ctx = useContext(context);
    const ref = useRef<HTMLDivElement>(null);
    const setLastheight = useRef(ctx.SetLastHeight);
    const [status] = useTimeout(1);

    useEffect(() => {
        if (ctx.init && ref.current) {
            flushToStyleTag();
            setLastheight.current(ref.current.offsetHeight);
        }
    }, [ctx.init]);

    return (
        <CSSTransition
            {...{
                nodeRef: ref,
                timeout: ctx.duration,
                classNames: `sl-animation`,
                ...rest,
            }}
        >
            <SmoothHeightWithIgnore
                {...{
                    ignore: !ctx.grow,
                    height: status || ctx.init ? 'auto' : ctx.LastHeight,
                    duration: ctx.init ? 1 : typeof ctx.duration === 'number' ? ctx.duration : ctx.duration.enter,
                    onGetNewHeight: height => {
                        if (rest.in && height) {
                            setLastheight.current(height);
                        }
                    },
                }}
            >
                <div
                    {...{
                        className: `${css(
                            CreateTransitionAnimation(ctx.motion, {
                                animationDuration: ctx.duration,
                            }),
                            Styles.item
                        )} ${className || ''}`,
                        onClick: onClick,
                        ref,
                    }}
                >
                    {children}
                </div>
            </SmoothHeightWithIgnore>
        </CSSTransition>
    );
}

const Styles = CreateSheet({
    root: {
        position: 'relative',
        overflow: 'hidden',
    },
    item: {
        '&.sl-animation-exit': {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
        },
    },
});
