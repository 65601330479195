import {
    TransProps,
    TFuncKey,
    Namespace,
    DefaultNamespace,
    KeyPrefix,
    TransChild,
    // @ts-ignore trans from react-i18next has an issue with prettier - https://github.com/prettier/prettier/issues/4223, this component fixes it
    Trans as I18BadTrans,
} from 'react-i18next';

export default function Tran1s<
    K extends TFuncKey<N, TKPrefix> extends infer A ? A : never,
    N extends Namespace = DefaultNamespace,
    TKPrefix extends KeyPrefix<N> = undefined,
    E = React.HTMLProps<HTMLDivElement>
>(props: TransProps<K, N, TKPrefix, E>) {
    let newChildren = props.children;

    if (Array.isArray(props.children)) {
        let fixedChildren: TransChild[] = [];
        if (Array.isArray(newChildren)) {
            // first add extra spaces to prev/next parts
            props.children.forEach(c => {
                fixedChildren.push(c);
            });

            fixedChildren.forEach((c, i) => {
                if (c === ' ') {
                    if (fixedChildren[i - 1] && typeof fixedChildren[i - 1] === 'string') {
                        fixedChildren[i - 1] = fixedChildren[i - 1] + ' ';
                    } else if (fixedChildren[i + 1] && typeof fixedChildren[i + 1] === 'string') {
                        fixedChildren[i + 1] = ' ' + fixedChildren[i + 1];
                    }
                }
            });

            // then remove extra spaces
            fixedChildren = fixedChildren.filter((c, i) => {
                if (Array.isArray(fixedChildren)) {
                    if (
                        c === ' ' &&
                        ((fixedChildren[i - 1] && typeof fixedChildren[i - 1] === 'string') ||
                            (fixedChildren[i + 1] && typeof fixedChildren[i + 1] === 'string'))
                    ) {
                        return false;
                    }
                }

                return true;
            });

            newChildren = fixedChildren;
        }
    }

    return <I18BadTrans {...{...props, children: newChildren}} />;
}
