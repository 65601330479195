import React from 'react';
import Trans from './Trans';
import {TransProps} from './model';

export default function UnescapedTrans(props: React.PropsWithChildren<TransProps>) {
    return (
        <Trans
            {...props}
            shouldUnescape={true}
        >
            {props.children}
        </Trans>
    );
}
