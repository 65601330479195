import {useLocalization} from 'packages/localization/localization';
import {css, CreateSheetWithTheme, useTheme, CSSProperties, GetColor} from 'aphrodite';
import {ButtonTheme} from 'packages/buttons/button.theme';
import {InputTheme} from 'packages/forms/Input';
import {LoadButton} from 'src/themes/button';
import {MakeInput} from 'src/themes/input';
import {useState} from 'react';
import useQueryParam from 'packages/hooks/useQueryParam';
import {SignUp} from 'packages/session/session';
import {history} from 'packages/history/history';
import SmoothErrorText from 'packages/motion/SmoothErrorText.react';
import PasswordInput from 'packages/forms/PasswordInput.react';
import SocialBtns from './SocialBtns.react';
import {CheckEmail} from 'packages/helpers/CheckEmail';
import {Danger, Dark, GrayLight} from 'src/themes/colors';
import SmoothLine from 'packages/motion/SmoothLine.react';
import RadioReact from 'packages/forms/Radio.react';
import {PrimaryRadio} from 'src/themes/radio';
import useSimpleError from 'packages/hooks/useSimpleError';

export default function SignUpReact({
    with_tour_operator,
    only_tour_operator,
    hide_socials,
    default_email,
    default_name,
    OnSignUp,
    theme,
    no_auto_focus,
}: {
    with_tour_operator?: boolean;
    only_tour_operator?: boolean;
    hide_socials?: boolean;
    default_email?: string;
    default_name?: string;
    OnSignUp?: (email: string) => void;
    theme: SignUpTheme;
    no_auto_focus?: boolean;
}) {
    const redirectUrl = useQueryParam('r')[0];
    const {t, tReady} = useLocalization('login');

    const Styles = useTheme(theme, StylesWithTheme);
    const [loading, setloading] = useState(false);
    const [email, setEmail] = useState(default_email || '');
    const [name, setName] = useState(default_name || '');
    const [password, setPassword] = useState('');
    const [accountType, setAccountType] = useState<'tourist' | 'tour-operator' | 'partner'>(
        with_tour_operator || only_tour_operator ? 'tour-operator' : 'tourist'
    );
    const [getErr, setErr] = useSimpleError<'email' | 'password' | 'name', string>();

    function onChangeType(value: 'tourist' | 'tour-operator' | 'partner') {
        setAccountType(value);
    }

    async function onSubmit(e: React.FormEvent) {
        e.preventDefault();

        if (email.length === 0) {
            setErr('email', t('errors.identReq'));
        } else if (password.length === 0) {
            setErr('password', t('errors.passwordReq'));
        } else if (password.length < 6) {
            setErr('password', t('errors.passwordMin'));
        } else if (accountType === 'tour-operator' && name.length === 0) {
            setErr('name', t('errors.companyNameReq'));
        } else if (!CheckEmail(email)) {
            setErr('email', t('errors.identNotEmail'));
        } else {
            setloading(true);
            const err = await SignUp({
                name: name,
                email: email,
                password: password,
                as_tour_operator: accountType === 'tour-operator',
            });
            setloading(false);

            if (err !== null) {
                setErr('email', err.text);
            } else {
                if (accountType === 'tour-operator') {
                    history.push(`/verification?id=${email}&r=${encodeURIComponent(`/for-tour-operators/success`)}`);
                } else if (OnSignUp) {
                    OnSignUp(email);
                } else {
                    history.push(`/verification?id=${email}${redirectUrl ? '&r=' + redirectUrl : ''}`);
                }
            }
        }
    }

    if (!tReady) {
        return null;
    }

    return (
        <form
            {...{
                method: 'POST',
                className: css(Styles.form, only_tour_operator && Styles.form_only_tour_operator),
                onSubmit: onSubmit,
            }}
        >
            <div className={css(Styles.mainPart)}>
                {with_tour_operator && (
                    <>
                        <div className={css(Styles.radioTitle)}>Sign up as:</div>
                        <RadioReact
                            className={css(Styles.radio)}
                            default_value={accountType}
                            theme={PrimaryRadio}
                            onChange={onChangeType}
                            labelClassName={css(Styles.radio_label)}
                            styles={{
                                checkcontainer: Styles.radio_container,
                                labelActive: Styles.radio_active,
                            }}
                            items={[
                                {
                                    value: 'tourist',
                                    label: 'Tourist',
                                },
                                {
                                    value: 'tour-operator',
                                    label: (
                                        <div className={css(Styles.tour_operator_select)}>
                                            <div className={css(Styles.tour_operator_select_text)}>Tour operator</div>
                                            <div className={css(Styles.tour_operator_select_label)}>
                                                {t('signUp.tour-operator-tooltip')}
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    value: 'partner',
                                    label: 'Partner',
                                },
                            ]}
                        />
                    </>
                )}
                <input
                    {...{
                        id: 'identifier',
                        type: 'text',
                        placeholder: t('inputs.email'),
                        autoFocus: !no_auto_focus,
                        required: true,
                        autoComplete: 'off',
                        value: email,
                        onChange: e => {
                            setErr('email', false);
                            setEmail(e.target.value);
                        },
                        className: css(
                            MakeInput(theme.input, {
                                fontSize: 16,
                                fontWeight: 400,
                                padding: '8px 16px',
                                width: '100%',
                                borderRadius: 12,
                                lineHeight: '28px',
                                error: Boolean(getErr('email')),
                            }),
                            Styles.input,
                            Styles.id_input
                        ),
                    }}
                />
                <SmoothLine
                    className={css(Styles.error)}
                    motion={['fade', 'swipe-right']}
                >
                    {getErr('email') ? getErr('email') : undefined}
                </SmoothLine>
                <input
                    {...{
                        id: 'full_name',
                        type: 'text',
                        placeholder:
                            accountType === 'tourist' || accountType === 'partner'
                                ? t('inputs.name')
                                : t('inputs.companyName'),
                        autoComplete: 'off',
                        value: name,
                        onChange: e => {
                            setErr('name', false);
                            setName(e.target.value);
                        },
                        className: css(
                            MakeInput(theme.input, {
                                fontSize: 16,
                                fontWeight: 400,
                                padding: '8px 16px',
                                width: '100%',
                                borderRadius: 12,
                                lineHeight: '28px',
                                error: Boolean(getErr('name')),
                            }),
                            Styles.input,
                            Styles.pass_input
                        ),
                    }}
                />
                <PasswordInput
                    {...{
                        showStrength: true,
                        inputProps: {
                            id: 'password',
                            type: 'password',
                            placeholder: t('inputs.password'),
                            required: true,
                            autoComplete: 'new-password',
                            value: password,
                            onChange: e => {
                                setErr('password', false);
                                setPassword(e.target.value.trim());
                            },
                            className: css(
                                MakeInput(theme.input, {
                                    fontSize: 16,
                                    fontWeight: 400,
                                    padding: '8px 16px',
                                    width: '100%',
                                    borderRadius: 12,
                                    lineHeight: '28px',
                                    error: Boolean(getErr('password')),
                                })
                            ),
                        },
                    }}
                />
                <SmoothErrorText
                    className={css(Styles.error)}
                    motion={['fade', 'swipe-right']}
                >
                    {getErr('password') || undefined}
                </SmoothErrorText>
                <LoadButton
                    {...{
                        type: 'submit',
                        disabled: email.trim().length === 0 || password.trim().length === 0,
                        loading: loading,
                        className: css(Styles.submit),
                        theme: theme.button,
                        layout: {
                            width: '100%',
                            padding: '15px 0',
                            fontSize: '1.1429rem',
                            lineHeight: 1,
                            fontWeight: 400,
                            whiteSpace: 'nowrap',
                            borderRadius: 13,
                        },
                    }}
                >
                    {t('signUp.btn')}
                </LoadButton>
                <div className={css(Styles.formFooterLinks)}>
                    By signing up you are agreeing to our{' '}
                    <span>
                        <a
                            className={css(Styles.formFooterLink)}
                            href="/terms"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Terms of Service
                        </a>{' '}
                        and{' '}
                        <a
                            className={css(Styles.formFooterLink)}
                            href="/privacy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Privacy Policy
                        </a>
                    </span>
                </div>
            </div>

            {!hide_socials && (
                <div className={css(Styles.formFooter)}>
                    <div className={css(Styles.divider)}>
                        <div className={css(Styles.line)} />
                        <div className={css(Styles.dividerText)}>or</div>
                        <div className={css(Styles.line)} />
                    </div>
                    <SocialBtns />
                </div>
            )}
        </form>
    );
}

export type SignUpTheme = {
    input: InputTheme;
    button: ButtonTheme;
    label_color: CSSProperties['color'];
};

const StylesWithTheme = CreateSheetWithTheme((theme?: SignUpTheme) => {
    return {
        form: {
            position: 'relative',
            padding: '0 20px',
            zIndex: 2,
            marginBottom: 0,
            fontSize: 14,
            fontWeight: 400,
        },
        form_only_tour_operator: {
            padding: 0,
        },
        mainPart: {
            flexGrow: 1,
        },
        input: {
            display: 'block',
            marginBottom: 15,
        },
        id_input: {
            marginBottom: 0,
        },
        pass_input: {
            marginTop: 20,
        },
        error: {
            display: 'block',
            padding: '10px 0 0 0',
            fontSize: '0.85714rem',
            color: Danger.default,
        },
        submit: {
            display: 'block',
            margin: '15px auto 0 auto',
        },
        social: {
            marginLeft: 60,
            '@media(max-width: 720px)': {
                position: 'relative',
                display: 'flex',
                margin: '50px 0 0 0',
                flexDirection: 'column',
                alignItems: 'center',
            },
        },
        formFooter: {
            marginTop: 30,
        },
        formFooterLinks: {
            marginTop: 10,
            color: '#000',
            textAlign: 'center',
            fontFamily: 'Montserrat',
            fontSize: 13,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '150%',
        },
        formFooterLink: {
            color: Dark.default,
            textDecoration: 'none',
            fontStyle: 'normal',
            fontWeight: 600,
            cursor: 'pointer',
            ':hover': {
                textDecoration: 'underline',
            },
        },
        divider: {
            margin: '20px 0',
            display: 'flex',
            alignItems: 'center',
            gap: 15,
        },
        line: {
            height: 1,
            width: '100%',
            background: GetColor('gray3'),
        },
        dividerText: {
            color: GrayLight.default,
            fontWeight: 500,
            fontSize: '1.143rem',
            lineHeight: '1.429rem',
            letterSpacing: '0.03em',
            textTransform: 'lowercase',
        },
        radioTitle: {
            marginBottom: 15,
            color: GetColor('supplementary2'),
            fontFamily: 'Montserrat',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal',
        },
        radio: {
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            marginBottom: 40,
        },
        radio_label: {
            padding: '14px 16px',
            flexGrow: 1,
            flexShrink: 0,
            fontWeight: 'bold',
            fontSize: '1.1429rem',
            lineHeight: '20px',
            borderRadius: 12,
            border: `2px solid ${GetColor('blockBg')}`,
            background: '#FFF',
        },
        radio_container: {
            alignItems: 'flex-start',
            transition: 'all 0.2s',
            '@media (min-width: 640px)': {
                ':hover': {
                    border: `2px solid ${GetColor('gray3')}`,
                },
            },
        },
        radio_active: {
            transition: 'all 0.2s',
            border: `2px solid #ED1664`,
            cursor: 'default',
            ':hover': {
                border: `2px solid #ED1664`,
            },
        },
        tour_operator_select: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        tour_operator_select_text: {},
        tour_operator_select_label: {
            color: GetColor('supplementary2'),
            fontFamily: 'Montserrat',
            fontSize: '0.9286rem',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal',
        },
        tour_operator_select_icon: {
            marginLeft: 5,
        },
    };
});
