import * as r from 'runtypes';

export const UserRuntype = r.Record({
    guid: r.String,
    email: r.String.Or(r.Null),
    phone: r.String.Or(r.Null),
    full_name: r.String.Or(r.Null),
});
export type User = r.Static<typeof UserRuntype>;

export const ConsoleUserRuntype = UserRuntype.extend({
    id: r.Number,
    created_at: r.Number,
    is_verified: r.Boolean,
    has_tour_operators: r.Boolean,
});
export type ConsoleUser = r.Static<typeof ConsoleUserRuntype>;
