import {RadioLayout, RadioTheme} from 'packages/forms/Radio.theme';
import {Partners, Primary, White} from './colors';
import {GetColor} from 'packages/aphrodite/aphrodite';

export const RadioDefaultayout: RadioLayout = {
    fontSize: 14,
    size: 20,
};

export const PrimaryRadio: RadioTheme = {
    after_checked_color: White.default,
    radio_background_color: 'white',
    radio_hover_background_color: '#f0f0f0',
    radio_checked_color: Primary.default,
    radio_border: `2px solid ${Primary.active}`,
};

export const PartnersRadio: RadioTheme = {
    after_checked_color: White.default,
    radio_background_color: '#eee',
    radio_hover_background_color: GetColor('gray2'),
    radio_checked_color: Partners.default,
    radio_border: `1px solid ${Partners.active}`,
};
