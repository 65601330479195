import {css, StyleDeclaration, CreateSheetWithTheme, useTheme} from 'aphrodite';
import {InterTheme, SpinnerTheme} from './spinner.theme';

export type {SpinnerTheme};

export type SpinnerProps = {
    theme: SpinnerTheme;
    height?: number | '100%';
    strokeWidth?: number;
    styles?: StyleDeclaration;
};

export default function Spinner({theme, height, strokeWidth, styles}: SpinnerProps) {
    const Styles = useTheme(
        {
            ...theme,
            height: height,
        },
        ThemeStyles
    );

    return (
        <div className={css(Styles.spinner, styles)}>
            <svg
                className={css(Styles.svg)}
                viewBox="25 25 50 50"
            >
                <circle
                    {...{
                        className: css(Styles.circle_path),
                        cx: 50,
                        cy: 50,
                        r: 20,
                        fill: 'none',
                        strokeWidth: strokeWidth ? strokeWidth : 2,
                        strokeMiterlimit: 10,
                    }}
                />
                <circle
                    {...{
                        className: css(Styles.circle),
                        cx: 50,
                        cy: 50,
                        r: 20,
                        fill: 'none',
                        strokeWidth: strokeWidth ? strokeWidth : 2,
                        strokeMiterlimit: 10,
                    }}
                />
            </svg>
        </div>
    );
}

const rotate = {
    '100%': {
        transform: 'rotate(360deg)',
    },
};

const dash = {
    '0%': {
        strokeDasharray: '1, 200',
        strokeDashoffset: 0,
    },
    '50%': {
        strokeDasharray: '89, 200',
        strokeDashoffset: -35,
    },
    '100%': {
        strokeDasharray: '89, 200',
        strokeDashoffset: -124,
    },
};

const ThemeStyles = CreateSheetWithTheme((theme?: InterTheme) => {
    return {
        spinner: {
            height: theme?.height ? theme?.height : undefined,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: theme?.height ? theme?.height : '100%',
            minWidth: theme?.height,
        },
        svg: {
            height: '100%',
            display: 'block',
        },
        circle: {
            animationTimingFunction: 'ease-in-out',
            animationName: [dash, rotate],
            animationDuration: '1.5s, 2s',
            animationIterationCount: 'infinite',
            transformBox: 'fill-box',
            transformOrigin: 'center center',
            height: '100%',
            width: '100%',
            strokeLinecap: 'round',
            stroke: theme?.color,
        },
        circle_path: {
            height: '100%',
            width: '100%',
            strokeLinecap: 'round',
            stroke: theme?.pathColor,
        },
    };
});
