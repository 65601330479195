import {css} from 'packages/aphrodite/aphrodite';
import React, {PropsWithChildren, useEffect, useRef, useState} from 'react';
import {CSSTransition} from 'react-transition-group';
import {CreateTransitionAnimation, Motion} from './CreateAnimation';

// show/hode error with motion
export default function SmoothErrorText({
    children,
    motion,
    timeout,
    ...rest
}: PropsWithChildren<{
    timeout?: number;
    motion?: Motion;
    className?: string;
    onClick?: (e: React.MouseEvent) => void;
}>) {
    const ref = useRef<HTMLSpanElement>(null);
    const err = useRef(children);
    const [, setReload] = useState(false);

    useEffect(() => {
        if (children !== undefined) {
            if (err.current !== undefined && err.current !== children) {
                err.current = children;
                setReload(re => !re);
            } else {
                err.current = children;
            }
        }
    }, [children]);

    return (
        <CSSTransition
            {...{
                timeout: timeout || 300,
                in: Boolean(children),
                unmountOnExit: true,
                nodeRef: ref,
                classNames: `sl-animation`,
                onExited: () => {
                    err.current = undefined;
                },
            }}
        >
            <span
                {...rest}
                {...{
                    ref,
                    className: `${css(CreateTransitionAnimation(motion, {animationDuration: timeout || 300}))} ${
                        rest.className || ''
                    }`,
                }}
            >
                {err.current}
            </span>
        </CSSTransition>
    );
}
