import * as runtypes from 'runtypes';
import {SessionTourOperatorItem, SessionTourOperatorItemRuntype} from 'packages/touroperators/models';
import {SuperUserFlagsRuntype} from 'generated/SuperUserFlags';
import {UnitsSystemRuntime} from 'packages/units/model';
import {PayoutFeesRuntype} from 'packages/payouts/model';

export const SessionUserRuntime = runtypes.Record({
    guid: runtypes.String,
    is_verified: runtypes.Boolean,
    primary_email: runtypes.String.Or(runtypes.Null),
    requested_email: runtypes.String.Or(runtypes.Null),
    primary_phone: runtypes.String.Or(runtypes.Null),
    requested_phone: runtypes.String.Or(runtypes.Null),
    full_name: runtypes.String,
    has_password: runtypes.Boolean,
    units_system: UnitsSystemRuntime,
});

export const SessionExtraRuntime = runtypes.Record({
    active_tour_operator_id: runtypes.Number,
    tour_operators: runtypes.Array(SessionTourOperatorItemRuntype),
    superuser_flags: SuperUserFlagsRuntype,
    is_superuser: runtypes.Boolean,
    su_login: runtypes.Optional(runtypes.Dictionary(runtypes.Unknown, runtypes.String)),
    default_fees: PayoutFeesRuntype,
    promo_fees: PayoutFeesRuntype,
    paid_countries: runtypes.Array(runtypes.String),
});

export const SessionRuntime = runtypes.Record({
    user: SessionUserRuntime,
    extra: SessionExtraRuntime,
});

export type SessionUser = runtypes.Static<typeof SessionUserRuntime>;
export type SessionExtra = runtypes.Static<typeof SessionExtraRuntime>;
export type SessionRaw = runtypes.Static<typeof SessionRuntime>;
export type Session = runtypes.Static<typeof SessionRuntime> & {
    Reload: () => Promise<void>;
    UpdateUserFields: (data: Partial<SessionUser>) => void;
    GetActiveTourOperator: () => SessionTourOperatorItem;
    UpdateUserTourOperators: (items: SessionTourOperatorItem[]) => void;
    ChangeActiveTourOperator: (tour_operator_id: number) => void;
    UpdateActiveTourOperator: (data: SessionTourOperatorItem) => void;
};
