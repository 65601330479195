import {useLocalization} from 'packages/localization/localization';
import {OpenPage} from 'packages/history/history';
// icons
import {ReactComponent as Logo} from './logo.svg';
import {ReactComponent as WhatsappIcon} from 'src/icons/whatsapp.svg';
import {ReactComponent as MailIcon} from 'src/icons/mail.svg';
import {ReactComponent as InstagramIcon} from 'src/icons/instagram.svg';
import {ReactComponent as FacebookIcon} from 'src/icons/facebook.svg';
import {ReactComponent as YoutubeIcon} from 'src/icons/youtube.svg';
import {ReactComponent as ArrowIcon} from 'src/icons/arrow.svg';
import {ReactComponent as ArrowUp} from 'src/icons/arrow-up.svg';
import {ReactComponent as FooterIcon} from 'src/icons/footer.svg';
// styles
import {css, CreateSheet} from 'aphrodite';
import {Dark, Gray} from 'src/themes/colors';
import AppsLinks from 'packages/appsLinks/appsLinks.react';
import Config from 'src/config.json';

interface Props {
    showWave?: boolean;
}

export default function PageFooter({showWave}: Props) {
    const {t, tReady} = useLocalization('footer');
    const scrollUp = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };

    if (!tReady) {
        return null;
    }

    return (
        <>
            {showWave && <FooterIcon className={css(Styles.footerIcon)} />}
            <div className={css(Styles.footer)}>
                <div className={css(Styles.container)}>
                    <a
                        {...{
                            href: '/',
                            className: css(Styles.logo),
                            'aria-label': 'DGtalguide',
                            onClick: OpenPage(''),
                        }}
                    >
                        <Logo
                            className={css(Styles.logoIcon)}
                            width="217"
                            height="50"
                        />
                    </a>
                    <div className={css(Styles.row)}>
                        <div className={css(Styles.menuRow)}>
                            <div className={css(Styles.col, Styles.menuCol)}>
                                <ul className={css(Styles.nav)}>
                                    <li className={css(Styles.navItem)}>
                                        <a
                                            className={css(Styles.navItemLink)}
                                            href="/"
                                            onClick={OpenPage('')}
                                        >
                                            {t('links.home')}
                                            <ArrowIcon
                                                width="22"
                                                height="16"
                                            />
                                        </a>
                                    </li>
                                    <li className={css(Styles.navItem)}>
                                        <a
                                            className={css(Styles.navItemLink)}
                                            href="/app-tutorial"
                                            onClick={OpenPage('app-tutorial')}
                                        >
                                            {t('links.app-tutorial')}
                                            <ArrowIcon
                                                width="22"
                                                height="16"
                                            />
                                        </a>
                                    </li>
                                    <li className={css(Styles.navItem)}>
                                        <a
                                            className={css(Styles.navItemLink)}
                                            href="/help"
                                            onClick={OpenPage('help')}
                                        >
                                            {t('links.help')}
                                            <ArrowIcon
                                                width="22"
                                                height="16"
                                            />
                                        </a>
                                    </li>
                                    <li className={css(Styles.navItem)}>
                                        <a
                                            className={css(Styles.navItemLink)}
                                            href="/releases"
                                            onClick={OpenPage('releases')}
                                        >
                                            {t('links.releases')}
                                            <ArrowIcon
                                                width="22"
                                                height="16"
                                            />
                                        </a>
                                    </li>
                                    <li className={css(Styles.navItem)}>
                                        <a
                                            className={css(Styles.navItemLink)}
                                            href="/demo"
                                            onClick={OpenPage('demo')}
                                        >
                                            {t('links.demo-request')}
                                            <ArrowIcon
                                                width="22"
                                                height="16"
                                            />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className={css(Styles.col, Styles.menuCol)}>
                                <ul className={css(Styles.nav)}>
                                    <li className={css(Styles.navItem)}>
                                        <a
                                            className={css(Styles.navItemLink)}
                                            href="/legal-notice"
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            {t('links.legal')}
                                            <ArrowIcon
                                                width="22"
                                                height="16"
                                            />
                                        </a>
                                    </li>
                                    <li className={css(Styles.navItem)}>
                                        <a
                                            className={css(Styles.navItemLink)}
                                            href="/privacy"
                                            onClick={OpenPage('privacy')}
                                        >
                                            {t('links.privacy')}
                                            <ArrowIcon
                                                width="22"
                                                height="16"
                                            />
                                        </a>
                                    </li>
                                    <li className={css(Styles.navItem)}>
                                        <a
                                            className={css(Styles.navItemLink)}
                                            href="/terms"
                                            rel="noreferrer"
                                            target="_blank"
                                            onClick={OpenPage('terms')}
                                        >
                                            {t('links.terms')}
                                            <ArrowIcon
                                                width="22"
                                                height="16"
                                            />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className={css(Styles.col, Styles.arrowCol)}>
                                <div
                                    className={css(Styles.arrowUp)}
                                    onClick={scrollUp}
                                >
                                    <ArrowUp
                                        width="20"
                                        height="40"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={css(Styles.col, Styles.contactCol)}>
                            <ul className={css(Styles.nav)}>
                                <li className={css(Styles.navItem, Styles.contactNavItem)}>
                                    <WhatsappIcon
                                        className={css(Styles.contactNavItemIcon)}
                                        width="35"
                                        height="35"
                                    />
                                    <a
                                        className={css(Styles.contactNavItemLink)}
                                        href="https://wa.me/393518104545"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        +39 351 810 45 45
                                    </a>
                                </li>
                                <li className={css(Styles.navItem, Styles.contactNavItem)}>
                                    <MailIcon
                                        className={css(Styles.contactNavItemIcon)}
                                        width="35"
                                        height="35"
                                    />
                                    <a
                                        className={css(Styles.contactNavItemLink)}
                                        href={`mailto:${Config.email_addresses.hello}`}
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        hello@dgtalguide.com
                                    </a>
                                </li>
                            </ul>
                            <ul className={css(Styles.social)}>
                                <li className={css(Styles.socialItem)}>
                                    <a
                                        {...{
                                            className: css(Styles.socialItemLink),
                                            href: 'https://www.instagram.com/dgtalguide/',
                                            target: '_blank',
                                            rel: 'noopener noreferrer',
                                            'aria-label': '@dgtalguide',
                                        }}
                                    >
                                        <InstagramIcon
                                            className={css(Styles.socialItemIcon)}
                                            width="30"
                                            height="30"
                                        />
                                    </a>
                                </li>
                                <li className={css(Styles.socialItem)}>
                                    <a
                                        {...{
                                            className: css(Styles.socialItemLink),
                                            href: 'https://www.facebook.com/dgtalguide',
                                            target: '_blank',
                                            rel: 'noopener noreferrer',
                                            'aria-label': 'Facebook',
                                        }}
                                    >
                                        <FacebookIcon
                                            className={css(Styles.socialItemIcon)}
                                            width="30"
                                            height="30"
                                        />
                                    </a>
                                </li>
                                <li className={css(Styles.socialItem)}>
                                    <a
                                        {...{
                                            className: css(Styles.socialItemLink),
                                            href: 'https://www.youtube.com/channel/UCwHDEStxIQ3G27mMIMJyoNA',
                                            target: '_blank',
                                            rel: 'noopener noreferrer',
                                            'aria-label': 'Youtube',
                                        }}
                                    >
                                        <YoutubeIcon
                                            className={css(Styles.socialItemIcon)}
                                            width="30"
                                            height="30"
                                        />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <AppsLinks
                        {...{
                            styles: {
                                parent: Styles.links,
                                link: Styles.linksImg,
                            },
                        }}
                    />
                    <div className={css(Styles.copyright)}>
                        <p className={css(Styles.copyrightText)}>
                            {t('copyright')} © {new Date().getFullYear()} DGtalguide Inc.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

const Styles = CreateSheet({
    footer: {
        padding: '30px 0 50px 0',
        color: '#fff',
        background: Dark.default,
    },
    container: {
        width: '100%',
        maxWidth: 1240,
        margin: '0 auto',
        padding: '0 20px',
    },
    logo: {
        display: 'inline-block',
        marginBottom: 50,
    },
    logoIcon: {
        display: 'block',
    },
    row: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row-reverse',
        '@media(max-width: 810px)': {
            display: 'block',
        },
    },
    menuRow: {
        display: 'flex',
        flexWrap: 'wrap',
        flexGrow: 1,
        justifyContent: 'flex-end',
        '@media(max-width: 810px)': {
            marginBottom: 50,
            justifyContent: 'flex-start',
        },
        '@media(max-width: 580px)': {
            justifyContent: 'flex-start',
        },
    },
    col: {
        margin: 0,
    },
    menuCol: {
        marginRight: 50,
    },
    contactCol: {
        marginRight: 160,
        flexGrow: 0,
        '@media(max-width: 1080px)': {
            marginRight: 50,
        },
    },
    nav: {
        display: 'block',
        margin: 0,
        padding: 0,
        listStyle: 'none',
    },
    navItem: {
        width: 240,
        marginBottom: 15,
        fontSize: '1.1429rem',
        fontWeight: 600,
        color: '#fff',
        transition: 'color .5s ease-out',
        ':nth-child(1n) svg': {
            transition: 'fill .5s ease-out',
        },
        ':hover': {
            color: Gray.default,
            ':nth-child(1n) svg': {
                fill: Gray.default,
            },
        },
        '@media(max-width: 965px)': {
            width: 200,
            fontSize: '1rem',
        },
    },
    contactNavItem: {
        display: 'flex',
        width: 'auto',
        fontSize: '1.45rem',
        alignItems: 'center',
        '@media(max-width: 965px)': {
            fontSize: '1rem',
        },
    },
    contactNavItemIcon: {
        display: 'block',
        marginRight: 10,
        fill: '#fff',
        '@media(max-width: 965px)': {
            width: 24,
            heigth: 24,
        },
    },
    contactNavItemLink: {
        color: 'inherit',
        textDecoration: 'none',
    },
    social: {
        display: 'flex',
        margin: '40px -25px 0 -15px',
        padding: 0,
        alignItems: 'center',
        listStyle: 'none',
    },
    socialItem: {
        margin: '0 15px',
    },
    socialItemLink: {
        display: 'block',
    },
    socialItemIcon: {
        display: 'block',
        fill: '#fff',
        transform: 'scale(1)',
        transition: 'all .5s ease-out',
        ':hover': {
            fill: Gray.default,
            transform: 'scale(1.1)',
        },
        '@media(max-width: 965px)': {
            width: 24,
            heigth: 24,
        },
    },
    navItemLink: {
        display: 'flex',
        color: Gray.default,
        alignItems: 'center',
        justifyContent: 'flex-start',
        textDecoration: 'none',
        transition: 'color .3s ease-out',
        ':nth-child(1n) svg': {
            display: 'block',
            opacity: 0,
            transform: 'translateX(0px)',
            transition: 'opacity .2s ease-out, transform .25s ease-out',
        },
        ':nth-child(1n) path': {
            stroke: '#fff',
        },
        ':hover': {
            color: '#fff',
            ':nth-child(1n) svg': {
                transform: 'translateX(30px)',
                opacity: 1,
            },
        },
    },
    copyright: {
        marginTop: 40,
        fontSize: '1rem',
        fontWeight: 500,
        color: 'rgba(255, 255, 255, 0.3)',
        textAlign: 'center',
    },
    copyrightText: {
        margin: 0,
    },
    arrowCol: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexGrow: 0,
        '@media(max-width: 810px)': {
            flexGrow: 1,
        },
        '@media(max-width: 580px)': {
            display: 'none',
        },
    },
    arrowUp: {
        display: 'block',
        margin: '0 auto',
        cursor: 'pointer',
        ':nth-child(1n) path': {
            strokeOpacity: 0.7,
            stroke: '#fff',
        },
        '@media(max-width: 810px)': {
            margin: 0,
        },
    },
    footerIcon: {
        display: 'block',
        width: '100%',
        height: 'auto',
        fill: Dark.default,
        marginBottom: -1,
    },
    links: {
        display: 'flex',
        margin: '50px 0 0 0',
        padding: 0,
        listStyle: 'none',
    },
    linksImg: {
        display: 'block',
        marginRight: 15,
    },
});
