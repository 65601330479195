import update from 'immutability-helper';
import languagesInit from './languages.json';

type Language = {
    code: string;
    name: string;
    enabled: boolean;
    loaded: boolean;
    inMenu: boolean;
};
export type Languages = Language[];

// IMPORTANT: English has to be always enabled
let languages: Languages = languagesInit;

const getEnabledLanguages = (): string[] => {
    return languages.reduce<string[]>((accumulator: string[], current: Language) => {
        if (current.enabled) accumulator.push(current.code);
        return accumulator;
    }, []);
};

const getLoadedLanguages = (): string[] => {
    return languages.reduce<string[]>((accumulator: string[], current: Language) => {
        if (current.loaded) accumulator.push(current.code);
        return accumulator;
    }, []);
};

const setLoadedLanguage = (code: string) => {
    const index: number = languages.findIndex((language: Language) => language.code === code);
    languages = update<Language[]>(languages, {[index]: {loaded: {$set: true}}});
};

export {languages, getEnabledLanguages, getLoadedLanguages, setLoadedLanguage};
