import {useRef, useSyncExternalStore, FC} from 'react';
import {InitStore, Store} from './store';
import {Executer, State} from './model';
import suspensible, {SuspenseProps} from './suspensible';
import {delayExecution, logging} from './utils';

export type Options<Payload> = {
    executerName?: string;
    initialState?: Payload;
    listenArgs?: boolean;
    onError?: Error;
};

const registry = new FinalizationRegistry(({uuid, executer}: {uuid: string; executer: string}) => {
    logging(uuid, executer, 'destored');
});

export function useDetached<Payload, Args extends ReadonlyArray<unknown>>(
    executer: Executer<Payload, Args>,
    options: Options<Payload>,
    ...args: Args
): {
    state: Readonly<State<Payload>>;
    execute: (...args: Args) => Promise<Payload | undefined>;
    setPayload: (payload: Payload | undefined) => void;
    Suspense: FC<SuspenseProps>;
} {
    const ref = useRef<{store: Store<Payload, Args>}>();
    if (ref.current === undefined) {
        const store = InitStore<Payload, Args>({
            executer,
            args,
            initialPayload: options.initialState,
            executerName: options.executerName,
            executeOnInit: options.listenArgs !== false,
        });
        registry.register(store, {uuid: store.getInternalState().uuid, executer: executer.name});
        ref.current = {store};
    }

    const state = useSyncExternalStore(
        ref.current.store.subscribe,
        ref.current.store.getState,
        ref.current.store.getServerState
    );

    if (options.listenArgs !== false) {
        let oldArgs = ref.current.store.getInternalState().args;
        let hasChanged = true;

        if (oldArgs) {
            hasChanged = args.some((d, index) => !Object.is(d, oldArgs[index]));
        }
        if (hasChanged) {
            delayExecution(ref.current!.store.onArgsChanged, ...args);
        }
    }

    function setPayload(payload: Payload | undefined) {
        ref.current!.store.setPayload(payload);
    }

    return {
        state,
        execute: ref.current.store.execute,
        setPayload,
        Suspense: suspensible({
            shouldSuspense: ref.current.store.getState().isLoading,
            hasPayload: Boolean(ref.current.store.getState().payload),
        }),
    };
}
