import {useLocalization} from 'packages/localization/localization';
import ReactCodeInput from 'packages/forms/CodeInput.react';
import {css, CreateSheetWithTheme, useTheme, CSSProperties} from 'aphrodite';
import {ButtonTheme} from 'packages/buttons/button.theme';
import {InputTheme} from 'packages/forms/Input';
import {ErrorData} from 'packages/errors/errors';
import {useState} from 'react';
import {DefaultLayout} from 'src/themes/code_input';
import {history, OpenPage} from 'packages/history/history';
import {session, VerifyRequestedEmailOrPhone} from 'packages/session/session';
import {LoadButton} from 'src/themes/button';
import {ResendEmailVerificationAPI} from 'packages/session/API/ResendEmailVerificationAPI';
import {FormStyles} from 'src/themes/form';
import SmoothLine from 'packages/motion/SmoothLine.react';
import {ResendPhoneVerificationAPI} from 'packages/session/API/ResendPhoneVerificationAPI';
import {Primary} from 'src/themes/colors';
import {CheckEmail} from 'packages/helpers/CheckEmail';
import useQueryParam from 'packages/hooks/useQueryParam';

export default function Verification({
    theme,
    origin,
    is_phone,
    change_identifier,
    OnVerify,
}: {
    theme: VerificationTheme;
    origin?: string;
    is_phone?: boolean;
    change_identifier?: () => void;
    OnVerify?: () => void;
}) {
    const {t, tReady} = useLocalization('login');
    const requestIdentifier = useQueryParam('id')[0];
    const redirectUrl = useQueryParam('r')[0];
    const isGetPhone = !CheckEmail(requestIdentifier);
    if (is_phone === undefined) {
        is_phone = isGetPhone;
    }
    
    const Styles = useTheme(theme, StylesWithTheme);

    const [Loading, SetLoading] = useState(false);
    const [Err, SetErr] = useState<ErrorData>();

    async function OnChangeCode(code: string) {
        SetErr(undefined);

        if (code.length === 6) {
            SetLoading(true);
            const err = await VerifyRequestedEmailOrPhone(code, !!is_phone, requestIdentifier, redirectUrl.includes('/for-tour-operators/success'));
            SetLoading(false);

            if (err !== null) {
                SetErr(err);
            } else {
                if (OnVerify) {
                    OnVerify();
                } else {
                    if (redirectUrl) {
                        history.push(redirectUrl);
                    } else {
                        history.push(origin || '/');
                    }
                }
            }
        }
    }

    async function ResendCode(e: React.MouseEvent) {
        e.preventDefault();
        e.stopPropagation();

        SetErr(undefined);

        SetLoading(true);
        const err = !!is_phone ? await ResendPhoneVerificationAPI() : await ResendEmailVerificationAPI();
        SetLoading(false);

        if (err !== null) {
            SetErr(err);
        }
    }

    const identifier = !!is_phone
        ? session.user.requested_phone || t('verification.phone')
        : session.user.requested_email || t('verification.email');

    if (!tReady) {
        return null;
    }

    return (
        <section
            {...{
                className: css(FormStyles.form),
            }}
        >
            <div
                {...{
                    htmlFor: 'email',
                    className: css(Styles.label),
                }}
            >
                {t('verification.sent')} <strong>{identifier}</strong>
            </div>
            <div className={css(Styles.code)}>
                <ReactCodeInput
                    {...{
                        inputMode: 'numeric',
                        fields: 6,
                        inputLayout: {
                            ...DefaultLayout,
                            ...{
                                width: 45,
                            },
                        },
                        inputTheme: theme.input,
                        onChange: OnChangeCode,
                        disabled: Loading,
                    }}
                />
            </div>
            <div className={css(Styles.error)}>
                <SmoothLine motion={['fade', 'swipe-up']}>{Err?.text}</SmoothLine>
            </div>
            <footer className={css(Styles.footer)}>
                <LoadButton
                    {...{
                        styles: Styles.footer_link,
                        theme: theme.resend_buttom,
                        loading: Loading,
                        onClick: ResendCode,
                    }}
                >
                    {t('verification.resend')}
                </LoadButton>
                <LoadButton
                    {...{
                        styles: Styles.footer_link,
                        theme: theme.resend_buttom,
                        loading: false,
                        onClick: e => {
                            if (change_identifier !== undefined) {
                                change_identifier();
                            } else {
                                OpenPage('join')(e);
                            }
                        },
                    }}
                >
                    {!!is_phone ? t('verification.changePhone') : t('verification.changeEmail')}
                </LoadButton>
            </footer>
        </section>
    );
}

export type VerificationTheme = {
    input: InputTheme;
    button: ButtonTheme;
    resend_buttom: ButtonTheme;
    label_color: CSSProperties['color'];
};

const StylesWithTheme = CreateSheetWithTheme((theme?: VerificationTheme) => {
    return {
        label: {
            color: theme?.label_color,
            fontWeight: 500,
            fontSize: '1rem',
            fontHeight: '1.214rem',
            marginBottom: 25,
            textAlign: 'center',
        },
        error: {
            display: 'flex',
            justifyContent: 'center',
            fontSize: '0.85714rem',
            color: Primary.default,
            textAlign: 'center',
            fontWeight: 600,
            height: 28,
            paddingTop: 10,
        },
        code: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        submit: {
            display: 'block',
            marginTop: 20,
            marginBottom: 20,
        },
        footer: {
            paddingTop: 30,
            fontSize: '1.2857rem',
            fontWeight: 500,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        footer_link: {
            textDecoration: 'none',
            color: Primary.default,
            ':hover': {
                textDecoration: 'underline',
            },
        },
    };
});
