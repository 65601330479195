import {CurrencyDataRuntype} from 'packages/currency/model';
import * as r from 'runtypes';

export const PayoutFeesRuntype = r.Record({
    ticket_fee: r.Number,
    online_fixed_fee: r.Number,
    online_percent_fee: r.Number,
    late_online_fee: r.Boolean,
    subscription_price: r.Number,
});
export type PayoutFees = r.Static<typeof PayoutFeesRuntype>;

export const PayoutRuntype = r.Record({
    id: r.Number,
    amount: r.Number,
    currency: CurrencyDataRuntype,
    created_at: r.Number,
    status: r.String,
});
export type Payout = r.Static<typeof PayoutRuntype>;
