import {MakeAnimation} from 'packages/aphrodite/aphrodite';

const pulsating = MakeAnimation({
    '0%': {
        transform: 'scale(1)',
    },
    '50%': {
        transform: 'scale(1.2)',
    },
    '100%': {
        transform: 'scale(1)',
    },
});

export default pulsating;
