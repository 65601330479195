import {ResourceRuntype} from 'packages/resources/models';
import {Array, Record, String, Number, Static, Null, Boolean} from 'runtypes';

export enum RouteTypeID {
    RouteCar = 1,
    RouteBike,
    RouteWalk,
    RouteTransport,
    RouteDashedLine,
}

let RouteTypes: {id: RouteTypeID; name: string}[] = [];
Object.entries(RouteTypeID).forEach(([name, id]) => {
    if (typeof id !== 'string') {
        RouteTypes.push({
            id: id,
            name: name.substr(5),
        });
    }
});
export {RouteTypes};

export const GeoPointRuntime = Record({
    lat: Number,
    lng: Number,
});

export const RouteSettingRuntype = Record({
    route_id: Number,
    avg_speed: Number,
    stay_duration: Number,
});
export type RouteSetting = Static<typeof RouteSettingRuntype>;

export const RoutesSettingsRuntype = Array(RouteSettingRuntype);
export type RoutesSettings = Static<typeof RoutesSettingsRuntype>;

export type GeoPoint = Static<typeof GeoPointRuntime>;

export const RoutePointRuntime = Record({
    geo_point: GeoPointRuntime,
    resource: ResourceRuntype,
});

export type RoutePoint = Static<typeof RoutePointRuntime>;

export const RouteRuntime = Record({
    id: Number,
    guid: String,
    product_id: Number,
    point: RoutePointRuntime,
    next_point: RoutePointRuntime.Or(Null),
    parent_guid: String.Or(Null),
    next_guid: String.Or(Null),
    avg_speed: Number,
    route_type_id: Number,
    path: String,
    stay_duration: Number,
    distance: Number,
    is_service_route: Boolean,
});

export type Route = Omit<Static<typeof RouteRuntime>, 'route_type_id'> & {route_type_id: RouteTypeID};

export const RoutesRuntime = Record({
    routes: Array(RouteRuntime),
    start_times: Array(String),
});
export type Routes = {
    routes: Route[];
    start_times: string[];
};

export function SortRoutes(routes: Route[]): [Route[], Map<string, Route[]>] {
    const sortedRoutes: Route[] = [];
    let points = new Map<string, Route[]>(),
        byNextMap = new Map<string | null, Route>(),
        totalRoutes = 0;

    if (routes.length === 0) {
        return [sortedRoutes, points];
    }

    routes.forEach(route => {
        if (route.parent_guid !== null) {
            let list = points.get(route.parent_guid);
            points.set(route.parent_guid, list ? [...list, {...route}] : [{...route}]);
        } else {
            byNextMap.set(route.next_guid, route);
            totalRoutes++;
        }
    });

    if (totalRoutes > 0) {
        let nextGUID: null | string = null,
            count = 0;
        while (byNextMap.has(nextGUID)) {
            let item = byNextMap.get(nextGUID);
            if (item) {
                count++;
                if (count > totalRoutes) {
                    alert(`bad routes order, contact support. #1, product: ${item.product_id}`);
                    break;
                }
                sortedRoutes.push(item);
                nextGUID = item.guid;
            } else {
                alert(`bad routes order, contact support. #2 product: ${routes[0].product_id}`);
                break;
            }
        }
    }

    return [sortedRoutes.reverse(), points];
}
