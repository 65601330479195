import i18next from 'i18next';
import Dropdown from 'packages/dropdowns/dropdownBase.react';
import {OpenLink, OpenPage} from 'packages/history/history';
import {useLocalization} from 'packages/localization/localization';
import {SignOut, session} from 'packages/session/session';
import React, {useEffect, useRef, useState} from 'react';
import Logo from '../logo/Logo.react';
// icons
import {ReactComponent as CrossIcon} from 'src/icons/cross.svg';
import {ReactComponent as LanguageIcon} from 'src/icons/language.svg';
import {ReactComponent as LikeIcon} from 'src/icons/like.svg';
import {ReactComponent as Menu} from 'src/icons/menu.svg';
import {ReactComponent as UserIcon} from 'src/icons/user.svg';
// styles
import {CreateSheet, css} from 'aphrodite';
// import AppsToast from 'packages/appsLinks/appsToast.react';
import {languages} from 'packages/localization/languages';
import {changeLanguage} from 'packages/localization/localization';
import {FrontTransparent, MakeButton, PrimaryFilled, WhiteTransparent} from 'src/themes/button';
import {Dark, Primary} from 'src/themes/colors';
import {Sizes} from 'src/themes/layouts';

export default function PageHeader({
    active,
    fixed,
    transparent,
    hideFavouritesControl,
}: {
    active?: string;
    fixed?: boolean;
    transparent?: boolean;
    hideFavouritesControl?: boolean;
}) {
    const {t, tReady} = useLocalization('header');
    const [mobileMenu, setMobileMenu] = useState<boolean>(false);
    const [hovered, setHovered] = useState<boolean>(false);

    const openMobileMenu = () => {
        setMobileMenu(true);
    };

    const closeMobileMenu = () => {
        setMobileMenu(false);
    };

    const openManager = (e: React.MouseEvent) => {
        // tmp fix
        if (session.extra.tour_operators.length === 0) {
            OpenPage('create')(e);
        } else {
            OpenPage('manager')(e);
        }
    };

    if (!tReady) {
        return null;
    }

    return (
        <div
            className={css(Styles.header, fixed && Styles.fixed, transparent && !hovered && Styles.transparent)}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <div className={css(Styles.container)}>
                <div
                    className={css(
                        Styles.col,
                        Styles.left,
                        Styles.logo,
                        transparent && !hovered && Styles.logoTransparent
                    )}
                >
                    <Logo />
                </div>
                <div className={css(Styles.col, Styles.headerNav)}>
                    <MenuList
                        active={active}
                        transparent={transparent}
                        hovered={hovered}
                    />
                </div>
                <div className={css(Styles.col, Styles.right)}>
                    <div className={css(Styles.language)}>
                        <LanguagesMenu
                            transparent={transparent}
                            hovered={hovered}
                        />
                    </div>
                    {session.user.is_verified && !hideFavouritesControl ? (
                        <div
                            className={css(Styles.menuIconItem, Styles.menuLike)}
                            onClick={OpenPage('favorites')}
                        >
                            <LikeIcon
                                className={css(Styles.menuIconItemIcon, Styles.likeIcon)}
                                width="17"
                                height="17"
                            />
                        </div>
                    ) : null}
                    {!session.user.is_verified ? (
                        <a
                            {...{
                                className: css(
                                    MakeButton(transparent && !hovered ? WhiteTransparent : FrontTransparent, {
                                        padding: '10px 30px',
                                        fontSize: '1.05rem',
                                        lineHeight: 1,
                                        fontWeight: 600,
                                        whiteSpace: 'nowrap',
                                        borderRadius: 10,
                                        border: '2px solid',
                                    }),
                                    Styles.menuBtn
                                ),
                                onClick: e => {
                                    e.preventDefault();
                                    if (window.location.pathname !== '/login') {
                                        let origin = '';
                                        if (window.location.pathname.length > 0 && window.location.pathname !== '/') {
                                            origin = window.location.pathname + window.location.search;
                                        }
                                        OpenPage(`login?${origin ? `origin=${encodeURIComponent(origin)}` : ''}`)(e);
                                    }
                                },
                                href: '/login',
                            }}
                        >
                            {t('links.signIn')}
                        </a>
                    ) : null}
                    {!session.user.is_verified ? (
                        <a
                            {...{
                                className: css(
                                    MakeButton(PrimaryFilled, {
                                        padding: '12px 30px',
                                        fontSize: '1.05rem',
                                        lineHeight: 1,
                                        fontWeight: 600,
                                        whiteSpace: 'nowrap',
                                        borderRadius: 10,
                                        border: 0,
                                    }),
                                    Styles.menuBtn,
                                    Styles.signUp
                                ),
                                onClick: OpenPage('join'),
                                href: '/join',
                            }}
                        >
                            {t('links.signUp')}
                        </a>
                    ) : null}
                    {session.user.is_verified && (
                        <div className={css(Styles.userMenu)}>
                            <Dropdown
                                {...{
                                    animation: 'fade',
                                    header: () => (
                                        <UserIcon
                                            className={css(
                                                Styles.avatar,
                                                transparent && !hovered && Styles.avatarTransparent
                                            )}
                                        />
                                    ),
                                    dropElement: () => (
                                        <ul className={css(Styles.dropdownActions)}>
                                            <li
                                                {...{
                                                    className: css(Styles.dropdownAction),
                                                    onClick: OpenPage('profile'),
                                                }}
                                            >
                                                {t('user.settings')}
                                            </li>
                                            <li
                                                {...{
                                                    className: css(Styles.dropdownAction),
                                                    onClick: OpenPage('favorites'),
                                                }}
                                            >
                                                {t('user.favorites')}
                                            </li>
                                            {session.extra.active_tour_operator_id > 0 && (
                                                <li
                                                    {...{
                                                        className: css(Styles.dropdownAction),
                                                        onClick: openManager,
                                                    }}
                                                >
                                                    {t('user.manager')}
                                                </li>
                                            )}
                                            {session.extra.is_superuser && (
                                                <li className={css(Styles.profileDivider)} />
                                            )}
                                            {session.extra.is_superuser && (
                                                <li
                                                    {...{
                                                        className: css(Styles.dropdownAction),
                                                        onClick: OpenPage('console'),
                                                    }}
                                                >
                                                    {t('user.console')}
                                                </li>
                                            )}
                                            <li className={css(Styles.profileDivider)} />
                                            <li
                                                {...{
                                                    className: css(Styles.dropdownAction),
                                                    onClick: SignOut,
                                                }}
                                            >
                                                {t('user.signOut')}
                                            </li>
                                        </ul>
                                    ),
                                }}
                            />
                        </div>
                    )}
                    <div
                        className={css(Styles.menuIconItem, Styles.mobileMenuToggle)}
                        onClick={openMobileMenu}
                    >
                        <Menu
                            className={css(
                                Styles.menuIconItemIcon,
                                Styles.mobileMenuIcon,
                                transparent && !hovered && Styles.mobileMenuIconTransparent
                            )}
                            width="17"
                            height="17"
                        />
                    </div>
                    <MenuMobile
                        visible={mobileMenu}
                        close={closeMobileMenu}
                    />
                </div>
            </div>
            {/* <AppsToast /> */}
        </div>
    );
}

interface menuProps {
    visible: boolean;
    close: () => void;
}

const MenuMobile = (props: menuProps) => {
    const {visible, close} = props;

    const wrapper = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function onClickOverlay(e: MouseEvent) {
            if (visible) {
                if (wrapper.current && e.target instanceof HTMLElement) {
                    if (!wrapper.current.contains(e.target)) {
                        close();
                    }
                }
            }
        }

        window.addEventListener('click', onClickOverlay);
        return () => {
            window.removeEventListener('click', onClickOverlay);
        };
    }, [visible, close]);

    return (
        <div
            ref={wrapper}
            className={css(Styles.mobileMenu, visible && Styles.mobileMenuVisible)}
        >
            {visible && (
                <>
                    <div
                        className={css(Styles.menuClose)}
                        onClick={close}
                    >
                        <CrossIcon className={css(Styles.menuCloseIcon)} />
                    </div>
                    <MenuList isMobile />
                    <LanguagesMenu isMobile />
                </>
            )}
        </div>
    );
};

export const LanguagesMenu = ({
    isMobile,
    transparent,
    hovered,
}: {
    isMobile?: boolean;
    transparent?: boolean;
    hovered?: boolean;
}) => {
    return (
        <Dropdown
            {...{
                animation: 'fade',
                // FIXME: refactor i18next.language
                header: () => (
                    <div
                        className={css(
                            Styles.menuIconItem,
                            !session.user.is_verified && Styles.languageSignout,
                            isMobile && Styles.languageMobile
                        )}
                    >
                        <LanguageIcon
                            className={css(
                                Styles.languageIcon,
                                transparent && !hovered && Styles.languageIconTransparent
                            )}
                            width="17"
                            height="17"
                        />
                        <span className={css(Styles.lngTitle, transparent && !hovered && Styles.lngTitleTransparent)}>
                            {i18next.language}
                        </span>
                    </div>
                ),
                dropElement: () => (
                    <ul className={css(Styles.dropdownActions)}>
                        {languages.map(lan => {
                            if (!lan.enabled || !lan.inMenu) return null;
                            return (
                                <li
                                    key={lan.code}
                                    {...{
                                        className: css(Styles.dropdownAction),
                                        onClick: () => changeLanguage(lan.code),
                                    }}
                                >
                                    {lan.name}
                                </li>
                            );
                        })}
                    </ul>
                ),
            }}
        />
    );
};

const MenuList = ({
    active,
    isMobile,
    transparent,
    hovered,
}: {
    active?: string;
    isMobile?: boolean;
    transparent?: boolean;
    hovered?: boolean;
}) => {
    const {t, tReady} = useLocalization('header');

    if (!tReady) {
        return null;
    }

    return (
        <ul className={css(Styles.nav, isMobile && Styles.navMobile)}>
            <li
                className={css(
                    Styles.navItem,
                    transparent && !hovered && Styles.navItemTransparent,
                    isMobile && Styles.navItemMobile,
                    active === 'tours' && Styles.navItemActive
                )}
            >
                <a
                    href="/tours"
                    className={css(
                        Styles.navItemLink,
                        transparent && !hovered && Styles.navItemLinkTransparent,
                        active === 'tours' && Styles.navItemLinkActive
                    )}
                    onClick={OpenPage('tours')}
                >
                    {t('links.search')}
                </a>
            </li>
            <li
                className={css(
                    Styles.navItem,
                    transparent && !hovered && Styles.navItemTransparent,
                    isMobile && Styles.navItemMobile,
                    active === 'faq' && Styles.navItemActive
                )}
            >
                <a
                    href="/help"
                    className={css(
                        Styles.navItemLink,
                        transparent && !hovered && Styles.navItemLinkTransparent,
                        active === 'faq' && Styles.navItemLinkActive
                    )}
                    onClick={OpenPage('help')}
                >
                    {t('links.help')}
                </a>
            </li>
            <li
                className={css(
                    Styles.navItem,
                    transparent && !hovered && Styles.navItemTransparent,
                    isMobile && Styles.navItemMobile,
                    active === 'pricing' && Styles.navItemActive
                )}
            >
                <a
                    href="/pricing"
                    className={css(
                        Styles.navItemLink,
                        transparent && !hovered && Styles.navItemLinkTransparent,
                        active === 'pricing' && Styles.navItemLinkActive
                    )}
                    onClick={OpenLink}
                >
                    {t('links.pricing')}
                </a>
            </li>
            <li
                className={css(
                    Styles.navItem,
                    transparent && !hovered && Styles.navItemTransparent,
                    isMobile && Styles.navItemMobile,
                    active === 'app-tutorial' && Styles.navItemActive
                )}
            >
                <a
                    href="/app-tutorial"
                    className={css(
                        Styles.navItemLink,
                        transparent && !hovered && Styles.navItemLinkTransparent,
                        active === 'app-tutorial' && Styles.navItemLinkActive
                    )}
                    onClick={OpenLink}
                >
                    {t('links.app-tutorial')}
                </a>
            </li>
            <li
                className={css(
                    Styles.navItem,
                    transparent && !hovered && Styles.navItemTransparent,
                    isMobile && Styles.navItemMobile,
                    active === 'demo' && Styles.navItemActive
                )}
            >
                <a
                    href="/demo"
                    className={css(
                        Styles.navItemLink,
                        transparent && !hovered && Styles.navItemLinkTransparent,
                        active === 'demo' && Styles.navItemLinkActive
                    )}
                    onClick={OpenLink}
                >
                    {t('links.demo-request')}
                </a>
            </li>
        </ul>
    );
};

const Styles = CreateSheet({
    header: {
        display: 'flex',
        position: 'relative',
        height: Sizes.header,
        alignItems: 'center',
        backgroundColor: '#fff',
        boxShadow: '0px 4px 15px rgba(48, 48, 48, 0.05)',
        transition: 'background-color 0.25s ease-in 0s, box-shadow 0.25s ease-in 0s',
        zIndex: 5,
    },
    transparent: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
    },
    fixed: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 20,
    },
    container: {
        display: 'flex',
        width: '100%',
        maxWidth: 1240,
        margin: '0 auto',
        padding: '0 20px',
    },
    col: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    left: {
        justifyContent: 'flex-start',
    },
    right: {
        justifyContent: 'flex-end',
    },
    logo: {
        ':nth-child(1n) .logo-text': {
            transition: 'fill 0.25s ease-in 0s',
        },
    },
    logoTransparent: {
        ':nth-child(1n) .logo-text': {
            fill: '#fff',
        },
    },
    nav: {
        display: 'flex',
        margin: 0,
        padding: 0,
        alignItems: 'center',
    },
    navMobile: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    navItem: {
        position: 'relative',
        margin: '0 17.5px',
        fontSize: '1.05rem',
        fontWeight: 600,
        whiteSpace: 'nowrap',
        listStyle: 'none',
        userSelect: 'none',
        ':after': {
            content: '" "',
            position: 'absolute',
            bottom: -5,
            left: 0,
            width: 0,
            height: 2,
            background: Dark.default,
            opacity: 0.5,
            transition: 'all 0.5s cubic-bezier(.645,.045,.355,1)',
        },
        ':hover': {
            ':after': {
                width: '100%',
                opacity: 1,
            },
        },
    },
    navItemTransparent: {
        ':after': {
            background: '#fff',
        },
    },
    navItemMobile: {
        marginBottom: 20,
    },
    navItemActive: {
        ':after': {
            width: '100%',
            opacity: 1,
        },
    },
    navItemLink: {
        color: Dark.default,
        textDecoration: 'none',
        opacity: 0.5,
        transition: 'opacity 0.3s cubic-bezier(.645,.045,.355,1), color 0.25s ease-in 0s',
        ':hover': {
            opacity: 1,
        },
    },
    navItemLinkActive: {
        opacity: 1,
        cursor: 'default',
    },
    navItemLinkTransparent: {
        color: '#fff',
        opacity: 1,
    },
    menuBtn: {
        marginLeft: 10,
    },
    menuIconItem: {
        display: 'flex',
        marginLeft: 25,
        fontSize: '1.05rem',
        fontWeight: 600,
        color: Dark.default,
        alignItems: 'center',
        cursor: 'pointer',
    },
    menuLike: {
        marginLeft: 70,
        cursor: 'pointer',
        '@media(max-width: 580px)': {
            display: 'none',
        },
    },
    menuIconItemIcon: {
        display: 'block',
    },
    languageSignout: {
        marginRight: 20,
    },
    languageIcon: {
        marginRight: 15,
        ':nth-child(1n) path': {
            stroke: Dark.default,
            transition: 'stroke 0.25s ease-in 0s',
        },
    },
    languageIconTransparent: {
        ':nth-child(1n) path': {
            stroke: '#fff',
        },
    },
    likeIcon: {
        fill: 'none',
        transition: 'fill 0.25s ease-in-out',
        ':nth-child(1n) path': {
            stroke: Primary.default,
        },
        ':hover': {
            fill: Primary.default,
        },
    },
    mobileMenuIcon: {
        fill: Dark.default,
        transition: 'fill 0.25s ease-in 0s',
    },
    mobileMenuIconTransparent: {
        fill: '#fff',
    },
    mobileMenu: {
        position: 'fixed',
        top: 0,
        right: -330,
        bottom: 0,
        width: 300,
        padding: '30px 25px',
        backgroundColor: '#fff',
        boxShadow: '0px 10px 20px rgba(48, 48, 48, 0.15)',
        transform: 'translate(0, 0px)',
        zIndex: 1297,
        transition: 'transform 0.2s ease-in-out',
    },
    mobileMenuVisible: {
        transform: 'translate(-330px)',
    },
    menuClose: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 15,
        right: 15,
        width: 30,
        height: 30,
        borderRadius: 3,
        cursor: 'pointer',
        ':hover': {
            backgroundColor: 'rgba(196, 196, 196, 0.2)',
        },
    },
    menuCloseIcon: {
        display: 'block',
        fill: Dark.default,
    },
    mobileMenuToggle: {
        display: 'none',
        '@media(max-width: 1200px)': {
            display: 'flex',
            marginLeft: 20,
        },
    },
    headerNav: {
        '@media(max-width: 1200px)': {
            display: 'none',
        },
    },
    dropdownActions: {
        position: 'absolute',
        top: 'calc(100% + 5px)',
        right: 0,
        minWidth: '100%',
        margin: 0,
        padding: 7,
        fontSize: '1rem',
        fontWeight: 600,
        listStyle: 'none',
        color: Dark.default,
        background: '#fff',
        borderRadius: 10,
        boxShadow: '0px 10px 30px -2px rgba(123, 123, 123, 0.3)',
        userSelect: 'none',
        zIndex: 1,
    },
    dropdownAction: {
        padding: '5px 10px',
        whiteSpace: 'nowrap',
        borderRadius: 5,
        ':hover': {
            background: '#F1F1F1',
        },
    },
    profileDivider: {
        width: '100%',
        height: 1,
        margin: '5px 0',
        background: 'rgba(48, 48, 48, 0.05)',
    },
    userMenu: {
        height: 25,
        marginLeft: 35,
        '@media(max-width: 580px)': {
            marginLeft: 15,
        },
    },
    avatar: {
        cursor: 'pointer',
        height: 24,
        width: 24,
        stroke: Dark.default,
        fill: 'none',
        transition: 'stroke 0.25s ease-in-out',
        ':hover': {
            stroke: Primary.default,
        },
    },
    avatarTransparent: {
        stroke: '#fff',
    },
    lngTitle: {
        textTransform: 'capitalize',
        color: Dark.default,
        transition: 'color 0.25s ease-in-out',
    },
    lngTitleTransparent: {
        color: '#fff',
    },
    signUp: {
        '@media(max-width: 600px)': {
            display: 'none',
        },
    },
    language: {
        '@media(max-width: 600px)': {
            display: 'none',
        },
    },
    languageMobile: {
        marginLeft: 20,
    },
});
