import React, {useContext} from 'react';
import {LightboxProps, LightboxTheme} from './model';
import {css, CreateSheetWithTheme, useTheme} from 'packages/aphrodite/aphrodite';
import {ReactComponent as CrossIcon} from 'src/icons/cross.svg';
import {ComponentContext} from './data';

export function Lightbox({close, styles, ...props}: LightboxProps) {
    const {theme} = useContext(ComponentContext);
    const Styles = useTheme(theme, StylesWithTheme);

    return (
        <article
            {...props}
            {...{
                className: css(Styles._lightbox, styles ? styles : null),
            }}
        >
            {typeof close == 'function' ? (
                <aside
                    className={css(Styles._lightboxClose)}
                    onClick={close}
                >
                    <CrossIcon
                        className={css(Styles._lightboxCloseIcon)}
                        width="20"
                        height="20"
                    />
                </aside>
            ) : null}
            {props.children}
        </article>
    );
}

export function Body({styles, ...props}: LightboxProps) {
    const {theme} = useContext(ComponentContext);
    const Styles = useTheme(theme, StylesWithTheme);

    return (
        <section
            {...props}
            {...{
                className: css(Styles._body, styles ? styles : null),
            }}
        >
            {props.children}
        </section>
    );
}

export function Header({styles, ...props}: LightboxProps) {
    const {selector, theme} = useContext(ComponentContext);
    const Styles = useTheme(theme, StylesWithTheme);

    return (
        <header
            {...props}
            {...{
                id: selector ? `_lightbox-${selector}-label` : undefined,
                className: css(Styles._header, styles ? styles : null),
            }}
        >
            {props.children}
        </header>
    );
}

export function Footer({styles, ...props}: LightboxProps) {
    const {theme} = useContext(ComponentContext);
    const Styles = useTheme(theme, StylesWithTheme);

    return (
        <footer
            {...props}
            {...{
                className: css(Styles._footer, styles ? styles : null),
            }}
        >
            {props.children}
        </footer>
    );
}

const StylesWithTheme = CreateSheetWithTheme((theme?: LightboxTheme) => {
    return {
        _lightbox: {
            borderRadius: 15,
            backgroundColor: theme?.lightbox.backgroundColor,
            color: theme?.lightbox.color,
            boxShadow: theme?.lightbox.boxShadow,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        },
        _lightboxClose: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: 12,
            right: 20,
            width: 20,
            height: 20,
            cursor: 'pointer',
            zIndex: 2,
        },
        _lightboxCloseIcon: {
            fill: theme?.lightbox.closeIconColor,
        },
        _header: {
            background: theme?.header.background,
            color: theme?.header.color,
            position: 'relative',
            fontSize: '1.142rem',
            fontWeight: 600,
            textAlign: 'left',
            margin: 0,
            padding: '10px 20px',
            borderRadius: '15px 15px 0 0',
        },
        _body: {
            backgroundColor: theme?.body.backgroundColor,
            color: theme?.body.color,
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            padding: '15px 15px 15px 15px',
        },
        _footer: {
            backgroundColor: theme?.footer.backgroundColor,
            color: theme?.footer.color,
            borderTop: theme?.footer.botderTop,
            padding: 15,
        },
    };
});
