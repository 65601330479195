import {useLocalization} from 'packages/localization/localization';
import {css, CreateSheet} from 'packages/aphrodite/aphrodite';
import {history, OpenPage} from 'packages/history/history';
import {SmoothSteper, SmoothSteperItem} from 'packages/motion/SmoothStepper.react';
import {LightSignIn, LightSignUp, LightVerification, LightResetPassword} from 'src/themes/authentication';
import {Primary} from 'src/themes/colors';
import {ReactComponent as BgIcon} from './bg.svg';
import ResetPasswordReact from './modules/ResetPassword.react';
import SignIn from './modules/SignIn.react';
import SignUp from './modules/SignUp.react';
import Verification from './modules/Verification.react';
import {useQueryParser} from 'typed-query-parser';
import MagicReact from './modules/Magic.react';

const Steps: Record<string, number> = {
    login: 0,
    join: 1,
    verification: 2,
    password_reset: 3,
    magic: 4,
};

export default function AuthenticationStepper({step, origin}: {step: keyof typeof Steps; origin?: string}) {
    const [queryData] = useQueryParser(history, types => ({
        code: types.string(),
    }));

    const {t, tReady} = useLocalization('login');

    if (!tReady) {
        return null;
    }

    return (
        <div className={css(Styles.container)}>
            <div className={css(Styles.content)}>
                <SmoothSteper {...{active: Steps[step] || 0, duration: 500, motion: ['fade'], grow: false}}>
                    <SmoothSteperItem>
                        <h1 className={css(Styles.title)}>{t('signIn.title')}</h1>
                        <SignIn {...{theme: LightSignIn, origin}} />
                        <footer className={css(Styles.footer)}>
                            {t('signIn.footerText')}{' '}
                            <a
                                {...{
                                    className: css(Styles.footer_link),
                                    href: '/join',
                                    onClick: OpenPage('join'),
                                }}
                            >
                                {t('signIn.footerLink')}
                            </a>
                        </footer>
                    </SmoothSteperItem>
                    <SmoothSteperItem>
                        <h1 className={css(Styles.title)}>{t('signUp.title')}</h1>
                        <SignUp
                            theme={LightSignUp}
                            with_tour_operator={true}
                        />
                        <footer className={css(Styles.footer)}>
                            {t('signUp.footerText')}{' '}
                            <a
                                {...{
                                    className: css(Styles.footer_link),
                                    href: '/login',
                                    onClick: OpenPage('login'),
                                }}
                            >
                                {t('signUp.footerLink')}
                            </a>
                        </footer>
                    </SmoothSteperItem>
                    <SmoothSteperItem>
                        <h1 className={css(Styles.title)}>{t('verification.title')}</h1>
                        <Verification {...{theme: LightVerification, origin}} />
                    </SmoothSteperItem>
                    <SmoothSteperItem>
                        <h1 className={css(Styles.title)}>
                            {queryData.code ? t('reset.setPassword') : t('verification.reset')}
                        </h1>
                        <ResetPasswordReact {...{theme: LightResetPassword}} />
                    </SmoothSteperItem>
                    <SmoothSteperItem>
                        <MagicReact {...{theme: LightSignIn, origin}} />
                        <footer className={css(Styles.footer, Styles.footerMagic)}>
                            <a
                                {...{
                                    className: css(Styles.footer_link),
                                    href: '/login',
                                    onClick: OpenPage('login'),
                                }}
                            >
                                {t('magic.footerLink')}
                            </a>
                        </footer>
                    </SmoothSteperItem>
                </SmoothSteper>
            </div>
            <div className={css(Styles.bg)}>
                <BgIcon className={css(Styles.bgIcon)} />
            </div>
        </div>
    );
}

const Styles = CreateSheet({
    container: {
        position: 'relative',
        overflowX: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        minHeight: 650,
    },
    content: {
        width: '100%',
        maxWidth: 440,
        position: 'relative',
        zIndex: 2,
    },
    title: {
        position: 'relative',
        display: 'inline-block',
        margin: '0 0 20px 20px',
        fontSize: '1.928rem',
        fontWeight: 700,
        textAlign: 'center',
        ':after': {
            content: "' '",
            position: 'absolute',
            bottom: -5,
            left: 0,
            width: '70%',
            height: 4,
            background: Primary.default,
            borderRadius: 4,
        },
    },
    footer: {
        paddingTop: 30,
        fontSize: '1.2857rem',
        fontWeight: 500,
        textAlign: 'center',
    },
    footerMagic: {
        paddingTop: 0,
    },
    footer_link: {
        textDecoration: 'none',
        color: Primary.default,
        ':hover': {
            textDecoration: 'underline',
        },
    },
    bg: {
        position: 'absolute',
        top: 0,
        left: '50%',
        width: '100%',
        zIndex: 0,
    },
    bgIcon: {
        display: 'block',
        width: 400,
        height: 'auto',
    },
});
