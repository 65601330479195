import {MakeAnimation} from 'packages/aphrodite/aphrodite';

const swipeRight = MakeAnimation(
    {},
    {
        '0%': {
            transform: 'translateX(0)',
        },
        '100%': {
            transform: 'translateX(100%)',
        },
    }
);

export default swipeRight;
