import * as r from 'runtypes'

export const VAR_PREFIX = "dg-color-";

export const ThemeColorsRuntype = r.Record({
	black: r.String,
	textColor: r.String,
	primary: r.String,
	supplementary: r.String,
	supplementary2: r.String,
	secondary: r.String,
	placeholder: r.String,
	gray: r.String,
	gray2: r.String,
	gray3: r.String,
	strokeColor: r.String,
	inputBg: r.String,
	blockBg: r.String,
	blockBg2: r.String,
	pageBg: r.String,
	bg: r.String,
	elementBg: r.String,
	whiteTextColor: r.String,
	negativeTextColor: r.String,
	buttonBg: r.String,
	accent: r.String,
	accentDark: r.String,
	accentLight: r.String,
	accentBg: r.String,
	accentLinearStart: r.String,
	accentLinearEnd: r.String,
	inactivePrimaryButtonBg1: r.String,
	transparentLight: r.String,
	transparentLight2: r.String,
	danger: r.String,
	dangerLight: r.String,
	dangerBg: r.String,
	success: r.String,
	successLight: r.String,
	successBg: r.String,
	highlight: r.String,
	highlightLight: r.String,
	highlightBg: r.String,
	attention: r.String,
	primaryLinearStart: r.String,
	primaryLinearEnd: r.String,
	cardShadowColor: r.String,
	shadowPrimaryColor: r.String,
	shadowAccentColor: r.String,
	tooltipBg: r.String,
	inactivePrimaryButtonBg2: r.String,
	inactiveSecondaryButtonTextColor: r.String,
	transparent: r.String,
})
export type ThemeColors = r.Static<typeof ThemeColorsRuntype>

export const Colors:ThemeColors = {
	black: "#000000",
	textColor: "#000000",
	primary: "#303030",
	supplementary: "#505050",
	supplementary2: "#707070",
	secondary: "#979797",
	placeholder: "#B0B0B0",
	gray: "#CACACA",
	gray2: "#D4D4D4",
	gray3: "#E6E6E6",
	strokeColor: "#F0F0F0",
	inputBg: "#F6F6F6",
	blockBg: "#F5F5F5",
	blockBg2: "#F9F9F9",
	pageBg: "#FFFFFF",
	bg: "#FFFFFF",
	elementBg: "#FFFFFF",
	whiteTextColor: "#FFFFFF",
	negativeTextColor: "#FFFFFF",
	buttonBg: "#F4F4F4",
	accent: "#FF2879",
	accentDark: "#C11856",
	accentLight: "#FF5291",
	accentBg: "#FFF3F7",
	accentLinearStart: "#FF5492",
	accentLinearEnd: "#FF005D",
	inactivePrimaryButtonBg1: "#FFC0D7",
	transparentLight: "rgba(255, 255, 255, 0.6)",
	transparentLight2: "rgba(255, 255, 255, 0.2)",
	danger: "#E60246",
	dangerLight: "#F47384",
	dangerBg: "#FFE0F1",
	success: "#11A34B",
	successLight: "#37D677",
	successBg: "#EBF7F0",
	highlight: "#28499E",
	highlightLight: "#86A0E2",
	highlightBg: "#F0F4FF",
	attention: "#FEE81F",
	primaryLinearStart: "#606060",
	primaryLinearEnd: "#1E1E1E",
	cardShadowColor: "rgba(48, 48, 48, 0.07)",
	shadowPrimaryColor: "rgba(48, 48, 48, 0.07)",
	shadowAccentColor: "rgba(152, 20, 68, 0.2)",
	tooltipBg: "rgba(48, 48, 48, 0.8)",
	inactivePrimaryButtonBg2: "#CFCFCF",
	inactiveSecondaryButtonTextColor: "#B0B0B0",
	transparent: "rgba(255, 255, 255, 0)",
}
