import {CreateSheet, CSSPropertiesComplete} from 'packages/aphrodite/aphrodite';
import fade from './animations/fade';
import swipeRight from './animations/swipe-right';
import swipeLeft from './animations/swipe-left';
import swipeDown from './animations/swipe-down';
import swipeUp from './animations/swipe-up';
import swipeInUp from './animations/swipe-in-up';
import swipeInDown from './animations/swipe-in-down';
import swipeHorizontalLeft from './animations/swipe-horizontal-left';
import swipeHorizontalRight from './animations/swipe-horizontal-right';
import bounceIn from './animations/bounce-in';
import wobbleHorizontalBottom from './animations/wobble-horizontal-bottom';
import wobbleVerticalBottom from './animations/wobble-vertical-bottom';
import wobbleSmile from './animations/wobble-smile';
import blur from './animations/blur';
import pulsating from './animations/pulsating';
import logSpin from './animations/logspin';
import slideLeft from './animations/slide-left';
import slideRight from './animations/slide-right';

export const AnimationsList = {
    fade: fade,
    blur: blur,
    'swipe-right': swipeRight,
    'swipe-left': swipeLeft,
    'swipe-down': swipeDown,
    'swipe-up': swipeUp,
    'swipe-in-up': swipeInUp,
    'swipe-in-down': swipeInDown,
    'slide-left': slideLeft,
    'slide-right': slideRight,
    'swipe-horizontal-left': swipeHorizontalLeft,
    'swipe-horizontal-right': swipeHorizontalRight,
    'wobble-horizontal-bottom': wobbleHorizontalBottom,
    'wobble-vertical-bottom': wobbleVerticalBottom,
    'wobble-smile': wobbleSmile,
    'bounce-in': bounceIn,
    pulsate: pulsating,
    logSpin: logSpin,
};

export type Motion = Array<keyof typeof AnimationsList>;
export type AnimationOptions = {
    animationIterationCount?: CSSPropertiesComplete['animationIterationCount'];
    animationTimingFunction?: CSSPropertiesComplete['transitionTimingFunction'];
    animationDuration: number | {enter?: number; exit?: number};
};

export const CreateTransitionAnimation = (motion: Motion | undefined, options: AnimationOptions) => {
    if (!motion) {
        return undefined;
    }

    const animations = motion.map(motion => AnimationsList[motion]);

    // increase css animation by 10ms to prevent flashing
    const d = options.animationDuration;
    let duration = {
        enter: typeof d === 'number' ? d + 10 : d.enter ? d.enter + 10 : 0,
        exit: typeof d === 'number' ? d + 10 : d.exit ? d.exit + 10 : 0,
    };

    return CreateSheet({
        animation: {
            '&.sl-animation-enter': {
                animationName: [animations.map(animation => animation.enter)],
                animationDuration: `${duration.enter}ms`,
                animationIterationCount: options.animationIterationCount || 'unset',
                animationTimingFunction: options.animationTimingFunction || 'linear',
            },
            '&.sl-animation-exit': {
                animationName: [animations.map(animation => animation.exit)],
                animationDuration: `${duration.exit}ms`,
                animationIterationCount: options.animationIterationCount || 'unset',
                animationTimingFunction: options.animationTimingFunction || 'linear',
            },
        },
    }).animation;
};

export const CreateAnimation = (motion: Motion | undefined, options: AnimationOptions) => {
    if (!motion) {
        return undefined;
    }

    const animations = motion.map(motion => AnimationsList[motion]);

    return CreateSheet({
        animation: {
            animationName: [animations.map(animation => animation.enter)],
            animationDuration: `${
                typeof options.animationDuration === 'number'
                    ? options.animationDuration
                    : options.animationDuration.enter || 0
            }ms`,
            animationIterationCount: options.animationIterationCount || 'unset',
            animationTimingFunction: options.animationTimingFunction || 'linear',
        },
    }).animation;
};
