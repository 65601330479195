import {ErrorData} from 'packages/errors/errors';
import {post} from 'packages/rest/api';

export type RequestResetPasswordRequest = {
    identifier: string;
};

export async function RequestResetPasswordAPI(body: RequestResetPasswordRequest): Promise<ErrorData | null> {
    const res = await post({url: 'session/passwords', body});
    return res[1];
}
