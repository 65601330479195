import {ToastLayout, ToastTheme, options, ToastContent, ShowToast} from 'packages/toasts/ShowToast';
import {Danger, Dark, Secondary, White} from './colors';
import {GetColor} from 'aphrodite';

const DefaultLayout: ToastLayout = {
    padding: 16,
    borderRadius: 4,
    boxShadow: '0 2px 20px 0 rgb(34 41 47 / 8%)',
    fontSize: 15,
    fontWeight: 'normal',
};

const SuccessToastTheme: ToastTheme = {
    backgroundColor: GetColor('success'),
    prgoressColor: GetColor('primary'),
    crossColor: GetColor('negativeTextColor'),
    color: GetColor('negativeTextColor'),
};

const DefaultToastTheme: ToastTheme = {
    backgroundColor: '#EFEFEF',
    prgoressColor: GetColor('accent'),
    crossColor: Secondary.default,
    color: Dark.default,
};

const WarningDangerTheme: ToastTheme = {
    backgroundColor: Danger.default,
    prgoressColor: Danger.active,
    crossColor: White.default,
    color: White.default,
};

export function ShowSuccessToast(Content: ToastContent, options?: options) {
    return ShowToast(Content, SuccessToastTheme, {...DefaultLayout, fontWeight: 600}, options);
}

export function ShowDefaultToast(Content: ToastContent, options?: options) {
    return ShowToast(Content, DefaultToastTheme, DefaultLayout, options);
}

export function ShowWarningToast(Content: ToastContent, options?: options) {
    return ShowToast(Content, WarningDangerTheme, DefaultLayout, options);
}
